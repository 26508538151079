import { faWarning } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link as RouterLink, navigate } from '@reach/router'
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import Checkout from 'components/page/signup/Checkout'
import FeedbackFormAlternative from 'components/ui/feedback-form/FeedbackFormAlternative'
import EditFutureOrderModal from 'components/ui/modal/EditFutureOrderModal'
import ErrorModal from 'components/ui/modal/ErrorModal'
import Toast from 'components/ui/toast/Toast'
import { getCognitoId, getVisitorId } from 'components/util/UserId'
import { ElementState } from 'components/util/stripe-custom'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import { ErrorMessage, Field, Formik } from 'formik'
import { StaticImage } from 'gatsby-plugin-image'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateDogsToDelete } from 'state/actions/account/accountDogAction'
import { updatePlanRadio } from 'state/actions/account/accountDogAction'
import { updatePlans } from 'state/actions/account/accountPlanAction'
import { updateOrders } from 'state/actions/account/accountPlanAction'
import { resetFlowDogs } from 'state/actions/account/flow/dogAction'
import { createDogsExistingPlan, updateChangedStatus } from 'state/actions/account/flow/dogAction'
import { resetFlowPlan } from 'state/actions/account/flow/planAction'
import { resetFlowShipping } from 'state/actions/account/flow/shippingAction'
import { resetKey } from 'state/actions/keyAction'
import { updateSpoofedUserEmail } from 'state/actions/uiAction'
import * as yup from 'yup'
import loadingGIF from '../../../../images/loadingspinner.gif'
import { RootState } from '../../../../state'
import {
  editCustomerDetails,
  getAccountSummary,
  getCustomerDetails,
  resetAccountSummary
} from '../../../../state/actions/accountSummary'
import Api from '../../../../state/apis/api'
import { AccountSummaryType, CustomerDetailsType } from '../../../../state/types/accountSummaryType'
import EditPasswordModal from '../../../ui/modal/EditPasswordModal'
import PlansAccordion from './PlansAccordion'
import TrackOrderAccordion from './TrackOrderAccordion'
import TreatAddonAccordion from './TreatAddonAccordion'
import WelcomeMsgAccordion from './WelcomeMsgAccordion'

import { isAppleDevice } from 'utils/get-browser-device'
import cardExpireIcon from '../../../../images/card-expire-icon.svg'
import changeDeliveryDateImage from '../../../../images/change-delivery-date.svg'
import changeRecipesImage from '../../../../images/change-recipes.svg'
import rewardsImage from '../../../../images/rewards.svg'
import updateDogsDetailsImage from '../../../../images/update-dog-details.svg'

dayjs.extend(advancedFormat)

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PK || '')

const validationSchema = yup.object({
  name: yup
    .string()
    .min(1)
    .max(50, 'Firstname must be at most 50 characters')
    .required('Please enter your First Name.')
    .test('name validation', 'Firstname contains only characters and - ', value => {
      if (value?.match(/[a-zA-Z\-]+/)) {
        return true
      }
      return false
    }),
  surname: yup
    .string()
    .min(1)
    .max(50, 'Lastname must be at most 50 characters')
    .required('Please enter your Last Name.')
    .test('name validation', 'Lastname contains only charaters and - ', value => {
      if (value?.match(/[a-zA-Z\-]+/)) {
        return true
      }
      return false
    }),
  email: yup.string().email('Invalid email address.').required('Please enter your Email address.'),
  telephone: yup
    .string()
    .required('Please enter a mobile number')
    .matches(/^(?:\+44|44|0)(1|2|7)\d{9}$/, 'Please enter a valid mobile number'),
  referrer: yup.string().email('Invalid email address.')
})

const billingValidationSchema = yup.object({
  name: yup
    .string()
    .min(1)
    .max(50, 'First Name must be at most 50 characters')
    .required('Please enter your First Name.')
    .test('name validation', 'Firstname contains only charaters and - ', value => {
      if (value?.match(/[a-zA-Z\-]+/)) {
        return true
      }
      return false
    }),
  surname: yup
    .string()
    .min(1)
    .max(50, 'Last Name must be at most 50 characters')
    .required('Please enter your Last Name')
    .test('name validation', 'Lastname contains only charaters and - ', value => {
      if (value?.match(/[a-zA-Z\-]+/)) {
        return true
      }
      return false
    }),
  address1: yup
    .string()
    .min(1)
    .max(50, 'First line of your address must be at most 50 characters')
    .required('Please enter the first line of your address'),
  address2: yup.string(),
  city: yup.string().min(1).max(50, 'City must be at most 50 characters').required('Please enter your City.'),
  county: yup.string().required('Please enter your County.'),
  postcode: yup.string().required('Please enter your Postcode.')
})

const AccountCards: React.FC<any> = props => {
  return (
    <Elements stripe={stripePromise}>
      <AccountSummary {...props} />
    </Elements>
  )
}

const AccountSummary: React.FC = () => {
  const dispatch = useDispatch()
  const {
    summary,
    customer,
    customerDetailsEdited,
    requires3dSecure,
    payment,
    error,
    errorMessage
  }: {
    summary: AccountSummaryType
    customer: CustomerDetailsType
    customerDetailsEdited: boolean
    requires3dSecure: boolean
    payment: object
    error: boolean
    errorMessage: object
  } = useSelector((state: RootState) => state.accountSummary)
  const { orders, plans } = useSelector((state: RootState) => state.accountPlan)
  const { spoofMode, spoofedUserId } = useSelector((state: RootState) => state.ui)
  const [_nextPlan, setNextPlan] = useState<any>({})
  const [showEditCustomer, setShowEditCustomer] = useState(false)
  const [showEditBilling, setShowEditBilling] = useState(false)
  const [showPasswordModal, setShowPasswordModal] = useState(false)
  const [showToast, setShowToast] = useState(false)
  const [toastMsg, setToastMsg] = useState('')
  const [browser, setBrowser] = useState('')
  const [localErrorMessage, setErrorMessage] = useState<string | undefined>()
  const [showPopup, setShowPopup] = useState(false)
  const [showRedZoneModal, setShowRedZoneModal] = useState(false)
  const [redZoneConfirmButton, setRedZoneConfirmButton] = useState(<></>)
  const [loading, setLoading] = useState(false)
  const [passwordModalState, setPasswordModalState] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [selectedPaymentOption, setSelectedPaymentOption] = useState('currentCard')
  const [paymentRequest, setPaymentRequest] = useState<any>(null)
  const [payEvent, setPayEvent] = useState(null)
  const paymentRef = React.useRef(null)

  const { changed, loading: isDogPlanLoading } = useSelector((state: RootState) => state.accountDogFlow)

  const [nextPage, setNextPage] = useState<string | undefined>(undefined)

  const stripe = useStripe()
  const elements = useElements()

  const [_visitorId, setVisitorId] = useState('')
  const [_cognitoId, setCognitoId] = useState('')

  useEffect(() => {
    setBrowser(isAppleDevice())
  }, [])

  useEffect(() => {
    const callIdsForGtag = async () => {
      setVisitorId(await getVisitorId())
      setCognitoId(await getCognitoId())
    }
    callIdsForGtag()
  }, [])

  useEffect(() => {
    if (customer?.forceResetPassword) {
      setPasswordModalState('Create')
      setShowPasswordModal(true)
    }
  }, [customer])

  useEffect(() => {
    if (error) {
      setShowPopup(true)
    } else {
      setShowPopup(false)
    }
  }, [error])

  useEffect(() => {
    const getAccountSummaryAndOrders = async () => {
      setLoading(true)
      await dispatch(resetAccountSummary())
      await dispatch(updateOrders())
      await dispatch(getAccountSummary())
      setLoading(false)
    }

    getAccountSummaryAndOrders()
  }, [])

  useEffect(() => {
    dispatch(resetFlowDogs())
    dispatch(resetFlowPlan())
    dispatch(resetFlowShipping())
    dispatch(resetKey())
    dispatch(updateDogsToDelete([]))
  }, [])

  useEffect(() => {
    if (spoofMode) {
      dispatch(getAccountSummary())
    }
  }, [spoofMode])

  useEffect(() => {
    if (summary && spoofMode) dispatch(updateSpoofedUserEmail(summary.customer.email))
  }, [summary])

  useEffect(() => {
    if (!spoofMode) dispatch(getCustomerDetails())
  }, [])

  useEffect(() => {
    if (customerDetailsEdited) {
      setShowToast(true)
      setShowEditCustomer(false)
      setShowEditBilling(false)
      dispatch(getAccountSummary())
      dispatch(getCustomerDetails())
    }
  }, [customerDetailsEdited])

  useEffect(() => {
    const confirmPayment = async () => {
      if (requires3dSecure && typeof payment !== 'undefined') {
        setShowEditBilling(true)
        const response = await stripe?.confirmCardSetup(payment.client_secret)

        if (!response?.error) {
          dispatch(
            editCustomerDetails({
              intent: response?.setupIntent
            })
          )
          setShowEditBilling(false)
        } else {
          setErrorMessage('There has been a problem adding your card')
        }
      }
    }

    confirmPayment()
  }, [requires3dSecure, payment])

  useEffect(() => {
    if (changed && nextPage === 'treats') {
      const url = spoofMode ? '/account/treats/?userId=' + spoofedUserId : '/account/treats/'
      navigate(url)
    } else if (changed && nextPage === 'plan') {
      const url = spoofMode ? '/account/plan/?userId=' + spoofedUserId : '/account/plan/'
      navigate(url)
    } else if (changed && nextPage === 'plan-recipes') {
      const url = spoofMode ? '/account/plan-recipes/?userId=' + spoofedUserId : '/account/plan-recipes/'
      navigate(url)
    }
    return () => {
      dispatch(updateChangedStatus(false))
    }
  }, [changed])

  useEffect(() => {
    // Populate the account plan store
    if (!plans) {
      dispatch(updatePlans())
    }
  }, [])

  useEffect(() => {
    let nextPlanData: Array<any> = []
    const orderData: any[] = orders?.length ? [...orders] : []
    const today = new Date()
    today.setHours(0, 0, 0, 0)
    if (plans?.length > 0) {
      plans?.map(plan => {
        if (plan.status === 'active' || plan.status === 'future') {
          nextPlanData.push(plan)
        }
      })
    }

    if (orderData && orderData[0]) {
      orderData[0].nextDelivery = dayjs(orderData[0].shipDate).add(1, 'day').format('YYYY-MM-DD')
      nextPlanData.push(orderData[0])
    }

    if (nextPlanData && nextPlanData?.length > 0) {
      nextPlanData.sort((a, b) => {
        return new Date(a.nextDelivery) - new Date(b.nextDelivery)
      })
    }

    nextPlanData = nextPlanData.filter(i => i)

    if (nextPlanData && nextPlanData?.length > 0) {
      let totalQuantity = 0
      const res = nextPlanData?.[0]?.recipes?.map((recipe: any) => {
        totalQuantity += recipe.quantity

        // Check to determine whether the first element's data is derived from an order or recipe
        // if recipe has ID, its a recipe object, else its from an order
        if (typeof recipe.id !== 'undefined') {
          return recipe.quantity ? `${recipe.quantity} x ${recipe.name}` : `${recipe.name}`
        } else {
          return `${recipe.quantity} x ${recipe.description}`
        }
      })
      const addons = nextPlanData?.[0]?.addons?.map((addon: any) => {
        if (typeof addon.id !== 'undefined') {
          return addon.quantity ? `${addon.quantity} x ${addon.name}` : `${addon.name}`
        } else {
          return `${addon.quantity} x ${addon.description}`
        }
      })

      const totalGram = nextPlanData?.[0]?.plan?.split('-')[0]
      setNextPlan({
        nextDelivery: dayjs(nextPlanData?.[0]?.nextDelivery).format('dddd Do MMMM YYYY'),
        quantityPack: totalQuantity ? `${totalQuantity} x ${totalGram}` : totalGram,
        items: [...res],
        addons: [...addons],
        subscriptionId: nextPlanData?.[0]?.subscriptionId,
        validDeliveryDate: true,
        address: nextPlanData?.[0]?.address
      })
    }
    //typeof nextPlanData?.[0]?.validDeliveryDate !== 'undefined' ? nextPlanData?.[0]?.validDeliveryDate : true
  }, [plans, orders])

  useEffect(() => {
    if (stripe) {
      createPaymentRequest()
    }
  }, [stripe])

  const handlePaymentMethodReceived = async (event: any) => {
    console.log(event, 'event')

    if (event?.paymentMethod) {
      setPayEvent(event)
    } else {
      setPayEvent(null) // Clear pay event due to errors
    }
    // Return success to close the popup, even if failed state
    event.complete('success')
  }

  const createPaymentRequest = () => {
    setPaymentRequest(null)

    const pr = stripe.paymentRequest({
      country: 'GB',
      currency: 'gbp',
      total: {
        label: 'Different Dog',
        amount: 0.0
      }
    })

    pr.canMakePayment().then(res => {
      if (res) {
        console.log(res, 'res')
        pr.on('paymentmethod', handlePaymentMethodReceived)
        setPaymentRequest(pr)
        console.error('pr', JSON.stringify(pr, null, 2))
      }
    })
  }

  const getDogNames = (plans: any) => {
    if (plans) {
      let dogNames = []
      dogNames = plans.map((plan: any) => {
        if (plan.dogNames) {
          return plan.dogNames.replace(' &', ',')
        }
        return null
      })
      dogNames = dogNames.filter((dog: any) => dog)
      return dogNames.join(', ').replace(/,(?=[^,]*$)/, ' &')
    }
    return ''
  }
  const _getFirstName = (name: any) => {
    if (name) {
      return name.charAt(0).toUpperCase().concat(name.slice(1).toLowerCase())
    }
    return ''
  }

  const setPasswordUpdated = async (updated: boolean) => {
    if (updated) {
      await dispatch(resetAccountSummary())
      await dispatch(getAccountSummary())
      await dispatch(getCustomerDetails())
    }
  }

  const handleEditCustomerModalShow = () => {
    setShowEditCustomer(!showEditCustomer)
  }

  const handleEditBillingModalShow = () => {
    setShowEditBilling(!showEditBilling)
  }

  const handleSetShowToast = (show: boolean) => {
    setShowToast(show)
  }

  const navigateToPage = (value: string) => {
    if (value === 'update_dog') {
      const url = spoofMode ? '/account/dogs/edit/?userId=' + spoofedUserId : '/account/dogs/edit/'
      navigate(url)
    } else if (value === 'rewards') {
      const url = spoofMode ? '/account/rewards/?userId=' + spoofedUserId : '/account/rewards/'
      navigate(url)
    } else if (value === 'update_delivery') {
      if (summary?.plans && summary?.plans.length === 1) {
        if (summary?.plans[0].redZone) {
          setRedZoneConfirmButton(
            <button
              type="button"
              className="btn-cta inline-flex w-full justify-center px-4 py-2 sm:col-start-1 sm:mt-0"
              onClick={() => {
                setShowRedZoneModal(false)
                const url = spoofMode
                  ? '/account/plan/edit-delivery/?userId=' + spoofedUserId
                  : '/account/plan/edit-delivery/'
                navigate(url)
              }}
            >
              Edit date
            </button>
          )
          setShowRedZoneModal(true)
        } else {
          const url = spoofMode
            ? '/account/plan/edit-delivery/?userId=' + spoofedUserId
            : '/account/plan/edit-delivery/'
          navigate(url)
        }
      } else {
        const url = spoofMode ? '/account/plan/edit-delivery/?userId=' + spoofedUserId : '/account/plan/edit-delivery/'
        navigate(url)
      }
    } else if (value === 'update_recipe') {
      if (summary?.plans && summary?.plans.length === 1) {
        if (summary?.plans[0].redZone) {
          setRedZoneConfirmButton(
            <button
              type="button"
              className="btn-cta inline-flex w-full justify-center px-4 py-2 sm:col-start-1 sm:mt-0"
              onClick={() => {
                setShowRedZoneModal(false)
                dispatch(updatePlanRadio(summary?.plans[0].subscriptionId))
                dispatch(
                  createDogsExistingPlan({
                    subscriptionId: summary?.plans[0].subscriptionId,
                    dogs: []
                  })
                )
              }}
              disabled={isDogPlanLoading}
            >
              Edit plan
            </button>
          )
          setShowRedZoneModal(true)
        } else {
          dispatch(updatePlanRadio(summary?.plans[0].subscriptionId))
          dispatch(
            createDogsExistingPlan({
              subscriptionId: summary?.plans[0].subscriptionId,
              dogs: []
            })
          )
        }
      } else {
        const url = spoofMode ? '/account/plan/?userId=' + spoofedUserId : '/account/plan/'
        navigate(url)
      }
    } else if (value === 'resume_plan') {
      if (summary?.plans && summary?.plans.length === 1) {
        dispatch(updatePlanRadio(summary?.plans[0].subscriptionId))
        dispatch(
          createDogsExistingPlan({
            subscriptionId: summary?.plans[0].subscriptionId,
            dogs: []
          })
        )
        setNextPage('plan-recipes')
        navigateToPage('update_recipe')
      } else {
        const url = spoofMode ? '/account/plan/?userId=' + spoofedUserId : '/account/plan/'
        navigate(url)
      }
    } else {
      const url = spoofMode ? '/account/plan/?userId=' + spoofedUserId : '/account/plan/'
      navigate(url)
    }
  }

  const getNextDeliveryDate = () => {
    let dates: Array<String> = []
    const today = new Date()
    today.setHours(0, 0, 0, 0)

    if (summary?.plans?.length > 0) {
      summary.plans.map(plan => {
        dates.push(plan.nextDelivery)
      })

      if (orders && orders[0]) {
        dates.push(dayjs(orders[0].shipDate).add(1, 'day').format('YYYY-MM-DD'))
      }

      dates.sort((a, b) => {
        return new Date(a) - new Date(b)
      })

      dates = dates.filter(i => i)

      for (let i = 0; i < dates.length; i++) {
        if (new Date(dates[i]) >= today) {
          return dayjs(dates[i]).format('dddd Do MMMM')
        }
      }
    }

    return ''
  }

  /*const isChristmasDelivery = () => {
    let dates: Array<String> = []
    const today = new Date()
    today.setHours(0, 0, 0, 0)

    if (summary?.plans?.length > 0) {
      summary.plans.map(plan => {
        dates.push(plan.nextDelivery)
      })

      if (orders && orders[0]) {
        dates.push(dayjs(orders[0].shipDate).add(1, 'day').format('YYYY-MM-DD'))
      }

      dates.sort((a, b) => {
        return new Date(a) - new Date(b)
      })

      dates = dates.filter(i => i)

      for (let i = 0; i < dates.length; i++) {
        if (new Date(dates[i]) >= today) {
          if (dates[i] === '2022-12-27' || dates[i] === '2022-12-28' || dates[i] === '2023-01-03') {
            return true
          }
        }
      }
    }

    return false
  }*/

  const nextPlanHasValidDeliveryDate = () => {
    return true
    let plans: Array<any> = []

    if (summary?.plans?.length > 0) {
      summary.plans.map(plan => {
        plans.push({ nextDelivery: plan.nextDelivery, validDeliveryDate: plan.validDeliveryDate })
      })

      plans.sort((a, b) => {
        return new Date(a.nextDelivery) - new Date(b.nextDelivery)
      })

      plans = plans.filter(i => i)

      return plans[0].validDeliveryDate
    }

    return true // true as default as more common its valid then not, so will prevent flashing of message
  }

  const _planInTrial = summary?.plans?.filter((plan: any) => plan.isInTrial)

  if (loading) {
    return (
      <div className="m-auto w-full text-center" style={{ marginTop: '25vh' }}>
        <img className="m-auto" width="200px" src={loadingGIF} alt="Loading, please wait..." />
      </div>
    )
  }
  const hasCardExpired = () => {
    const expiry = summary?.customer?.payment?.expiry
    let currentMonth = new Date().getMonth() + 1
    let year = new Date().getFullYear()

    if (expiry?.year === year) {
      if (currentMonth >= expiry?.month) {
        return true
      }
    }
    return false
  }

  const activePlans = summary?.plans?.filter(p => p.status === 'active' || p.status === 'future')
  const hasActivePlan = activePlans?.length > 0

  const renderPaymentImage = () => {
    switch (summary?.customer?.payment?.brand.toLowerCase()) {
      case 'american_express':
        return <StaticImage className="" width={35} src="../../../../images/account/Amex.svg" alt="American Express" />
      case 'dinersclub':
        return <StaticImage className="" width={35} src="../../../../images/account/DinersClub.svg" alt="Diners Club" />
      case 'discover':
        return <StaticImage className="" width={35} src="../../../../images/account/Discover.svg" alt="Discover" />
      case 'jcb':
        return <StaticImage className="" width={35} src="../../../../images/account/JCB.svg" alt="JCB" />
      case 'mastercard':
        return <StaticImage className="" width={35} src="../../../../images/account/MasterCard.svg" alt="MasterCard" />
      case 'unionpay':
        return <StaticImage className="" width={35} src="../../../../images/account/UnionPay.svg" alt="UnionPay" />
      case 'visa':
        return <StaticImage className="" width={35} src="../../../../images/account/Visa.svg" alt="Visa" />
      default: {
        return summary?.customer?.payment?.brand[0]?.toUpperCase() + summary?.customer?.payment?.brand?.slice(1)
      }
    }
  }

  return (
    !loading && (
      <div className="flex flex-1 flex-col">
        {hasCardExpired() && (
          <div className="relative mx-auto mt-10 w-full rounded-3xl bg-ddRed md:mb-8 md:w-3/4 lg:w-2/3">
            <div className="flex flex-col px-6 py-6 lg:flex-row lg:justify-between">
              <div className="flex items-center">
                <div className="mr-3">
                  <img className="w-10" src={cardExpireIcon} alt="Change Delivery Date" />
                </div>
                <div className="flex flex-col text-xs lg:text-sm">
                  <p>
                    Your card ending <span className="font-bold">{summary?.customer?.payment?.lastFour}</span> will
                    expire soon.
                  </p>
                  <p className="mb-2">Please update your payment details.</p>
                </div>
              </div>
              <div className="mt-6 flex justify-center lg:mt-0 lg:justify-end">
                <button
                  type="button"
                  className={`ml-2 mr-2 rounded-full border-2 border-base px-4 py-2 text-center hover:underline focus:underline sm:block`}
                  onClick={() => {
                    !showEditBilling && handleEditBillingModalShow(),
                      setTimeout(() => {
                        paymentRef?.current.scrollIntoView()
                      }, 500)
                  }}
                >
                  Update card
                </button>
              </div>
            </div>
          </div>
        )}
        {/* Christmas delivery dates */}
        <div className="flex mx-auto mt-8 w-full rounded-2xl bg-ddBlue p-4 pl-4 md:mt-12 md:w-3/4 lg:w-5/6 2xl:w-1/2">
          <div className="">
            <StaticImage
              width={250}
              className="justify-center items-center"
              src="../../../../images/Christmas.svg"
              alt="Delivery date unavailable"
            />
          </div>
          <div className="pr-1">
            <span className="pl-4 font-sans font-semibold text-base lg:text-xl">Christmas Delivery Dates </span>
            <p className="pl-4 font-sans font-normal text-sm lg:text-base">
              Please note that our Different Dog HQ will be closed on the{' '}
              <span className="font-bold">24th, 25th, 26th and 27th of December</span> and again on the{' '}
              <span className="font-bold">1st and 2nd of January</span>. Should your delivery fall between the 16th of
              December and the 3rd of January, it may automatically be rearranged. If you have a preference for when you
              would like your box delivered, please contact our team at{' '}
              <a className="underline" href="mailto:feedme@differentdog.com">
                feedme@differentdog.com
              </a>
              .
            </p>
          </div>
        </div>
        {/* This works providing the orders are in order as newest first */}
        {orders &&
          orders[0]?.status === 'shipped' &&
          orders[0]?.tracking?.url &&
          dayjs(orders[0]?.shipDate).add(1, 'day').isAfter(dayjs()) && <TrackOrderAccordion order={orders[0]} />}
        {
          <WelcomeMsgAccordion
            dogNames={getDogNames(summary?.plans)}
            isNewCustomer={summary?.customer?.isNewCustomer}
            plan={plans}
          />
        }
        {
          <TreatAddonAccordion
            setNextPage={setNextPage}
            setRedZoneConfirmButton={setRedZoneConfirmButton}
            setShowRedZoneModal={setShowRedZoneModal}
          />
        }
        <div className="quick-actions-wave mt-8 w-full py-8 lg:py-12 md:pb-20 md:pt-20 bg-[#F5F1ED] rounded-t-3xl md:-mb-2 lg:-mb-1">
          <div className="relative px-4 py-4">
            {hasActivePlan && getNextDeliveryDate() && (
              <div>
                <div className="h4 mb-2 text-center text-xl text-primary sm:text-2xl">
                  {orders && orders?.length === 0 ? 'Your first delivery is' : 'Your next delivery is'}
                </div>
                <div className="h4 text-center text-2xl text-primary sm:text-4xl">{getNextDeliveryDate()}</div>
              </div>
            )}
            {!nextPlanHasValidDeliveryDate() && (
              <div className="relative mx-auto mt-8 mb-4 w-full rounded-xl border border-[#FB5D34] bg-[#FAD1CD] p-4 pl-16 text-sm md:w-3/4 lg:w-2/3">
                <StaticImage
                  width={64}
                  className="absolute -top-2 -left-2 md:-left-4 md:-top-4"
                  src="../../../../images/change-delivery-date.svg"
                  alt="Delivery date unavailable"
                  imgStyle={{ position: 'absolute' }}
                  style={{ position: 'absolute' }}
                />
                <p>
                  Your next delivery date is unavailable. Please select another below or contact Customer Delight for
                  support.
                </p>
              </div>
            )}
            {/*hasActivePlan && isChristmasDelivery() && (
              <div className="relative mx-auto mt-8 mb-4 w-full rounded-xl border border-[#FB5D34] bg-[#FAD1CD] p-4 pl-16 text-sm md:w-3/4 lg:w-2/3">
                <StaticImage
                  width={64}
                  className="absolute -top-2 -left-2 md:-left-4 md:-top-4"
                  src="../../../../images/change-delivery-date.svg"
                  alt="Delivery date unavailable"
                  imgStyle={{ position: 'absolute' }}
                  style={{ position: 'absolute' }}
                />
                <p>
                  Your delivery will not arrive on a bank holiday or the day after, please see your emails for an
                  updated delivery date or contact Customer Delight with any questions.
                </p>
              </div>
            )*/}
            {!hasActivePlan && (
              <h4 className="m-auto my-8 w-fit text-xl text-primary sm:text-center sm:text-3xl">
                Resume your plan to re-start your food deliveries
              </h4>
            )}
            <h4
              data-testid="quickActions"
              className="m-auto my-8 w-fit text-xl text-primary sm:text-center sm:text-2xl"
            >
              Quick actions
            </h4>
            <div
              className={`m-auto flex w-full flex-row ${hasActivePlan ? 'flex-wrap' : ''}  items-start justify-center gap-0 text-sm md:w-full md:gap-8 md:text-base`}
            >
              {!hasActivePlan && (
                <div className="mb-4 flex w-1/2 flex-col md:mb-0 md:w-1/5 mx-1 lg:mx-0">
                  <button
                    data-testid="resumePlan"
                    className="flex flex-col items-center justify-center rounded-3xl md:p-4 hover:bg-[#B0CDD5] focus:bg-[#B0CDD5] border-2 border-[#006685] min-h-[170px] md:min-h-[200px] lg:min-h-[175px]"
                    type="button"
                    onClick={() => {
                      navigateToPage('resume_plan')
                    }}
                  >
                    <img
                      className="w-1/2 max-w-[75px] object-contain md:w-full"
                      src={changeDeliveryDateImage}
                      alt="Resume Plan"
                    />
                    <span className="p-2">Resume Plan</span>
                  </button>
                </div>
              )}
              <div className="mb-4 flex w-1/2 flex-col md:mb-0 md:w-1/5 lg:mx-0">
                <button
                  data-testid="updateDogDetails"
                  className="flex flex-col items-center justify-center rounded-3xl md:p-4 hover:bg-[#B0CDD5] focus:bg-[#B0CDD5] border-2 border-[#006685] min-h-[170px] md:min-h-[200px] lg:min-h-[175px] mr-1 lg:mr-0"
                  type="button"
                  onClick={() => {
                    navigateToPage('update_dog')
                  }}
                >
                  <img
                    className="w-1/2 max-w-[75px] object-contain md:w-full"
                    src={updateDogsDetailsImage}
                    alt="Update Dog Details"
                  />
                  <span className="p-2">Update Dog Details</span>
                </button>
              </div>
              {hasActivePlan && (
                <div className="mb-4 flex w-1/2 flex-col md:mb-0 md:w-1/5">
                  <button
                    data-testid="changeDeliveryDate"
                    className="flex flex-col items-center justify-center rounded-3xl md:p-4 hover:bg-[#B0CDD5] focus:bg-[#B0CDD5] border-2 border-[#006685] min-h-[170px] md:min-h-[200px] lg:min-h-[175px] ml-1 lg:ml-0"
                    type="button"
                    onClick={() => {
                      navigateToPage('update_delivery')
                    }}
                  >
                    <img
                      className="w-1/2 max-w-[75px] object-contain md:w-full"
                      src={changeDeliveryDateImage}
                      alt="Change Delivery Date"
                    />
                    <span className="p-2">Change Delivery Date</span>
                  </button>
                </div>
              )}
              {hasActivePlan && (
                <div className="flex w-1/2 flex-col md:w-1/5">
                  <button
                    data-testid="changeRecipes"
                    className="flex flex-col items-center justify-center rounded-3xl md:p-4 hover:bg-[#B0CDD5] focus:bg-[#B0CDD5] border-2 border-[#006685] min-h-[170px] md:min-h-[200px] lg:min-h-[175px] mr-1 lg:mr-0"
                    type="button"
                    onClick={() => {
                      setNextPage('plan-recipes')
                      navigateToPage('update_recipe')
                    }}
                  >
                    <img
                      className="w-1/2 max-w-[75px] object-contain md:w-full"
                      src={changeRecipesImage}
                      alt="Change Recipes or Quantity"
                    />
                    <span className="p-2">Change Recipes or Quantity</span>
                  </button>
                </div>
              )}
              {hasActivePlan && (
                <div className="flex w-1/2 flex-col md:w-1/5">
                  <button
                    data-testid="rewards"
                    className="flex flex-col items-center justify-center rounded-3xl md:p-4 hover:bg-[#B0CDD5] focus:bg-[#B0CDD5] border-2 border-[#006685] min-h-[170px] md:min-h-[200px] lg:min-h-[175px] ml-1 lg:ml-0"
                    type="button"
                    onClick={() => {
                      navigateToPage('rewards')
                    }}
                  >
                    <img className="w-1/2 max-w-[75px] object-contain md:w-full" src={rewardsImage} alt="Rewards" />
                    <span className="p-2">Rewards</span>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="quick-actions-bottom-wave relative mx-auto hidden h-28 w-full bg-contain bg-bottom bg-no-repeat md:block 2xl:h-56 -mt-16 lg:-mt-12 2xl:-mt-32" />
        <div className="quick-actions-bottom-wave-mobile relative flex mx-auto -mt-1 h-12 xs:h-16 sm:h-20 md:h-30 w-full bg-cover bg-no-repeat md:hidden" />
        <div>
          <h2 className="mt-8 mb-4 text-center text-3xl sm:text-5xl">Your Plans</h2>
          {/*<div className="relative mx-auto mb-4 w-full rounded-xl border border-primary bg-ddBlue p-4 pl-16 text-sm">
            <StaticImage
              width={64}
              className="absolute -top-2 -left-2 md:-left-4 md:-top-4"
              src="../../../../images/change-delivery-date.svg"
              alt="Delivery in hot weather?"
              imgStyle={{ position: 'absolute' }}
              style={{ position: 'absolute' }}
            />
            <p className="font-bold">Due a delivery in this hot weather?</p>
            <p>
              Don't worry, we'll be putting in extra ice packs this week and even if your food arrives defrosted, it's
              still completely fine to refreeze as long as the packs are cool to touch. For any concerns, get in touch
              and we'll be happy to help.
            </p>
          </div>*/}
          <div>
            <PlansAccordion plans={summary?.plans} orders={orders} setNextPage={setNextPage} />
          </div>
        </div>
        <h2 data-testid="yourInformation" className="mt-8 mb-4 text-center text-3xl sm:text-5xl">
          Your Information
        </h2>
        <div className="-mr-4 flex w-full flex-row flex-wrap">
          <Formik
            initialValues={{
              name: summary?.customer?.name,
              surname: summary?.customer?.surname,
              telephone: summary?.customer?.phone,
              password: '',
              email: summary?.customer?.email,
              emailMarketing: customer?.consent?.marketing,
              smsMarketing: customer?.consent?.sms_consent,
              mailConsentFirstParty: customer?.consent?.mailConsentFirstParty,
              mailConsentThirdParty: customer?.consent?.mailConsentThirdParty,
              referrer: ''
            }}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
              dispatch(editCustomerDetails(values))
              setShowEditCustomer(false)
              setToastMsg('Personal details updated successfully')
              resetForm()
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset
              /* and other goodies */
            }) => (
              <form className="w-full pr-0 md:w-1/2 md:pr-4" onSubmit={handleSubmit}>
                <div className="relative flex h-full flex-col rounded-3xl bg-checkoutBG p-4">
                  <div className="flex items-center justify-between">
                    <div className="h3 text-xl sm:text-2xl md:ml-4">Your Details</div>
                  </div>
                  <div className="md:pl-4">
                    <div className="mt-4 flex flex-wrap justify-evenly">
                      <div className="w-full">
                        <div>
                          {!showEditCustomer && 'First name: '}
                          <span className="font-bold">
                            {!showEditCustomer && summary?.customer?.name !== undefined ? summary?.customer?.name : ''}
                          </span>
                        </div>
                        {showEditCustomer && (
                          <>
                            <div className="relative flex w-full flex-shrink-0 flex-row items-center">
                              <input
                                type="text"
                                name="name"
                                defaultValue={summary?.customer?.name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="peer mr-2 block w-full rounded-md border border-gray-300 bg-white pt-6 text-left focus:border-[#B0CDD5] focus:ring-0"
                              />
                              <label
                                className={`absolute transform cursor-text appearance-none duration-300 peer-focus:ml-0 peer-focus:-translate-y-3 peer-focus:scale-75 ${
                                  values.name ? 'ml-0 -translate-y-3 scale-75' : 'ml-4'
                                }`}
                                htmlFor="name"
                              >
                                First name<span className="ml-1">*</span>
                              </label>
                            </div>
                            {errors.name && touched.name && (
                              <span className="p-2 text-xs text-red-500">
                                {' '}
                                <ErrorMessage name="name" />{' '}
                              </span>
                            )}
                          </>
                        )}
                      </div>
                      <div className="mt-4 w-full">
                        <div>
                          {!showEditCustomer && 'Last name: '}
                          <span className="font-bold">
                            {!showEditCustomer && summary?.customer?.surname !== undefined
                              ? summary?.customer?.surname
                              : ''}
                          </span>
                        </div>
                        {showEditCustomer && (
                          <>
                            <div className="relative flex w-full flex-shrink-0 flex-row items-center">
                              <input
                                type="text"
                                name="surname"
                                defaultValue={summary?.customer?.surname}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="peer mr-2 block w-full rounded-md border border-gray-300 bg-white pt-6 text-left focus:border-[#B0CDD5] focus:ring-0"
                              />
                              <label
                                className={`absolute transform cursor-text appearance-none duration-300 peer-focus:ml-0 peer-focus:-translate-y-3 peer-focus:scale-75 ${
                                  values.name ? 'ml-0 -translate-y-3 scale-75' : 'ml-4'
                                }`}
                                htmlFor="surname"
                              >
                                Last name<span className="ml-1">*</span>
                              </label>
                            </div>
                            {errors.surname && touched.surname && (
                              <span className="p-2 text-xs text-red-500">
                                {' '}
                                <ErrorMessage name="surname" />{' '}
                              </span>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    <div className="mt-4 flex flex-wrap justify-evenly">
                      <div className="w-full">
                        <div>
                          {!showEditCustomer && 'Phone number: '}
                          <span className="font-bold">
                            {!showEditCustomer && summary?.customer?.phone !== undefined
                              ? summary?.customer?.phone
                              : ''}
                          </span>
                        </div>
                        {showEditCustomer && (
                          <>
                            <div className="relative flex w-full flex-shrink-0 flex-row items-center">
                              <input
                                autoComplete="tel"
                                type="tel"
                                name="telephone"
                                pattern="(((\+44)? ?(\(0\))? ?)|(0))( ?[0-9]{3,4}){3}$"
                                defaultValue={summary?.customer?.phone}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="peer mr-2 block w-full rounded-md border border-gray-300 bg-white pt-6 text-left focus:border-[#B0CDD5] focus:ring-0"
                              />
                              <label
                                className={`absolute transform cursor-text appearance-none duration-300 peer-focus:-ml-3 peer-focus:-translate-y-3 peer-focus:scale-75 ${
                                  values.telephone ? '-ml-3 -translate-y-3 scale-75' : 'ml-4'
                                }`}
                                htmlFor="telephone"
                              >
                                Phone number<span className="ml-1">*</span>
                              </label>
                            </div>
                            {errors.telephone && touched.telephone && (
                              <span className="p-2 text-xs text-red-500">
                                {' '}
                                <ErrorMessage name="telephone" />{' '}
                              </span>
                            )}
                          </>
                        )}
                      </div>
                      <div className="mt-4 w-full flex-wrap">
                        <div>
                          {!showEditCustomer && <span>Email address: </span>}
                          <span className="break-all font-bold">
                            {!showEditCustomer && summary?.customer?.email !== undefined
                              ? summary?.customer?.email
                              : ''}
                          </span>
                        </div>
                        {showEditCustomer && (
                          <>
                            <div className="relative flex w-full flex-shrink-0 flex-row items-center">
                              <input
                                type="text"
                                name="email"
                                defaultValue={summary?.customer?.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={!spoofMode}
                                className={`${
                                  !spoofMode ? 'cursor-not-allowed' : ''
                                } peer mr-2 block w-full rounded-md border border-gray-300 bg-white pt-6 text-left focus:border-[#B0CDD5] focus:ring-0 disabled:bg-gray-300`}
                              />
                              <label
                                className={`absolute transform cursor-text appearance-none duration-300 peer-focus:ml-1 peer-focus:-translate-y-3 peer-focus:scale-75 ${
                                  values.email ? 'ml-1 -translate-y-3 scale-75' : 'ml-4'
                                }`}
                                htmlFor="email"
                              >
                                Email<span className="ml-1">*</span>
                              </label>
                            </div>
                            {errors.email && touched.email && (
                              <span className="p-2 text-xs text-red-500">
                                {' '}
                                <ErrorMessage name="email" />{' '}
                              </span>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    {!showEditCustomer && (
                      <>
                        <div className="relative mt-1 flex flex-col pt-6">
                          <div className="h3 text-xl sm:text-2xl">Marketing consent</div>
                          <p className="mt-2 font-bold font-sans text-base">Receive discounts and tips via:</p>
                          <label className="inline-flex">
                            <span className="mt-2 flex-wrap text-sm font-bold">
                              Email: <span className="font-medium">{customer?.consent?.marketing ? 'Yes' : 'No'}</span>
                            </span>
                          </label>
                          <label className="inline-flex">
                            <span className="mt-2 flex-wrap text-sm font-bold">
                              SMS: <span className="font-medium">{customer?.consent?.sms_consent ? 'Yes' : 'No'}</span>
                            </span>
                          </label>
                          <label className="inline-flex">
                            <span className="mt-2 flex-wrap text-sm font-bold">
                              Post:{' '}
                              <span className="font-medium">
                                {customer?.consent?.mailConsentFirstParty ? 'Yes' : 'No'}
                              </span>
                            </span>
                          </label>
                        </div>

                        <p className="mt-8 font-bold font-sans text-base">
                          Receive offers from other trusted brands via:
                        </p>
                        <label className="inline-flex">
                          <span className="mt-2 flex-wrap text-sm font-bold">
                            Post:{' '}
                            <span className="font-medium">
                              {customer?.consent?.mailConsentThirdParty ? 'Yes' : 'No'}
                            </span>
                          </span>
                        </label>
                      </>
                    )}
                    {showEditCustomer && (
                      <div className="relative mt-8 flex flex-col">
                        <div className="flex items-center justify-between">
                          <div className="h3 text-xl sm:text-2xl">Marketing consent</div>
                        </div>
                        <p className="mt-6 font-bold font-sans text-base">Receive discounts and tips via:</p>
                        <label className="inline-flex">
                          <Field
                            id="offers-email"
                            type="checkbox"
                            name="emailMarketing"
                            className="text-fourth-checkbox mt-2 h-6 w-6 rounded-[4px]"
                          />
                          <span className="mt-3 flex-wrap pl-3 text-sm">Email</span>
                        </label>
                        <label className="inline-flex">
                          <Field
                            id="offers-sms"
                            type="checkbox"
                            name="smsMarketing"
                            className="text-fourth-checkbox mt-2 h-6 w-6 rounded-[4px]"
                          />
                          <span className="mt-3 flex-wrap pl-3 text-sm">SMS</span>
                        </label>
                        <label className="inline-flex">
                          <Field
                            id="offers-post"
                            type="checkbox"
                            name="mailConsentFirstParty"
                            className="text-fourth-checkbox mt-2 h-6 w-6 rounded-[4px]"
                          />
                          <span className="mt-3 flex-wrap pl-3 text-sm">Post</span>
                        </label>
                        <p className="mt-6 font-bold font-sans text-base">
                          Receive offers from other trusted brands via:
                        </p>
                        <label className="inline-flex">
                          <Field
                            id="offers-post"
                            type="checkbox"
                            name="mailConsentThirdParty"
                            className="text-fourth-checkbox mt-2 h-6 w-6 rounded-[4px]"
                          />
                          <span className="mt-3 flex-wrap pl-3 text-sm">Post</span>
                        </label>
                        {spoofMode && (
                          <>
                            {/* Referrer email */}
                            <div className="relative flex w-full flex-shrink-0 flex-row items-center mt-4">
                              <input
                                type="text"
                                name="referrer"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={!spoofMode}
                                className={`${
                                  !spoofMode ? 'cursor-not-allowed' : ''
                                } peer mr-2 block w-full rounded-md border border-gray-300 bg-white pt-6 text-left focus:border-[#B0CDD5] focus:ring-0 disabled:bg-gray-300`}
                              />
                              <label
                                className={`absolute transform cursor-text appearance-none duration-300 peer-focus:ml-1 peer-focus:-translate-y-3 peer-focus:scale-75 ${
                                  values.email ? 'ml-1 -translate-y-3 scale-75' : 'ml-4'
                                }`}
                                htmlFor="email"
                              >
                                Referrer Email
                              </label>
                            </div>
                            {errors.referrer && touched.referrer && (
                              <span className="p-2 text-xs text-red-500">
                                {' '}
                                <ErrorMessage name="referrer" />{' '}
                              </span>
                            )}
                          </>
                        )}
                        <div className="flex flex-col items-center justify-center rounded-3xl hover:underline md:p-2">
                          <a
                            className="mt-2 text-sm text-primary hover:underline focus:underline"
                            target="_blank"
                            href="/signup/privacy-policy"
                            rel="noreferrer"
                          >
                            Privacy Policy
                          </a>
                        </div>
                      </div>
                    )}
                    <div className="relative left-0 right-0 bottom-0 mt-4 flex w-full flex-col justify-center md:flex-row">
                      <div>
                        <button
                          data-testid="editPassword"
                          className="my-5 mr-4 w-full rounded-full border-2 border-primary py-2 px-4 hover:underline focus:underline md:my-0 md:w-auto"
                          type="button"
                          onClick={() => {
                            setPasswordModalState('Edit')
                            setShowPasswordModal(true)
                          }}
                        >
                          Edit password
                        </button>
                      </div>
                      {!showEditCustomer && (
                        <div>
                          <button
                            type="button"
                            className="btn-primary w-full justify-center !border-[#2A6482] hover:!bg-[#2A6482] hover:!text-white focus:!bg-[#2A6482] focus:!text-white md:w-auto"
                            onClick={() => handleEditCustomerModalShow()}
                          >
                            Edit details
                          </button>
                        </div>
                      )}
                    </div>
                    {showEditCustomer && (
                      <div className="md:col-span-2">
                        <div className="flex flex-row items-center justify-end">
                          <button
                            className="btn-secondary mr-10"
                            onClick={() => {
                              handleReset()
                              handleEditCustomerModalShow()
                            }}
                          >
                            Cancel
                          </button>
                          <button type="submit" className="btn-primary disabled:opacity-50">
                            Save
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </form>
            )}
          </Formik>
          <Formik
            initialValues={{
              name: summary?.customer?.name,
              surname: summary?.customer?.surname,
              address1: summary?.customer?.address?.billing?.address1,
              address2: summary?.customer?.address?.billing?.address2,
              city: summary?.customer?.address?.billing?.city,
              county: summary?.customer?.address?.billing?.county,
              postcode: summary?.customer?.address?.billing?.postcode,
              card: new ElementState()
            }}
            validationSchema={billingValidationSchema}
            onSubmit={async values => {
              setIsSubmitting(true)
              if (!stripe || !elements) return
              // @ts-ignore
              const element = elements.getElement(CardElement)!
              const { name, surname, address1, address2, city, county, postcode } = values
              let payment = null
              if (selectedPaymentOption === 'newCard') {
                payment = await stripe.createPaymentMethod({
                  type: 'card',
                  card: element,
                  billing_details: {
                    name: `${name} ${surname}`,
                    address: {
                      line1: address1,
                      line2: address2,
                      city,
                      state: county,
                      postal_code: postcode
                    }
                  }
                })
              }
              if (selectedPaymentOption == 'currentCard') {
                payment = customer.payment
              }
              if (selectedPaymentOption == 'appleGpay') {
                payment = payEvent
              }
              const billing = {
                name,
                surname,
                address1,
                address2,
                city,
                county,
                postcode
              }

              if (payment.error) {
                //failure
                console.error(JSON.stringify(payment.error, null, 2))
                setErrorMessage(payment.error.message)
                return
              }
              setErrorMessage('')
              dispatch(
                editCustomerDetails({
                  billing,
                  payment: payment.paymentMethod
                })
              )
              setToastMsg('Billing details updated successfully')
              setShowEditBilling(false)
              setIsSubmitting(false)
              setSelectedPaymentOption('currentCard')
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset
              /* and other goodies */
            }) => (
              <form className="w-full pr-0 md:w-1/2" onSubmit={handleSubmit}>
                <div className="relative mt-4 flex h-full flex-col rounded-3xl bg-checkoutBG p-4 pb-0 md:mt-0 md:pb-4">
                  {summary?.customer?.payment?.type === 'card' && summary?.customer?.payment?.status !== 'valid' && (
                    <div className="bg-transparent py-3 text-yellow-700 dark:text-yellow-800" role="warn">
                      <div className="flex">
                        <div className="my-auto py-1">
                          <FontAwesomeIcon icon={faWarning} className="mr-4 h-6 w-6 fill-current text-yellow-700" />
                        </div>
                        <div>
                          <p className="font-bold">Payment Check</p>
                          <p className="text-sm">
                            {summary?.customer?.payment?.status === 'expiring' &&
                              'Your card will expire soon. Please update'}
                            {summary?.customer?.payment?.status === 'expired' && 'Your card has expired. Please update'}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="flex items-center justify-between">
                    <div data-testid="yourBillingDetails" className="h3 text-xl sm:text-2xl md:ml-4">
                      Your Billing Details
                    </div>
                  </div>
                  <div className={`mt-5 flex flex-row md:pl-4 ${showEditBilling && 'flex-wrap'}`}>
                    <div className="w-full">
                      {showEditBilling && (
                        <p className="md:5/6 mb-4 text-justify font-medium text-primary sm:w-11/12 xl:w-3/4">
                          If you change your billing address you may also need to update your delivery address on the{' '}
                          <RouterLink className="underline hover:text-base" to="/account/plan/">
                            plan page
                          </RouterLink>
                          .
                        </p>
                      )}
                      <div className="mb-4">Billing Address:</div>
                      {!showEditBilling && (
                        <div className="font-semibold">
                          <div>
                            {summary?.customer?.address?.billing?.name || summary?.customer?.name}{' '}
                            {summary?.customer?.address?.billing?.surname || summary?.customer?.surname}
                          </div>
                          <div>
                            {`${
                              summary?.customer?.address?.billing?.address1
                                ? summary?.customer?.address?.billing?.address1 + ','
                                : ''
                            }`}
                          </div>
                          <div>
                            {`${
                              summary?.customer?.address?.billing?.address2
                                ? summary?.customer?.address?.billing?.address2 + ','
                                : ''
                            }`}
                          </div>
                          <div>
                            {`${
                              summary?.customer?.address?.billing?.city ? summary?.customer?.address?.billing?.city : ''
                            } ${
                              summary?.customer?.address?.billing?.county
                                ? summary?.customer?.address?.billing?.county
                                : ''
                            }`}
                          </div>
                          <div>
                            {`${
                              summary?.customer?.address?.billing?.postcode
                                ? summary?.customer?.address?.billing?.postcode
                                : ''
                            }`}
                          </div>
                        </div>
                      )}
                      {showEditBilling && (
                        <div className="flex flex-col gap-y-2">
                          <div className="mt-4 flex w-full flex-row gap-x-2 pr-2">
                            <div className="flex w-full">
                              <div>{!showEditBilling && 'First name: '}</div>
                              <div className="relative flex w-full flex-shrink-0 flex-row items-center">
                                <input
                                  data-testid="firstName"
                                  type="text"
                                  className="peer block w-full rounded-md border border-gray-300 bg-white pt-6 text-left focus:border-[#B0CDD5] focus:ring-0 disabled:bg-gray-300"
                                  name="name"
                                  defaultValue={summary?.customer?.address?.billing?.name}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <label
                                  className={`absolute transform cursor-text appearance-none duration-300 peer-focus:ml-0.5 peer-focus:-translate-y-3 peer-focus:scale-75 ${
                                    values.name ? 'ml-0.5 -translate-y-3 scale-75' : 'ml-4'
                                  }`}
                                  htmlFor="name"
                                >
                                  First name<span className="ml-1">*</span>
                                </label>
                              </div>
                            </div>
                            <div className="flex w-full">
                              <div>{!showEditBilling && 'Last name: '}</div>
                              <div className="relative flex w-full flex-shrink-0 flex-row items-center">
                                <input
                                  data-testid="surname"
                                  type="text"
                                  className="peer block w-full rounded-md border border-gray-300 bg-white pt-6 text-left focus:border-[#B0CDD5] focus:ring-0 disabled:bg-gray-300"
                                  name="surname"
                                  defaultValue={summary?.customer?.address?.billing?.surname}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <label
                                  className={`absolute transform cursor-text appearance-none duration-300 peer-focus:ml-0.5 peer-focus:-translate-y-3 peer-focus:scale-75 ${
                                    values.name ? 'ml-0.5 -translate-y-3 scale-75' : 'ml-4'
                                  }`}
                                  htmlFor="surname"
                                >
                                  Last name<span className="ml-1">*</span>
                                </label>
                              </div>
                            </div>
                          </div>
                          {((errors.name && touched.name) || (errors.surname && touched.surname)) && (
                            <div className="flex w-full flex-col">
                              {errors.name && touched.name && (
                                <div className="p-2 text-xs text-red-500">
                                  {' '}
                                  <ErrorMessage name="name" />{' '}
                                </div>
                              )}
                              {errors.surname && touched.surname && (
                                <div className="p-2 text-xs text-red-500">
                                  {' '}
                                  <ErrorMessage name="surname" />{' '}
                                </div>
                              )}
                            </div>
                          )}
                          <div>
                            <div>{!showEditBilling && 'Address line 1: '}</div>
                            <div className="relative flex w-full flex-shrink-0 flex-row items-center">
                              <input
                                data-testid="address1"
                                type="text"
                                name="address1"
                                defaultValue={summary?.customer?.address?.billing?.address1}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="peer mr-2 block w-full rounded-md border border-gray-300 bg-white pt-6 text-left focus:border-[#B0CDD5] focus:ring-0 disabled:bg-gray-300"
                              />
                              <label
                                className={`absolute transform cursor-text appearance-none duration-300 peer-focus:ml-0 peer-focus:-translate-y-3 peer-focus:scale-75 ${
                                  values.name ? 'ml-0 -translate-y-3 scale-75' : 'ml-4'
                                }`}
                                htmlFor="address1"
                              >
                                Address line 1<span className="ml-1">*</span>
                              </label>
                            </div>
                            {errors.address1 && touched.address1 && (
                              <div className="p-2 text-xs text-red-500">
                                {' '}
                                <ErrorMessage name="address1" />{' '}
                              </div>
                            )}
                          </div>
                          <div>
                            <div>{!showEditBilling && 'Address line 2: '}</div>
                            <div className="relative flex w-full flex-shrink-0 flex-row items-center">
                              <input
                                data-testid="address2"
                                type="text"
                                name="address2"
                                defaultValue={summary?.customer?.address?.billing?.address2}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="peer mr-2 block w-full rounded-md border border-gray-300 bg-white pt-6 text-left focus:border-[#B0CDD5] focus:ring-0 disabled:bg-gray-300"
                              />
                              <label
                                className={`absolute transform cursor-text appearance-none duration-300 peer-focus:ml-0 peer-focus:-translate-y-3 peer-focus:scale-75 ${
                                  values.name ? 'ml-0 -translate-y-3 scale-75' : 'ml-4'
                                }`}
                                htmlFor="address2"
                              >
                                Address line 2
                              </label>
                            </div>
                            {errors.address2 && touched.address2 && (
                              <div className="p-2 text-xs text-red-500">
                                {' '}
                                <ErrorMessage name="address2" />{' '}
                              </div>
                            )}
                          </div>
                          <div>
                            <div>{!showEditBilling && 'City: '}</div>
                            <div className="relative flex w-full flex-shrink-0 flex-row items-center">
                              <input
                                data-testid="city"
                                type="text"
                                name="city"
                                defaultValue={summary?.customer?.address?.billing?.city}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="peer mr-2 block w-full rounded-md border border-gray-300 bg-white pt-6 text-left focus:border-[#B0CDD5] focus:ring-0 disabled:bg-gray-300"
                              />
                              <label
                                className={`absolute transform cursor-text appearance-none duration-300 peer-focus:ml-2 peer-focus:-translate-y-3 peer-focus:scale-75 ${
                                  values.name ? 'ml-2 -translate-y-3 scale-75' : 'ml-4'
                                }`}
                                htmlFor="city"
                              >
                                City<span className="ml-1">*</span>
                              </label>
                            </div>
                            {errors.city && touched.city && (
                              <div className="p-2 text-xs text-red-500">
                                {' '}
                                <ErrorMessage name="city" />{' '}
                              </div>
                            )}
                          </div>
                          <div>
                            <div>{!showEditBilling && 'County: '}</div>
                            <div className="relative flex w-full flex-shrink-0 flex-row items-center">
                              <input
                                data-testid="county"
                                type="text"
                                name="county"
                                defaultValue={summary?.customer?.address?.billing?.county}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="peer mr-2 block w-full rounded-md border border-gray-300 bg-white pt-6 text-left focus:border-[#B0CDD5] focus:ring-0 disabled:bg-gray-300"
                              />
                              <label
                                className={`absolute transform cursor-text appearance-none duration-300 peer-focus:ml-1 peer-focus:-translate-y-3 peer-focus:scale-75 ${
                                  values.name ? 'ml-1 -translate-y-3 scale-75' : 'ml-4'
                                }`}
                                htmlFor="county"
                              >
                                County<span className="ml-1">*</span>
                              </label>
                            </div>
                            {errors.county && touched.county && (
                              <div className="p-2 text-xs text-red-500">
                                {' '}
                                <ErrorMessage name="county" />{' '}
                              </div>
                            )}
                          </div>
                          <div>
                            <div>{!showEditBilling && 'Postcode: '}</div>
                            <div className="relative flex w-full flex-shrink-0 flex-row items-center">
                              <input
                                data-testid="postcode"
                                type="text"
                                name="postcode"
                                defaultValue={summary?.customer?.address?.billing?.postcode}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="peer mr-2 block w-full rounded-md border border-gray-300 bg-white pt-6 text-left focus:border-[#B0CDD5] focus:ring-0 disabled:bg-gray-300"
                              />
                              <label
                                className={`absolute transform cursor-text appearance-none duration-300 peer-focus:ml-1 peer-focus:-translate-y-3 peer-focus:scale-75 ${
                                  values.name ? 'ml-1 -translate-y-3 scale-75' : 'ml-4'
                                }`}
                                htmlFor="postcode"
                              >
                                Postcode<span className="ml-1">*</span>
                              </label>
                            </div>
                            {errors.postcode && touched.postcode && (
                              <div className="p-2 text-xs text-red-500">
                                {' '}
                                <ErrorMessage name="postcode" />{' '}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className={`w-full ${showEditBilling ? 'mt-4' : 'sm:ml-4'}`}>
                      {!showEditBilling && (
                        <div ref={paymentRef} className="mb-4">
                          Payment:
                        </div>
                      )}
                      {!showEditBilling && summary?.customer?.payment?.type === 'card' && (
                        <div className="font-bold">{`${
                          summary?.customer?.payment?.brand[0]?.toUpperCase() +
                          summary?.customer?.payment?.brand?.slice(1)
                        } ending in ${summary?.customer?.payment?.lastFour}`}</div>
                      )}
                      {showEditBilling && (
                        <div className="mt-4 mb-4 flex flex-col justify-center">
                          <div className="font-bold font-sans">Payment method:</div>
                          {summary?.customer?.payment?.type === 'card' && (
                            <label className="inline-flex my-1">
                              <Field
                                data-testid="currentCard"
                                id="currentCard"
                                type="checkbox"
                                name="currentCard"
                                className="rounded-checkbox mt-3 h-6 w-6 border border-black"
                                checked={selectedPaymentOption === 'currentCard'}
                                onChange={() => setSelectedPaymentOption('currentCard')}
                              />
                              <div className="mt-3 pl-3 font-medium font-sans text-base">
                                Pay with {renderPaymentImage()}
                                {` ending in ${summary?.customer?.payment?.lastFour}`}
                              </div>
                            </label>
                          )}
                          {paymentRequest && (
                            <label className="inline-flex my-1">
                              <Elements stripe={stripePromise}>
                                <button
                                  className=""
                                  onClick={() => {
                                    if (paymentRequest) {
                                      paymentRequest.show()
                                    }
                                  }}
                                >
                                  {' '}
                                  <Field
                                    id="appleGpay"
                                    type="checkbox"
                                    name="appleGpay"
                                    className="rounded-checkbox mt-3 h-6 w-6 border border-black"
                                    checked={selectedPaymentOption === 'appleGpay'}
                                    onChange={() => setSelectedPaymentOption('appleGpay')}
                                  />
                                </button>
                              </Elements>
                              <div className="mt-3 pl-3 font-medium font-sans text-base">
                                Pay with{' '}
                                {paymentRequest._activeBackingLibraryName === 'APPLE_PAY' && (
                                  <StaticImage
                                    className="ml-2"
                                    alt="Apple Pay"
                                    objectFit="contain"
                                    width={50}
                                    src="../../../../images/account/ApplePay.svg"
                                  />
                                )}
                                {(paymentRequest._activeBackingLibraryName === 'GOOGLE_PAY' ||
                                  (paymentRequest._activeBackingLibraryName === 'BROWSER' && browser !== 'Apple')) && (
                                  <StaticImage
                                    className="ml-2"
                                    alt="Google Pay"
                                    objectFit="contain"
                                    width={50}
                                    src="../../../../images/account/GPay.svg"
                                  />
                                )}
                              </div>
                            </label>
                          )}

                          <label className="inline-flex my-2">
                            <Field
                              id="newCard"
                              type="checkbox"
                              name="newCard"
                              className="rounded-checkbox mt-3 h-6 w-6 border border-black"
                              checked={selectedPaymentOption === 'newCard'}
                              onChange={() => setSelectedPaymentOption('newCard')}
                            />
                            <div className="mt-3 pl-3 font-medium font-sans text-base">Add new card</div>
                          </label>
                          {selectedPaymentOption === 'newCard' && (
                            <div className="mt-1">
                              <Checkout errorMessage={localErrorMessage} />
                            </div>
                          )}
                          {localErrorMessage && <div className="text-red-500">{`${localErrorMessage}`}</div>}
                        </div>
                      )}
                      <div className="flex flex-row items-center">
                        {!showEditBilling && summary?.customer?.payment?.type === 'apple_pay' && (
                          <StaticImage width={50} src="../../../../images/apple-pay-logo.svg" alt="Apple Pay Logo" />
                        )}
                        {!showEditBilling && summary?.customer?.payment?.type === 'google_pay' && (
                          <StaticImage width={50} src="../../../../images/google-pay-logo.svg" alt="Google Pay Logo" />
                        )}{' '}
                        {!showEditBilling && customer?.payment?.type !== 'card' && customer?.payment?.lastFour && (
                          <div className="pl-2 font-bold">{` ending in ${customer?.payment?.lastFour}`}</div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="mt-1 flex flex-wrap justify-between md:pl-4">
                    {summary && summary.customer.credit !== 0 && (
                      <div className="relative md:pb-10">
                        <span className="font-bold">£{(summary.customer.credit / 100).toFixed(2)} credit</span>
                        <span> will be automatically applied to your next payment</span>
                      </div>
                    )}
                  </div>
                  {showEditBilling && (
                    <div className="md:col-span-2">
                      <div className="flex flex-row items-center justify-end">
                        <button
                          className="btn-secondary mr-8"
                          onClick={() => {
                            handleReset()
                            handleEditBillingModalShow()
                            setErrorMessage('')
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          data-testid="save"
                          type="submit"
                          disabled={isSubmitting}
                          className="btn-primary disabled:opacity-50"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  )}
                  {!showEditBilling && (
                    <div className="relative bottom-0 flex w-full flex-row justify-center md:absolute md:bottom-4">
                      <div className="flex w-full flex-row justify-center">
                        <button
                          data-testid="editBillingDetails"
                          type="button"
                          className="btn-primary w-full justify-center !border-[#2A6482] hover:!bg-[#2A6482] hover:!text-white focus:!bg-[#2A6482] focus:!text-white md:w-auto"
                          onClick={() => handleEditBillingModalShow()}
                        >
                          Edit billing details
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </form>
            )}
          </Formik>
        </div>
        <Toast showToast={showToast} msg={toastMsg} handleSetShowToast={handleSetShowToast} />
        <EditPasswordModal
          show={showPasswordModal}
          setShow={setShowPasswordModal}
          handleSetShowToast={handleSetShowToast}
          setToastMsg={setToastMsg}
          passwordModalState={passwordModalState}
          setPasswordUpdated={setPasswordUpdated}
        />
        {error && (
          <ErrorModal
            show={showPopup}
            setShow={setShowPopup}
            errorMessage={errorMessage && errorMessage?.popup?.message}
            url={null}
            action={errorMessage && errorMessage?.popup?.action}
            actionText={errorMessage && errorMessage?.popup?.actionText}
          />
        )}
        {showRedZoneModal && (
          <EditFutureOrderModal
            show={showRedZoneModal}
            setShow={setShowRedZoneModal}
            confirmButton={redZoneConfirmButton}
          />
        )}
        <FeedbackFormAlternative
          name={summary?.customer?.name}
          email={summary?.customer?.email}
          isNewCustomer={summary?.customer?.isNewCustomer}
          orders={orders}
        />
      </div>
    )
  )
}

export default AccountCards
