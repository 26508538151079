import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dialog, Transition } from '@headlessui/react'
import { Storage } from 'aws-amplify'
import React, { Fragment, useRef, useState } from 'react'
import AvatarEditor from 'react-avatar-editor'

export default function CropperModal({ src, modalOpen, setModalOpen, setPreview, dogId }) {
  const [slideValue, setSlideValue] = useState(10)
  const cropRef = useRef(null)
  const [uploadingImage, setIsUploadedImage] = useState(false)

  const uploadImage = async (imageUrlObj: any) => {
    try {
      await Storage.put(`${dogId}.jpg`, imageUrlObj, {
        level: 'private',
        metadata: { processed: 'false' }
      })
      if (typeof window.gtag !== 'undefined') {
        window.gtag('event', 'profile_picture_uploaded', {
          page: 'account_dogs'
        })
      }
      console.log('Profile image uploaded')
    } catch (error) {
      console.log('Error uploading profile image : ', error)
    }
  }

  const handleSave = async () => {
    if (cropRef) {
      setIsUploadedImage(true)
      const dataUrl = cropRef.current.getImage().toDataURL()
      const result = await fetch(dataUrl)
      const blob = await result.blob()
      setPreview(URL.createObjectURL(blob))
      await uploadImage(blob)
      setIsUploadedImage(false)
      setModalOpen(false)
    }
  }

  return (
    <Transition.Root show={modalOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-50 overflow-y-auto" onClose={setModalOpen}>
        <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block w-full max-w-md transform rounded-3xl bg-white p-4 text-center shadow-xl transition-all sm:my-8 sm:p-8 sm:align-middle">
              <div>
                <FontAwesomeIcon
                  icon={faClose}
                  fontSize={24}
                  className="absolute top-4 right-4 cursor-pointer md:top-8 md:right-8"
                  onClick={() => setModalOpen(false)}
                />
                <div className="mb-1">
                  <Dialog.Title as="h3" className="mb-4 text-center text-lg font-medium leading-6">
                    Edit your dog's profile picture
                  </Dialog.Title>
                </div>
                <div className="w-[300px] h-[300px] flex justify-center items-center mx-auto">
                  <AvatarEditor
                    ref={cropRef}
                    image={src}
                    style={{ width: '100%', height: '100%' }}
                    border={50}
                    borderRadius={150}
                    color={[0, 0, 0, 0.72]}
                    scale={slideValue / 10}
                    rotate={0}
                  />
                </div>
              </div>
              <input
                className="my-2"
                style={{ width: '300px' }}
                type="range"
                value={slideValue}
                name="points"
                min="10"
                max="50"
                onChange={e => setSlideValue(e.target.value)}
              />
              <div className="mx-auto grid grid-cols-3">
                <div className="col-start-2 inline-flex justify-center">
                  <button
                    disabled={uploadingImage}
                    onClick={() => {
                      handleSave()
                    }}
                    className="btn-cta disabled:opacity-50"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
