import { faAngleLeft, faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dialog, Transition } from '@headlessui/react'
import ErrorToast from 'components/ui/toast/ErrorToast'
import Toast from 'components/ui/toast/Toast'
import dayjs from 'dayjs'
import { Form, Formik } from 'formik'
import { navigate } from 'gatsby'
import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { postReschedulePlan } from 'state/actions/account/accountPlanAction'
import { fetchPlanSchedule } from 'state/apis/account/planApi'
import EditFutureOrderModal from './EditFutureOrderModal'

export default function StopDeliveriesModal({ show, setShow, plans }) {
  const { spoofMode, spoofedUserId } = useSelector((state: RootState) => state.ui)
  const [selectedPlan, setSelectedPlan] = useState(undefined)
  const [activeDeliveryDate, setActiveDeliveryDate] = useState('')
  const [redZoneConfirmButton, setRedZoneConfirmButton] = useState(<></>)
  const [redZoneModalShow, setRedZoneModalShow] = useState(false)
  const [shippingDates, setShippingDates] = useState([])
  const [errorToast, setErrorToast] = useState(false)
  const [toastMsg, setToastMsg] = useState('')
  const [showToast, setShowToast] = useState(false)
  const dispatch = useDispatch()

  const formikRef = React.useRef<any>()

  useEffect(() => {
    if (plans?.length === 1) {
      setSelectedPlan(plans[0])
      setActiveDeliveryDate(plans[0].nextDelivery)
    }
  }, [show])

  useEffect(() => {
    const getRescheduleDates = async () => {
      const dates = await fetchPlanSchedule(selectedPlan[0]?.subscriptionId)
      setShippingDates(dates.shippingDates)
    }
    if (selectedPlan?.subscriptionId) {
      getRescheduleDates()
    }
  }, [activeDeliveryDate])

  // Get closest valid delivery date, or the next closest match afterwards
  // Ensure customer doesn't get charged more than they're currently paying
  const getNextAvailableDeliveryDate = ({
    targetDeliveryDate,
    targetDeliveryPrice,
    daysToAdd,
    availableDates
  }: any) => {
    const attemptDeliveryDate = dayjs(targetDeliveryDate).add(daysToAdd, 'days').format('YYYY-MM-DD')

    // Filter for the next available date which is the same price or cheaper
    const nextMatch = availableDates.find(d => d.date >= attemptDeliveryDate && d.price <= targetDeliveryPrice)
    return nextMatch.date
  }

  const handleSetShowToast = (show: boolean) => {
    setShowToast(show)
  }
  const handleSetErrorToast = (show: boolean) => {
    setErrorToast(show)
  }

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={() => {
          setSelectedPlan(undefined)
          setShow(false)
        }}
      >
        <div className="flex min-h-screen items-center justify-center px-4 pt-4 pb-20 text-center sm:p-0">
          <Transition.Child
            as={'div'}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden align-middle sm:inline-block sm:h-full" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={'div'}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            className={'w-full'}
          >
            <div className="relative inline-block w-full max-w-xl transform rounded-3xl bg-white p-4 text-left align-middle shadow-xl transition-all sm:my-8 sm:p-8">
              <div
                className={`absolute top-0 right-0 flex h-12 w-12 cursor-pointer flex-col items-center justify-center p-2 md:top-4 md:right-4`}
                onClick={() => setShow(false)}
              >
                <FontAwesomeIcon
                  icon={faClose}
                  fontSize={24}
                  onClick={() => {
                    setShow(false)
                    setSelectedPlan(undefined)
                  }}
                />
              </div>
              {selectedPlan && (
                <div>
                  <div>
                    <div data-testid="whatWouldYouLikeToDo">
                      <Dialog.Title as="h3" className="mb-2 text-center text-lg font-medium leading-6">
                        What would you like to do?
                      </Dialog.Title>
                      <Formik
                        innerRef={formikRef}
                        initialValues={{
                          editPlanRadio: '',
                          shippingDates: activeDeliveryDate ? new Date(activeDeliveryDate) : null
                        }}
                        onSubmit={async (formikValues, { setSubmitting }) => {
                          setSubmitting(true)
                          console.log({ selectedPlan })
                          if (formikValues.editPlanRadio === 'skipDelivery') {
                            const newDeliveryDate = getNextAvailableDeliveryDate({
                              targetDeliveryDate: formikValues.shippingDates,
                              targetDeliveryPrice: selectedPlan?.validDeliveryDate?.price || 399,
                              daysToAdd: 7,
                              availableDates: shippingDates
                            })

                            try {
                              await dispatch(
                                postReschedulePlan({
                                  subscriptionId: selectedPlan.subscriptionId,
                                  nextDelivery: dayjs(newDeliveryDate).format('YYYY-MM-DD')
                                })
                              )
                              setToastMsg(`Your new delivery date is ${dayjs(newDeliveryDate).format('dddd Do MMMM')}`)
                              setShowToast(true)
                            } catch (e) {
                              console.error(JSON.stringify(e, null, 2))
                              setErrorToast(true)
                            }
                            setTimeout(() => {
                              setSubmitting(false)
                              setSelectedPlan(undefined)
                              setShow(false)
                            }, 2000)
                          } else if (selectedPlan) {
                            if (formikValues.editPlanRadio === 'pausePlan') {
                              const url = spoofMode
                                ? `/account/plan/${selectedPlan.subscriptionId}/status/pause/?userId=` + spoofedUserId
                                : `/account/plan/${selectedPlan.subscriptionId}/status/pause/`
                              navigate(url)
                              setSubmitting(false)
                              setShow(false)
                            } else if (formikValues.editPlanRadio === 'cancelPlan') {
                              const url = spoofMode
                                ? `/account/plan/${selectedPlan.subscriptionId}/status/cancel/?userId=` + spoofedUserId
                                : `/account/plan/${selectedPlan.subscriptionId}/status/cancel/`
                              navigate(url)
                              setSubmitting(false)
                              setShow(false)
                            }
                          }
                        }}
                      >
                        {props => {
                          return (
                            <Form>
                              <div className="mt-6 flex w-full flex-col items-center">
                                <button
                                  className={`btn-primary focus mb-4 w-full justify-center rounded-full border-2 p-2 text-center !drop-shadow-none`}
                                  disabled={props.isSubmitting}
                                  onClick={() => {
                                    props.setFieldValue('editPlanRadio', 'skipDelivery')
                                    if (typeof window.gtag !== 'undefined') {
                                      window.gtag('event', 'select_skip_delivery', {
                                        page: 'modal_edit_plan',
                                        subscription_id: selectedPlan.subscriptionId
                                      })
                                    }

                                    props.handleSubmit()
                                  }}
                                  type="button"
                                >
                                  Skip delivery by a week
                                </button>
                              </div>
                              {selectedPlan?.status !== 'future' && (
                                <>
                                  <div className="flex w-full flex-col items-center">
                                    <button
                                      className={`btn-primary focus mb-4 w-full justify-center rounded-full border-2 p-2 text-center !drop-shadow-none`}
                                      onClick={() => {
                                        props.setFieldValue('editPlanRadio', 'pausePlan')
                                        if (typeof window.gtag !== 'undefined') {
                                          window.gtag('event', 'select_pause_plan', {
                                            page: 'modal_edit_plan',
                                            subscription_id: selectedPlan.subscriptionId
                                          })
                                        }

                                        props.handleSubmit()
                                      }}
                                      type="button"
                                    >
                                      Pause {selectedPlan?.dogNames}'s plan
                                    </button>
                                  </div>
                                </>
                              )}
                              <>
                                <div className="flex w-full flex-col items-center">
                                  <button
                                    data-testid="cancelPlan"
                                    className={`btn-primary focus mb-8 w-full justify-center rounded-full border-2 p-2 text-center !drop-shadow-none`}
                                    onClick={() => {
                                      props.setFieldValue('editPlanRadio', 'cancelPlan')
                                      if (typeof window.gtag !== 'undefined') {
                                        window.gtag('event', 'select_cancel_plan', {
                                          page: 'modal_edit_plan',
                                          subscription_id: selectedPlan.subscriptionId
                                        })
                                      }

                                      props.handleSubmit()
                                    }}
                                    type="button"
                                  >
                                    Cancel {selectedPlan?.dogNames}'s plan
                                  </button>
                                </div>
                              </>
                            </Form>
                          )
                        }}
                      </Formik>
                    </div>
                  </div>
                </div>
              )}
              {plans?.length > 1 && selectedPlan === undefined && (
                <>
                  <Dialog.Title as="h3" className="mb-2 text-center text-lg font-medium leading-6">
                    Select for which plan you want to stop deliveries
                  </Dialog.Title>
                  <div className="max-h-ful my-8 flex max-w-full flex-col gap-y-3">
                    {plans?.map((plan: any) => (
                      <div key={plan.subscriptionId} className="flex-shrink-0">
                        <div className="justify-between text-center">
                          <button
                            data-testid="editAddress"
                            className="btn-primary w-3/5 justify-center !border-primary !bg-transparent !px-6 !text-base hover:underline"
                            onClick={() => {
                              if (plan.redZone) {
                                setRedZoneConfirmButton(
                                  <button
                                    type="button"
                                    className="btn-cta inline-flex justify-center px-4 py-2 sm:col-start-1 sm:mt-0"
                                    onClick={() => {
                                      setRedZoneModalShow(false)
                                      setSelectedPlan(plan)
                                      setActiveDeliveryDate(plan.nextDelivery)
                                    }}
                                  >
                                    Edit plan
                                  </button>
                                )
                                setRedZoneModalShow(true)
                              }
                              setSelectedPlan(plan)
                              setActiveDeliveryDate(plan.nextDelivery)
                            }}
                          >
                            {plan.dogNames}
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}

              <div className="mx-auto grid grid-cols-3">
                {plans?.length > 1 && selectedPlan && (
                  <div className="col-span-1 inline-flex ">
                    <FontAwesomeIcon
                      icon={faAngleLeft}
                      fontSize={20}
                      className="cursor-pointer pr-1"
                      onClick={() => {
                        setSelectedPlan(undefined)
                      }}
                    />
                    <button
                      onClick={() => {
                        setSelectedPlan(undefined)
                      }}
                      className="btn-toggle-left mb-1"
                    >
                      Back
                    </button>
                  </div>
                )}
                <div className="col-start-2 inline-flex justify-center">
                  <button
                    onClick={() => {
                      setShow(false)
                      setSelectedPlan(undefined)
                    }}
                    className="btn-secondary"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
        <EditFutureOrderModal
          show={redZoneModalShow}
          setShow={setRedZoneModalShow}
          confirmButton={redZoneConfirmButton}
        />
        <ErrorToast
          showToast={errorToast}
          msg={'Unable to update delivery date'}
          handleSetShowToast={handleSetErrorToast}
          handleOnClick={() => {}}
        />
        <Toast showToast={showToast} msg={toastMsg} handleSetShowToast={handleSetShowToast} />
      </Dialog>
    </Transition.Root>
  )
}
