import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dialog, Transition } from '@headlessui/react'
import { AddressFinder } from '@ideal-postcodes/address-finder'
import ErrorModal from 'components/ui/modal/ErrorModal'
import { Field, Form, Formik } from 'formik'
import React, { Fragment, useRef, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'state'
import { updatePlans } from 'state/actions/account/accountPlanAction'
import { validatePostcode } from 'state/actions/shippingAction'
import Api from 'state/apis/api'
import * as yup from 'yup'

export default function EditAddressModal({ show, setShow, subscriptionId, deliveryAddress }) {
  const cancelButtonRef = useRef(null)
  const dispatch = useDispatch()
  const [_openAddressInputs, setOpenAddressInputs] = useState(false)
  const [showAddressFinder, _setShowAddressFinder] = useState(true)
  const [isShippingAddressSame, setIsShippingAddressSame] = useState(true)
  const { spoofMode, spoofedUserId } = useSelector((state: RootState) => state.ui)
  const [errorMessage, setErrorMessage] = useState('')
  const [showPopup, setShowPopup] = useState(false)

  var formikRef = useRef<any>()

  useEffect(() => {
    if (typeof AddressFinder.setup === 'undefined') {
      setOpenAddressInputs(true)
    }

    AddressFinder.setup({
      inputField: document.getElementById('shippingAddressSearch'),
      apiKey: process.env.GATSBY_IDEAL_POSTCODES,
      injectStyle: true,
      onAddressRetrieved: ({ line_1, line_2, line_3, post_town, county, postcode }) => {
        formikRef.current.setFieldValue([`line1`], line_1)
        formikRef.current.setFieldValue([`line2`], line_2)
        formikRef.current.setFieldValue([`city`], post_town)
        formikRef.current.setFieldValue([`postcode`], postcode)
        formikRef.current.setFieldValue([`county`], county)
        dispatch(validatePostcode(postcode))
      },
      onFailedCheck: () => {
        setOpenAddressInputs(true)
      },
      onSearchError: () => {
        setOpenAddressInputs(true)
      }
    })
  }, [])

  useEffect(() => {
    if (errorMessage) {
      setShowPopup(true)
    }
  }, [errorMessage])

  useEffect(() => {
    if (deliveryAddress.name) {
      setIsShippingAddressSame(false)
    }
  }, [errorMessage])

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={() => setShow(false)}
      >
        <div className="flex min-h-screen items-center justify-center px-4 pt-4 pb-20 text-center sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block w-full max-w-xl transform rounded-3xl bg-white p-4 text-left align-middle shadow-xl transition-all sm:my-8 md:p-8">
              <div>
                <FontAwesomeIcon
                  icon={faClose}
                  fontSize={24}
                  className="absolute top-4 right-4 cursor-pointer md:top-8 md:right-8"
                  onClick={() => setShow(false)}
                />
                <div>
                  <div>
                    <h3 className="mb-8 text-center">Edit your delivery address</h3>
                    <Formik
                      initialValues={{
                        name: deliveryAddress.name,
                        surname: deliveryAddress.surname,
                        line1: deliveryAddress.address1,
                        line2: deliveryAddress.address2,
                        city: deliveryAddress.city,
                        county: deliveryAddress.county,
                        postcode: deliveryAddress.postcode,
                        shippingAddressSearch: ''
                      }}
                      innerRef={formikRef}
                      validationSchema={yup.object({
                        name: yup.string().when([], {
                          is: () => !isShippingAddressSame,
                          then: yup.string().required('Please enter first name'),
                          otherwise: yup.string().notRequired()
                        }),
                        surname: yup.string().when([], {
                          is: () => !isShippingAddressSame,
                          then: yup.string().required('Please enter surname'),
                          otherwise: yup.string().notRequired()
                        }),
                        line1: yup.string().required('Please enter the first line of your address'),
                        line2: yup.string(),
                        city: yup.string().required('Please enter the town or city'),
                        county: yup.string().required('Please enter the county'),
                        postcode: yup.string().required('Please enter the postcode'),
                        shippingAddressSearch: yup.string()
                      })}
                      onSubmit={(formikValues, { setSubmitting }) => {
                        setSubmitting(true)
                        const body = {
                          subscriptionId: subscriptionId,
                          address: {
                            name: formikValues.name,
                            surname: formikValues.surname,
                            address1: formikValues.line1,
                            address2: formikValues.line2,
                            city: formikValues.city,
                            county: formikValues.county,
                            postcode: formikValues.postcode
                          }
                        }

                        const url = spoofMode
                          ? '/v1/private/admin/account/plan/address?userId=' + spoofedUserId
                          : '/v1/private/account/plan/address'

                        Api.put('wwwREST', url, { body })
                          .then(() => {
                            setShow(false)
                            dispatch(updatePlans())
                            setErrorMessage('')
                            setSubmitting(false)
                          })
                          .catch(err => {
                            setShow(true)
                            console.error('There has been an error updating the delivery address')
                            setErrorMessage(err?.response?.data?.popup?.message)
                            setShowPopup(true)
                          })
                      }}
                    >
                      {props => {
                        return (
                          <Form>
                            {showAddressFinder && (
                              <div className="mb-4">
                                <label>
                                  <Field
                                    type="text"
                                    id="shippingAddressSearch"
                                    name="shippingAddressSearch"
                                    placeholder="Search for address"
                                    label="Search for address"
                                    className="block w-full rounded-md border border-gray-300 bg-white py-3 px-4 text-left focus:border-highlight focus:ring-highlight"
                                  />
                                  {props.errors.shippingAddressSearch && props.touched.shippingAddressSearch ? (
                                    <div className="text-xs text-red-500">{props.errors.shippingAddressSearch}</div>
                                  ) : null}
                                </label>
                              </div>
                            )}
                            <div className="mb-4 flex flex-col">
                              <div className="relative flex w-full flex-shrink-0 flex-row items-center">
                                <Field
                                  data-testid="addressLine1"
                                  type="text"
                                  name="line1"
                                  className="peer block w-full rounded-md border border-gray-300 bg-white pt-6 text-left focus:border-[#B0CDD5] focus:ring-0"
                                />
                                <label
                                  className={`absolute transform cursor-text appearance-none duration-300 peer-focus:ml-0 peer-focus:-translate-y-3 peer-focus:scale-75 ${
                                    props.values.line1 ? 'ml-0 -translate-y-3 scale-75' : 'ml-4'
                                  }`}
                                  htmlFor="line1"
                                >
                                  Address line 1
                                </label>
                              </div>
                              {props.errors.line1 && props.touched.line1 ? (
                                <div className="p-2 text-xs text-red-500">{props.errors.line1}</div>
                              ) : null}
                            </div>
                            <div className="mb-4 flex flex-col">
                              <div className="relative flex w-full flex-shrink-0 flex-row items-center">
                                <Field
                                  type="text"
                                  name="line2"
                                  className="peer block w-full rounded-md border border-gray-300 bg-white pt-6 text-left focus:border-[#B0CDD5] focus:ring-0"
                                />
                                <label
                                  className={`absolute transform cursor-text appearance-none duration-300 peer-focus:ml-0 peer-focus:-translate-y-3 peer-focus:scale-75 ${
                                    props.values.line2 ? 'ml-0 -translate-y-3 scale-75' : 'ml-4'
                                  }`}
                                  htmlFor="line2"
                                >
                                  Address line 2
                                </label>
                              </div>
                              {props.errors.line2 && props.touched.line2 ? (
                                <div className="p-2 text-xs text-red-500">{props.errors.line2}</div>
                              ) : null}
                            </div>
                            <div className="mb-4 flex flex-col">
                              <div className="relative flex w-full flex-shrink-0 flex-row items-center">
                                <Field
                                  type="text"
                                  name="city"
                                  className="peer block w-full rounded-md border border-gray-300 bg-white pt-6 text-left focus:border-[#B0CDD5] focus:ring-0"
                                />
                                <label
                                  className={`absolute transform cursor-text appearance-none duration-300 peer-focus:ml-2 peer-focus:-translate-y-3 peer-focus:scale-75 ${
                                    props.values.city ? 'ml-2 -translate-y-3 scale-75' : 'ml-4'
                                  }`}
                                  htmlFor="city"
                                >
                                  City
                                </label>
                              </div>
                              {props.errors.city && props.touched.city ? (
                                <div className="p-2 text-xs text-red-500">{props.errors.city}</div>
                              ) : null}
                            </div>
                            <div className="mb-4 flex flex-col">
                              <div className="relative flex w-full flex-shrink-0 flex-row items-center">
                                <Field
                                  type="text"
                                  name="county"
                                  className="peer block w-full rounded-md border border-gray-300 bg-white pt-6 text-left focus:border-[#B0CDD5] focus:ring-0"
                                />
                                <label
                                  className={`absolute transform cursor-text appearance-none duration-300 peer-focus:ml-1 peer-focus:-translate-y-3 peer-focus:scale-75 ${
                                    props.values.county ? 'ml-1 -translate-y-3 scale-75' : 'ml-4'
                                  }`}
                                  htmlFor="county"
                                >
                                  County
                                </label>
                              </div>
                              {props.errors.county && props.touched.county ? (
                                <div className="p-2 text-xs text-red-500">{props.errors.county}</div>
                              ) : null}
                            </div>
                            <div className="mb-8 flex flex-col">
                              <div className="relative flex w-full flex-shrink-0 flex-row items-center">
                                <Field
                                  type="text"
                                  name="postcode"
                                  className="peer block w-full rounded-md border border-gray-300 bg-white pt-6 text-left focus:border-[#B0CDD5] focus:ring-0"
                                />
                                <label
                                  className={`absolute transform cursor-text appearance-none duration-300 peer-focus:ml-1 peer-focus:-translate-y-3 peer-focus:scale-75 ${
                                    props.values.postcode ? 'ml-1 -translate-y-3 scale-75' : 'ml-4'
                                  }`}
                                  htmlFor="postcode"
                                >
                                  Postcode
                                </label>
                              </div>
                              {props.errors.postcode && props.touched.postcode ? (
                                <div className="p-2 text-xs text-red-500">{props.errors.postcode}</div>
                              ) : null}
                            </div>
                            <div className="relative mb-3 flex w-full flex-shrink-0 flex-row items-center">
                              <Field
                                type="checkbox"
                                name="billingShipping"
                                className="text-fourth-checkbox"
                                checked={isShippingAddressSame}
                                onChange={() => {
                                  setIsShippingAddressSame(prev => !prev)
                                }}
                              />
                              <span className="ml-2">Billing and shipping name are the same</span>
                            </div>
                            {!isShippingAddressSame && (
                              <>
                                <h4 className="text-m md:text-l mt-5 mb-2 bg-white pb-2 text-center">
                                  Please enter the name for this shipping
                                </h4>
                                <div className="mb-4 flex w-full flex-row">
                                  <div className="mx-1 mb-8 flex w-1/2 flex-col">
                                    <div className="relative flex w-full flex-shrink-0 flex-row items-center">
                                      <Field
                                        type="text"
                                        name="name"
                                        className="peer block w-full rounded-md border border-gray-300 bg-white pt-6 text-left focus:border-[#B0CDD5] focus:ring-0"
                                      />
                                      <label
                                        className={`absolute transform cursor-text appearance-none duration-300 peer-focus:ml-1 peer-focus:-translate-y-3 peer-focus:scale-75 ${
                                          props.values.name ? 'ml-1 -translate-y-3 scale-75' : 'ml-4'
                                        }`}
                                        htmlFor="name"
                                      >
                                        First Name<span className="ml-1">*</span>
                                      </label>
                                    </div>
                                    {props.errors.name && props.touched.name ? (
                                      <div className="p-2 text-xs text-red-500">{props.errors.name}</div>
                                    ) : null}
                                  </div>
                                  <div className="mx-1 mb-8 flex w-1/2 flex-col">
                                    <div className="relative flex w-full flex-shrink-0 flex-row items-center">
                                      <Field
                                        type="text"
                                        name="surname"
                                        className="peer block w-full rounded-md border border-gray-300 bg-white pt-6 text-left focus:border-[#B0CDD5] focus:ring-0"
                                      />
                                      <label
                                        className={`absolute transform cursor-text appearance-none duration-300 peer-focus:ml-1 peer-focus:-translate-y-3 peer-focus:scale-75 ${
                                          props.values.surname ? 'ml-1 -translate-y-3 scale-75' : 'ml-4'
                                        }`}
                                        htmlFor="surname"
                                      >
                                        Surname<span className="ml-1">*</span>
                                      </label>
                                    </div>
                                    {props.errors.surname && props.touched.surname ? (
                                      <div className="p-2 text-xs text-red-500">{props.errors.surname}</div>
                                    ) : null}
                                  </div>
                                </div>
                              </>
                            )}
                            <div className="mx-auto grid grid-cols-3">
                              <div className="col-start-2 inline-flex justify-center">
                                <button onClick={() => setShow(false)} type="button" className="btn-secondary">
                                  Cancel
                                </button>
                              </div>
                              <div className="col-start-3 flex justify-end">
                                <button
                                  data-testid="save"
                                  type="submit"
                                  className="btn-cta disabled:opacity-50"
                                  disabled={props.isSubmitting}
                                >
                                  Save
                                </button>
                              </div>
                            </div>
                          </Form>
                        )
                      }}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
          {errorMessage && (
            <ErrorModal
              show={showPopup}
              setShow={setShowPopup}
              errorMessage={errorMessage}
              url={null}
              action={null}
              actionText={null}
            />
          )}
        </div>
      </Dialog>
    </Transition.Root>
  )
}
