import { GrowthBookProvider } from '@growthbook/growthbook-react'
import { Router } from '@reach/router'
import { Amplify, Auth } from 'aws-amplify'
import AccountSummary from 'components/page/account/account-summary'
import AddTreats from 'components/page/account/add-treats'
import DogsPage from 'components/page/account/dogs'
import RecipesPage from 'components/page/account/flow/Recipes'
import ReviewPlan from 'components/page/account/flow/ReviewPlan'
import CheckoutPage from 'components/page/account/flow/ShippingConfirm'
import TreatsPage from 'components/page/account/flow/Treats'
import RecipesPlansPage from 'components/page/account/flow/plans'
import PlanPage from 'components/page/account/plan'
import Rewards from 'components/page/account/rewards'
import SignIn from 'components/page/account/sign-in'
import StatusPage from 'components/page/account/status'
import StatusPageAlternate from 'components/page/account/status-alternate/index'
import DeliveryPage from 'components/page/account/status-alternate/reason/Delivery'
import DietPage from 'components/page/account/status-alternate/reason/Diet'
import EatingPage from 'components/page/account/status-alternate/reason/Eating'
import MovingPage from 'components/page/account/status-alternate/reason/Moving'
import RainbowPageAlternate from 'components/page/account/status-alternate/reason/Rainbow'
import ReferPage from 'components/page/account/status-alternate/reason/Refer'
import ServicePage from 'components/page/account/status-alternate/reason/Service'
import SubscriptionPage from 'components/page/account/status-alternate/reason/Subscription'
import VetPage from 'components/page/account/status-alternate/reason/Vet'
import PauseCancelPlanPage from 'components/page/account/status/reason/PauseCancelPlan'
import RainbowPage from 'components/page/account/status/reason/Rainbow'
import PrivateRoute from 'components/route/PrivateRoute'
import PublicRoute from 'components/route/PublicRoute'
import Layout from 'components/ui/layout/Layout'
import { gb, loadGrowthbook } from 'components/util/Growthbook'
import React, { createContext, useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'

//@ts-ignore
import awsconfig from '../../aws-exports'
Amplify.configure(awsconfig)

export const RewardCtx = createContext<any>({})

const App = () => {
  const [rewardFrame, setRewardFrame] = useState<any>()

  let gbKey = process.env.GATSBY_GROWTHBOOK_KEY

  useEffect(() => {
    loadGrowthbook(gb)
  }, [])

  const layout = (
    <Layout isProtected>
      <Helmet
        bodyAttributes={{
          class: 'account'
        }}
      ></Helmet>
      <RewardCtx.Provider value={{ rewardFrame, setRewardFrame }}>
        <Router basepath="/account">
          <PrivateRoute path="/" component={AccountSummary} />
          <PrivateRoute path="/dogs" component={DogsPage} />
          <PrivateRoute path="/dogs/edit" component={DogsPage} />
          <PrivateRoute path="/plan-recipes" component={RecipesPlansPage} />
          <PrivateRoute path="/recipes" component={RecipesPage} />
          <PrivateRoute path="/treats" component={TreatsPage} />
          <PrivateRoute path="/checkout" component={CheckoutPage} />
          <PrivateRoute path="/review-plan" component={ReviewPlan} />
          <PrivateRoute path="/plan" component={PlanPage} />
          <PrivateRoute path="/plan/edit-delivery" component={PlanPage} />
          <PrivateRoute path="/plan/:subscriptionId/status-1/:actionType/" component={StatusPage} />

          <PrivateRoute path="/plan/:subscriptionId/status/:actionType/" component={StatusPageAlternate} />
          <PrivateRoute path="/plan/:subscriptionId/status/:actionType/vet" component={VetPage} />
          <PrivateRoute path="/plan/:subscriptionId/status/:actionType/diet" component={DietPage} />
          <PrivateRoute path="/plan/:subscriptionId/status/:actionType/rainbow" component={RainbowPageAlternate} />
          <PrivateRoute path="/plan/:subscriptionId/status/:actionType/eating" component={EatingPage} />
          <PrivateRoute path="/plan/:subscriptionId/status/:actionType/subscription" component={SubscriptionPage} />
          <PrivateRoute path="/plan/:subscriptionId/status/:actionType/refer" component={ReferPage} />
          <PrivateRoute path="/plan/:subscriptionId/status/:actionType/delivery" component={DeliveryPage} />
          <PrivateRoute path="/plan/:subscriptionId/status/:actionType/service" component={ServicePage} />
          <PrivateRoute path="/plan/:subscriptionId/status/:actionType/moving" component={MovingPage} />

          <PrivateRoute path="/plan/:subscriptionId/status-1/:actionType/rainbow" component={RainbowPage} />
          <PrivateRoute path="/plan/:subscriptionId/status-1/:actionType/vet" component={PauseCancelPlanPage} />
          <PrivateRoute path="/plan/:subscriptionId/status-1/:actionType/diet" component={PauseCancelPlanPage} />
          <PrivateRoute path="/plan/:subscriptionId/status-1/:actionType/service" component={PauseCancelPlanPage} />
          <PrivateRoute path="/plan/:subscriptionId/status-1/:actionType/delivery" component={PauseCancelPlanPage} />
          <PrivateRoute path="/plan/:subscriptionId/status-1/:actionType/other" component={PauseCancelPlanPage} />
          <PrivateRoute path="/plan/:subscriptionId/status-1/:actionType/moving" component={PauseCancelPlanPage} />
          <PrivateRoute path="/plan/:subscriptionId/status-1/:actionType/eating" component={PauseCancelPlanPage} />
          <PrivateRoute path="/plan/:subscriptionId/status-1/:actionType/refer" component={PauseCancelPlanPage} />
          <PrivateRoute
            path="/plan/:subscriptionId/status-1/:actionType/subscription"
            component={PauseCancelPlanPage}
          />

          <PublicRoute path="/signin" component={SignIn} />
          <PrivateRoute path="/rewards" component={Rewards} />
          <PrivateRoute path="/add-treats" component={AddTreats} />
        </Router>
      </RewardCtx.Provider>
    </Layout>
  )

  return (
    <>
      {gbKey && <GrowthBookProvider growthbook={gb}>{layout}</GrowthBookProvider>}
      {!gbKey && layout}
    </>
  )
}

export default App
