import Image from 'components/ui/image/StaticImage'
import { Field, Form, Formik } from 'formik'
import { navigate } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'state'
import { cancelPauseReason } from 'state/actions/account/accountPlanAction'
import { getPlanCancelReasons, getPlanPauseReasons } from 'state/apis/account/planApi'
import { PlanType } from 'state/types/account/accountPlanType'
import * as yup from 'yup'
import loadingGIF from '../../../../images/loadingspinner.gif'

const Status: React.FC = ({ subscriptionId, actionType }) => {
  const dispatch = useDispatch()
  const [action, setAction] = useState('')
  const { plans } = useSelector((state: RootState) => state.accountPlan)
  const { spoofMode, spoofedUserId } = useSelector((state: RootState) => state.ui)
  const [cancelReasons, setCancelReasons] = useState([])
  const [pauseReasons, setPauseReasons] = useState([])
  const [loading, setLoading] = useState(false)

  const [activePlan, setActivePlan] = useState<PlanType | null>(null)

  const getReasons = async () => {
    if (actionType === 'pause') {
      setLoading(true)
      const pauseReasonsFromApi = await getPlanPauseReasons()
      setLoading(false)
      setPauseReasons(pauseReasonsFromApi)
    } else if (actionType === 'cancel') {
      setLoading(true)
      const cancelReasonsFromApi = await getPlanCancelReasons()
      setLoading(false)
      setCancelReasons(cancelReasonsFromApi)
    }
  }

  useEffect(() => {
    if ((actionType !== 'pause' && actionType !== 'cancel') || !subscriptionId) {
      const url = spoofMode ? '/account/plan/?userId=' + spoofedUserId : '/account/plan/'
      navigate(url)
    }
  }, [])

  useEffect(() => {
    getReasons()

    if (plans) {
      plans.map(plan => {
        if (plan.subscriptionId === subscriptionId) {
          setActivePlan(plan)
        }
      })
    }
  }, [])

  const validationSchema = Object.assign({
    updatePlan: yup.string().required('Please Select Cancellation Reason'),
    other: yup.string().when('updatePlan', {
      is: (other: any) => {
        return JSON.parse(other).id === 12
      },
      then: yup
        .string()
        .required('Please give Specific Reason')
        .test('other validation', 'Please give a specific reason', value => {
          if (value?.match(/^[+a-z0-9\s().,\-_!?"']*$/gi)) {
            return true
          }
          return false
        })
    })
  })

  const cancelOrPause = (formikValues: any, setSubmitting: any) => {
    const codeAndReason = JSON.parse(formikValues.updatePlan)
    delete codeAndReason.input

    dispatch(
      cancelPauseReason({
        subscriptionId,
        activePlan,
        actionType: action,
        text: formikValues.other,
        ...codeAndReason
      })
    )

    const Reason = JSON.parse(formikValues.updatePlan)
    if (Reason?.incentive?.redirect) {
      const url = spoofMode ? Reason?.incentive?.redirect + '/?userId=' + spoofedUserId : Reason?.incentive?.redirect
      navigate(url)
    }
    setSubmitting(false)
  }

  if (
    loading &&
    ((actionType === 'pause' && !pauseReasons?.length) || (actionType === 'cancel' && !cancelReasons?.length))
  ) {
    return (
      <div className="m-auto w-full text-center" style={{ marginTop: '25vh' }}>
        <img className="m-auto" width="200px" src={loadingGIF} alt="Loading, please wait..." />
      </div>
    )
  }

  return (
    <div className="mx-auto mt-20 min-h-screen max-w-7xl">
      <div className="flex flex-1 flex-col">
        <main>
          <div className="rounded-xl bg-backgroundThird p-4 py-10 shadow-xl">
            <h2 data-testid="updatePlanStatus" className="mb-4 text-center text-3xl">
              Update Plan Status
            </h2>
            <p className="py-5 text-center">Please let us know why you are changing your dog's plan</p>
            <Formik
              initialValues={{
                updatePlan: '',
                other: ''
              }}
              validationSchema={yup.object().shape(validationSchema)}
              onSubmit={(formikValues, { setSubmitting }) => {
                cancelOrPause(formikValues, setSubmitting)
              }}
            >
              {props => {
                return (
                  <Form className="p-2">
                    <div className="mx-auto flex max-w-5xl flex-wrap justify-center gap-4">
                      {actionType === 'cancel' &&
                        cancelReasons?.map((res: any) => (
                          <div className="w-full md:w-[calc(50%-2rem)]">
                            <label
                              className={`${
                                props.values['updatePlan'] === JSON.stringify(res)
                                  ? 'btn-primary-active'
                                  : 'btn-primary !bg-transparent'
                              } flex h-full w-full cursor-pointer flex-row items-center`}
                            >
                              <Field type="radio" className="hidden" value={JSON.stringify(res)} name="updatePlan" />
                              <span className="ml-2 text-left">{res?.reason}</span>
                            </label>
                          </div>
                        ))}
                      {actionType === 'pause' &&
                        pauseReasons?.map((res: any) => (
                          <div className="w-full md:w-[calc(50%-2rem)]">
                            <label
                              className={`${
                                props.values['updatePlan'] === JSON.stringify(res)
                                  ? 'btn-primary-active'
                                  : 'btn-primary !bg-transparent'
                              } flex h-full w-full cursor-pointer flex-row items-center`}
                            >
                              <Field type="radio" className="hidden" value={JSON.stringify(res)} name="updatePlan" />
                              <span className="ml-2 text-left">{res?.reason}</span>
                            </label>
                          </div>
                        ))}
                      {props.errors.updatePlan && props.touched.updatePlan ? (
                        <div className="text-xs text-red-500">{props.errors.updatePlan}</div>
                      ) : null}
                    </div>
                    <div
                      className={`mt-5 text-center 
                              ${props.values.updatePlan && JSON.parse(props.values.updatePlan).input ? '' : 'hidden'}`}
                    >
                      <div>
                        <label>
                          <Field component="textarea" name="other" rows={2} />
                        </label>
                      </div>
                      <div>
                        {props.errors.other && props.touched.other ? (
                          <div className="text-xs text-red-500">{props.errors.other}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="my-10 flex flex-col items-center justify-between gap-4">
                      <div>
                        <button
                          data-testid="cancelPlan"
                          type="submit"
                          disabled={!props.isValid || props.isSubmitting || !props.values.updatePlan}
                          className={`btn-cta !px-10 disabled:opacity-50`}
                          onClick={() => {
                            if (typeof window.gtag !== 'undefined') {
                              window.gtag(
                                'event',
                                `account_update_plan_${actionType.charAt(0).toUpperCase() + actionType.slice(1)}_next`,
                                {
                                  page: 'account_plan'
                                }
                              )
                            }
                            if (actionType === 'pause' && activePlan?.status === 'active') {
                              setAction('pause')
                            } else if (
                              actionType === 'cancel' &&
                              (activePlan?.status === 'future' || activePlan?.status === 'active')
                            ) {
                              setAction('cancel')
                            }
                          }}
                        >
                          Submit
                        </button>
                      </div>
                      <button
                        type="button"
                        className="btn-secondary"
                        onClick={() => {
                          if (typeof window.gtag !== 'undefined') {
                            window.gtag(
                              'event',
                              `account_update_plan_${actionType.charAt(0).toUpperCase() + actionType.slice(1)}_back`,
                              {
                                page: 'account_plan'
                              }
                            )
                          }
                          const url = spoofMode ? '/account/plan/?userId=' + spoofedUserId : '/account/plan/'
                          navigate(url)
                        }}
                      >
                        Back
                      </button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </main>
      </div>
    </div>
  )
}

export default Status
