import { faClose, faExclamation } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment } from 'react'
import { createDogsExistingPlan } from 'state/actions/account/flow/dogAction'

interface Iprops {
  show: boolean
  setShow: (arg: boolean) => void
  confirmButton: any
}

const EditFutureOrderModal = ({ show, setShow, confirmButton }: Iprops) => {
  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-50 overflow-y-auto" onClose={setShow}>
        <div className="flex min-h-screen items-center justify-center px-4 pt-4 pb-20 text-center sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block w-full max-w-xl transform rounded-3xl bg-white p-4 text-left align-middle shadow-xl transition-all sm:my-8 sm:p-8">
              <div>
                <FontAwesomeIcon
                  icon={faClose}
                  fontSize={24}
                  className="absolute top-4 right-4 cursor-pointer md:top-8 md:right-8"
                  onClick={() => setShow(false)}
                />
                <div className="absolute -top-6 left-0 right-0 mx-auto flex h-12 w-12 items-center justify-center rounded-full border-4 border-white bg-primary">
                  <FontAwesomeIcon style={{ fontSize: '2.25rem' }} icon={faExclamation} className="text-white" />
                </div>
                <div className="text-center">
                  <Dialog.Title as="h3" className="mb-8 text-lg font-medium leading-6">
                    Your order is currently processing
                  </Dialog.Title>
                  <div className="mx-auto mb-8 w-full">
                    <p className="text-sm">Click the Edit button if you want to make changes on future orders.</p>
                    <p className="text-sm">
                      If you need advice on this order please contact Customer Delight on{' '}
                      <a href="mailto:feedme@differentdog.com" className="font-bold">
                        feedme@differentdog.com
                      </a>{' '}
                      or{' '}
                      <a href="tel:+441743384562" className="font-bold">
                        01743 384 562
                      </a>
                      .
                    </p>
                  </div>
                </div>
              </div>
              <div className="mx-auto grid grid-cols-3">
                <div className="col-start-2 inline-flex justify-center">
                  <button data-testid="back" type="button" className="btn-secondary" onClick={() => setShow(false)}>
                    Close
                  </button>
                </div>
                <div className="col-start-3 flex justify-end">{confirmButton}</div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default EditFutureOrderModal
