import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useFeatureValue } from '@growthbook/growthbook-react'
import { navigate } from '@reach/router'
// import { extractPublicId } from 'cloudinary-build-url'
import RecipeOrTreatModalAlter from 'components/ui/modal/RecipeOrTreatModalAlter'
import StickyFooter from 'components/ui/sticky-footer/StickyFooter'
import CdnImage from 'components/util/CdnImage'
// import CloudinaryImage from 'components/util/CloudinaryImage'
import { Field, Formik } from 'formik'
import { StaticImage } from 'gatsby-plugin-image'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'state'
import {
  addRecipes,
  populateRecipeAndTreatData,
  prefillRecipes,
  updateChangedStatus,
  updatePlanEditedStatus
} from 'state/actions/account/flow/planAction'
import { updateKey } from 'state/actions/keyAction'
import { PlanType, RecipeType } from 'state/types/planType'
import { arrayComparison } from 'utils/array-comparions'
import { RecipesSchema } from 'utils/yup-validation-schema'

const Recipes = () => {
  const { plan, noOfPacks, packSize, recipesSelected, changed, loading, planEdited } = useSelector(
    (state: RootState) => state.accountDogPlanFlow
  )
  const { dogInfo }: any = useSelector((state: RootState) => state.accountDogFlow)
  const { plans } = useSelector((state: RootState) => state.accountPlan)
  const [recipeCount, setRecipeCount] = useState(0)
  const [recipes, setRecipes] = useState<Array<{ id: string; quantity: number }>>([])
  const [modalShow, setModalShow] = useState(false)
  const [modalActiveRecipe, setModalActiveRecipe] = useState({})
  const [recipesForUi, setRecipesForUi] = useState([])
  const [recipesUpdated, setRecipesUpdated] = useState(false)

  const { spoofMode, spoofedUserId } = useSelector((state: RootState) => state.ui)

  const dispatch = useDispatch()

  const recipeFoodImagesShow = useFeatureValue('recipe-food-images-account', 0)

  useEffect(() => {
    // Check if recipes have been changed
    const recipesOnPlan = plans?.filter((p: any) => p.subscriptionId === dogInfo?.subscriptionId) || []
    if (recipesOnPlan[0]?.quantity !== recipeCount) {
      setRecipesUpdated(true)
    } else {
      if (recipesOnPlan[0]?.recipes && recipes) {
        const recipesChanged = arrayComparison(recipesOnPlan[0]?.recipes, recipes)
        setRecipesUpdated(recipesChanged)
      }
    }
  }, [dogInfo, recipes])

  useEffect(() => {
    // Abandon popup handler
    const abandonHandler = (e: any) => {
      e.preventDefault()
      // Chrome requires returnValue to be set
      e.returnValue = ''
    }
    if (recipesUpdated || planEdited) {
      window.addEventListener('beforeunload', abandonHandler)
      dispatch(updatePlanEditedStatus(true))
    }
    return () => {
      window.removeEventListener('beforeunload', abandonHandler)
    }
  }, [recipesUpdated, planEdited])

  useEffect(() => {
    if (dogInfo?.defaultPackSize?.default && plan?.recipes?.length === 0 && plan?.addons?.length === 0) {
      const planType = dogInfo.defaultPackSize.default.toLowerCase().replace('_t', 'T')
      dispatch(
        populateRecipeAndTreatData({
          plan: dogInfo.defaultPackSize[planType],
          noOfPacks: dogInfo.defaultPackSize[planType].numberOfPacks,
          packSize: dogInfo.defaultPackSize[planType].packSize,
          weeks: dogInfo.defaultPackSize[planType].weeks,
          isDefaultPlan: dogInfo.defaultPackSize.default !== 'CUSTOM',
          packType: dogInfo.defaultPackSize.default,
          fixedOrCustomPlan: dogInfo.defaultPackSize.default === 'CUSTOM' ? 'CUSTOM' : 'FIXED'
        })
      )
    } else if (!dogInfo?.dogs?.[0]?.name) {
      // If navigating to treats page directly by the url
      navigate(`/account/plan`)
    }
  }, [dogInfo])

  const backClick = () => {
    if (dogInfo.isInTrial) {
      const url = spoofMode ? '/account/plan/?userId=' + spoofedUserId : '/account/plan/'
      navigate(url)
    } else {
      const url = spoofMode ? '/account/plan-recipes/?userId=' + spoofedUserId : '/account/plan-recipes/'
      navigate(url)
    }
  }

  const disableRecipeAddButton = (recipeId: string, outOfStock: boolean) => {
    const index = recipes.findIndex(recipe => recipe.id === recipeId)
    const previousRecipesIndex = plan.recipes.findIndex((recipe: any) => recipe.id === recipeId)

    if (index !== -1) {
      const recipesArr = [...recipes]

      if (outOfStock) {
        if (recipesArr[index].quantity >= plan.recipes[previousRecipesIndex].quantity) {
          return true
        }
      }
    } else {
      if (outOfStock) {
        return true
      }
    }

    return false
  }

  const handleAddRecipe = (recipeId: string, outOfStock: boolean) => {
    if (recipes) {
      const index = recipes.findIndex(recipe => recipe.id === recipeId)
      const previousRecipesIndex = plan.recipes.findIndex((recipe: any) => recipe.id === recipeId)

      if (index !== -1) {
        const recipesArr = [...recipes]

        if (outOfStock) {
          if (recipesArr[index].quantity < plan.recipes[previousRecipesIndex].quantity) {
            recipesArr[index].quantity = recipesArr[index].quantity + 1
            setRecipes(recipesArr)
            setRecipeCount(curr => curr + 1)
          }
        } else {
          recipesArr[index].quantity = recipesArr[index].quantity + 1
          setRecipes(recipesArr)
          setRecipeCount(curr => curr + 1)
        }
      } else {
        if (!outOfStock) {
          setRecipes([...recipes, { id: recipeId, quantity: 1 }])
          setRecipeCount(curr => curr + 1)
        }
      }
    } else {
      if (!outOfStock) {
        setRecipes([{ id: recipeId, quantity: 1 }])
        setRecipeCount(curr => curr + 1)
      }
    }
  }

  const handleRemoveRecipe = (recipeId: string) => {
    if (recipes) {
      const index = recipes.findIndex(recipe => recipe.id === recipeId)
      if (index !== -1) {
        if (recipes[index].quantity > 1) {
          const recipesArr = [...recipes]
          recipesArr[index].quantity = recipesArr[index].quantity - 1
          setRecipes(recipesArr)
        } else {
          setRecipes(recipes.filter(recipe => recipe.id !== recipeId))
        }
        setRecipeCount(curr => curr - 1)
      }
    }
  }

  const getRecipeQuantity = (recipeId: string) => {
    if (recipes) {
      const index = recipes.findIndex(recipe => recipe.id === recipeId)
      if (index !== -1) {
        return recipes[index].quantity
      }
    }

    return 0
  }

  useEffect(() => {
    if (recipesSelected.length > 0 && recipesSelected[0].quantity > 0) {
      let totalRecipes = 0
      setRecipes(recipesSelected)
      recipesSelected.forEach(recipe => {
        totalRecipes += recipe.quantity
      })
      setRecipeCount(totalRecipes)
    } else {
      const recipesSelectedLocal: any = []
      plan.recipes.map(recipe => {
        if (recipe.quantity > 0) {
          recipesSelectedLocal.push(recipe)
        }
      })
      if (recipesSelectedLocal?.length > 0) {
        setRecipes(recipesSelectedLocal)
        dispatch(prefillRecipes(recipesSelectedLocal))
      }
    }
  }, [recipesSelected, plan.recipes])

  useEffect(() => {
    if (changed && recipesSelected?.length) {
      const url = spoofMode ? '/account/treats/?userId=' + spoofedUserId : '/account/treats/'
      navigate(url)
    }
    return () => {
      dispatch(updateChangedStatus(false))
    }
  }, [changed])

  useEffect(() => {
    const sortedRecipes = plan.recipes.sort((a, b) => {
      return b.quantity - a.quantity
    })

    setRecipesForUi(sortedRecipes)
  }, [plan.recipes])

  return (
    <>
      <div>
        <Formik
          initialValues={{ recipes: [] }}
          validationSchema={RecipesSchema}
          onSubmit={() => {
            dispatch(addRecipes(recipes))
          }}
          validateOnMount
        >
          {props => {
            return (
              <div>
                <h1 data-testid="chooseYourRecipes" className="p-4 text-center text-3xl tracking-tight sm:text-5xl">
                  What's on the menu?
                </h1>
                <p className="mb-4 text-center">
                  All our recipes are nutritionally balanced by our expert canine nutritionist, Bianca.
                </p>
                <div className="mb-4 w-full text-center">
                  <p>
                    You have{' '}
                    <span
                      date-testid="packsSelected"
                      className="mx-2.5 rounded bg-[#F58C82] p-2 text-lg font-bold text-white"
                    >
                      {recipeCount}
                    </span>{' '}
                    out of{' '}
                    <span
                      data-testid="noOfPacks"
                      className="mx-2.5 rounded bg-[#F58C82] p-2 text-lg font-bold text-white"
                    >
                      {noOfPacks}
                    </span>{' '}
                    packs selected
                  </p>
                </div>
                <ul className="mx-auto grid w-full grid-cols-1 gap-y-6 gap-x-4 p-4 pt-0 xs:grid-cols-2 sm:gap-x-8 md:max-w-[90%] md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                  {recipesForUi &&
                    recipesForUi
                      .filter(recipe => recipe.range === 'standard')
                      .map((recipe: { id: string; name: string; imageUrlNormal: string }, _key: number) => {
                        const outOfStock = recipe.status === 'OUTOFSTOCK'
                        const selectedRecipe = recipes?.find(r => r.id === recipe.id)
                        return (
                          <li
                            className={`relative overflow-hidden rounded-3xl bg-[#F8F2EC]
                            ${selectedRecipe && 'shadow-[0_0_0_4px_rgba(0,102,133,1)]'}`}
                            key={recipe.id}
                          >
                            <div className={`flex h-full flex-col justify-between text-center`}>
                              <div className="flex h-full flex-col">
                                <div className="block grid-cols-3 md:grid md:grid-cols-1">
                                  <div className="aspect-h-1 relative mx-auto w-full overflow-hidden md:aspect-w-1 md:mb-2 md:rounded-none">
                                    {recipeFoodImagesShow === 0 && recipe?.images?.noBackground && (
                                      <CdnImage
                                        url={recipe?.images?.noBackground}
                                        alt={recipe.name}
                                        steps={[550]}
                                        className={`max-h-[120px] w-full object-cover xs:min-h-[120px] md:max-h-full`}
                                      />
                                    )}
                                    {recipeFoodImagesShow === 1 && recipe?.images?.primary && (
                                      <CdnImage
                                        url={recipe?.images?.primary}
                                        alt={recipe.name}
                                        steps={[550]}
                                        className={`max-h-[120px] w-full object-cover xs:min-h-[120px] md:max-h-full`}
                                      />
                                    )}
                                    {recipeFoodImagesShow === 2 && recipe?.images?.tertiary && (
                                      <CdnImage
                                        url={recipe?.images?.tertiary}
                                        alt={recipe.name}
                                        steps={[550]}
                                        className={`max-h-[120px] w-full object-cover xs:min-h-[120px] md:max-h-full`}
                                      />
                                    )}
                                    {recipe.bestseller && (
                                      <span
                                        style={{ lineHeight: '1rem', right: '0.5rem' }}
                                        className="absolute top-[15%] inline-flex h-11 w-11 -rotate-12 items-center justify-center rounded-full bg-healthBG text-center text-[0.65rem] text-healthText drop-shadow md:h-20 md:w-20 md:text-[1rem] xl:inline-flex"
                                      >
                                        Popular
                                      </span>
                                    )}
                                  </div>
                                  <div className="col-span-2 flex h-full flex-grow flex-col p-4">
                                    <h4 className="mb-2 text-center md:text-xl">{recipe.name}</h4>
                                    {recipe?.highlights &&
                                      recipe?.highlights?.map((highlight: any, i: number) => {
                                        return (
                                          <span
                                            key={i}
                                            className="mx-auto mt-1 inline-flex justify-center rounded-full bg-[#FCD1CD] py-2 px-2 text-center text-xs xs:w-full md:text-sm"
                                          >
                                            {highlight}
                                          </span>
                                        )
                                      })}
                                    <p className="mt-4 flex h-full flex-grow flex-col text-center text-xs text-black md:text-sm">
                                      {recipe.shortDescription}{' '}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="flex items-center justify-center pt-4">
                                {getRecipeQuantity(recipe.id) > 0 && (
                                  <button
                                    data-testid="btnAddMinus"
                                    type="button"
                                    className={
                                      'btn-cta mr-4 flex h-10 h-[30px] w-10 w-[30px] items-center !px-0 !py-0 !text-4xl disabled:bg-[#D1D5DA]'
                                    }
                                    onClick={() => handleRemoveRecipe(recipe.id)}
                                  >
                                    <span className="mb-[6px]">-</span>
                                  </button>
                                )}
                                {getRecipeQuantity(recipe.id) > 0 && (
                                  <Field
                                    name="recipe"
                                    type="text"
                                    placeholder="0"
                                    className="w-1/3 rounded-3xl border border-gray-300 py-3 px-4 text-center shadow-sm focus:border-primary focus:ring-primary"
                                    value={getRecipeQuantity(recipe.id)}
                                    inputMode="none"
                                    onFocus={(e: any) => e.target.blur()}
                                  />
                                )}
                                {getRecipeQuantity(recipe.id) === 0 && (
                                  <button
                                    data-testid="addBtn"
                                    type="button"
                                    disabled={
                                      disableRecipeAddButton(recipe.id, outOfStock) ||
                                      (noOfPacks && recipeCount >= noOfPacks)
                                    }
                                    className={`${
                                      outOfStock && 'cursor-not-allowed'
                                    } btn-cta !px-8 !text-base !text-white disabled:cursor-not-allowed disabled:bg-[#D1D5DA]`}
                                    onClick={() => handleAddRecipe(recipe.id, outOfStock)}
                                  >
                                    {outOfStock ? 'Out of stock' : 'Add'}
                                  </button>
                                )}
                                {getRecipeQuantity(recipe.id) > 0 && (
                                  <button
                                    data-testid="btnAddPlus"
                                    type="button"
                                    disabled={noOfPacks && recipeCount >= noOfPacks}
                                    className={`btn-cta ml-4 flex h-10 h-[30px] w-10 w-[30px] items-center !px-0 !py-0 !text-4xl disabled:cursor-not-allowed disabled:bg-[#D1D5DA] disabled:grayscale disabled:filter`}
                                    onClick={() => handleAddRecipe(recipe.id, outOfStock)}
                                  >
                                    +
                                  </button>
                                )}
                              </div>
                              <div className="my-2 text-center">
                                <button
                                  className="mb-2.5 text-xs text-black underline sm:text-sm"
                                  onClick={() => {
                                    setModalShow(true)
                                    setModalActiveRecipe(recipe)
                                  }}
                                >
                                  Nutritional Information
                                </button>
                              </div>
                            </div>
                          </li>
                        )
                      })}
                  {/* <li className="hidden h-full flex-col items-center justify-center rounded-3xl bg-[#D7EAD2] p-4 text-center xs:flex">
                    <h3 className="text-2xl">
                      Discover our <span className="text-3xl">Health+</span> range below
                    </h3>
                  </li> */}
                </ul>
                <div className="grid justify-center p-4">
                  <h2 className="p-4 text-center text-3xl tracking-tight">Health+</h2>
                  <p className="mb-4 max-w-[586px] text-center">
                    Our Health+ range is the UK's only freshly cooked dog food range specially designed to support
                    common health conditions.
                  </p>
                </div>
                <ul className="mx-auto grid w-full grid-cols-1 gap-x-4 gap-y-8 p-4 pt-0 xs:grid-cols-2 sm:gap-x-8 md:max-w-[90%] md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                  {recipesForUi &&
                    recipesForUi
                      .filter(recipe => recipe.range === 'health')
                      .map((recipe: { id: string; name: string; imageUrlNormal: string }, _key: number) => {
                        const outOfStock = recipe.status === 'OUTOFSTOCK'
                        const selectedRecipe = recipes?.find(r => r.id === recipe.id)
                        return (
                          <li
                            className={`relative rounded-3xl bg-[#D7EAD2]
                            ${selectedRecipe && 'shadow-[0_0_0_4px_rgba(0,102,133,1)]'}
                           overflow-hidden`}
                            key={recipe.id}
                          >
                            <div className={`flex h-full flex-col justify-between text-center`}>
                              <div className="flex h-full flex-col">
                                <div className="block grid-cols-3 md:grid md:grid-cols-1">
                                  <div className="aspect-h-1 relative mx-auto w-full md:aspect-w-1 md:rounded-none overflow-hidden">
                                    {recipeFoodImagesShow === 0 && recipe?.images?.noBackground && (
                                      <CdnImage
                                        url={recipe?.images?.noBackground}
                                        alt={recipe.name}
                                        steps={[550]}
                                        className="max-h-[120px] w-full object-cover xs:min-h-[120px] md:max-h-full"
                                      />
                                    )}
                                    {recipeFoodImagesShow === 1 && recipe?.images?.primary && (
                                      <CdnImage
                                        url={recipe?.images?.primary}
                                        alt={recipe.name}
                                        steps={[550]}
                                        className="max-h-[120px] w-full object-cover xs:min-h-[120px] md:max-h-full"
                                      />
                                    )}
                                    {recipeFoodImagesShow === 2 && recipe?.images?.tertiary && (
                                      <CdnImage
                                        url={recipe?.images?.tertiary}
                                        alt={recipe.name}
                                        steps={[550]}
                                        className="max-h-[120px] w-full object-cover xs:min-h-[120px] md:max-h-full"
                                      />
                                    )}

                                    {recipe.bestseller && (
                                      <span
                                        style={{ lineHeight: '1rem', right: '0.5rem' }}
                                        className="absolute top-[15%] inline-flex h-11 w-11 -rotate-12 items-center justify-center rounded-full bg-healthBG text-center text-[0.65rem] text-healthText drop-shadow md:h-20 md:w-20 md:text-[1rem] xl:inline-flex"
                                      >
                                        Popular
                                      </span>
                                    )}
                                  </div>
                                  <div className="col-span-2 flex h-full flex-grow flex-col">
                                    {recipe.adultOnly && (
                                      <div className="flex w-full justify-center text-center font-sans- font-medium text-sm">
                                        <span className="bg-[#E79085] py-2 mb-1 md:mb-0 whitespace-normal break-words w-full">
                                          Unsuitable for puppies & pregnant/nursing dogs
                                        </span>
                                      </div>
                                    )}
                                    <div className="col-span-2 flex h-full flex-grow flex-col p-4">
                                      <h4 className="mb-2 text-center md:text-xl">{recipe.name}</h4>
                                      {recipe?.highlights &&
                                        recipe?.highlights?.map((highlight: any, i: number) => {
                                          return (
                                            <span
                                              key={i}
                                              className="mx-auto mt-1 inline-flex justify-center rounded-full bg-white py-2 px-2 text-center text-xs xs:w-full md:text-sm"
                                            >
                                              {highlight}
                                            </span>
                                          )
                                        })}
                                      <p className="mt-4 flex h-full flex-grow flex-col text-center text-xs text-black md:text-sm">
                                        {recipe.shortDescription}{' '}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {recipe.price > 0 && (
                                <div className="mx-auto flex flex-col items-center justify-center text-center">
                                  <p className="text-2xl font-bold text-primary">+£{(recipe.price / 100).toFixed(2)}</p>
                                </div>
                              )}
                              <div className="flex items-center justify-center">
                                {getRecipeQuantity(recipe.id) > 0 && (
                                  <button
                                    data-testid="btnAddMinus"
                                    type="button"
                                    className={
                                      'btn-cta mr-4 flex h-10 h-[30px] w-10 w-[30px] items-center !px-0 !py-0 !text-4xl disabled:bg-[#D1D5DA]'
                                    }
                                    onClick={() => handleRemoveRecipe(recipe.id)}
                                  >
                                    <span className="mb-[6px]">-</span>
                                  </button>
                                )}
                                {getRecipeQuantity(recipe.id) > 0 && (
                                  <Field
                                    name="recipe"
                                    type="text"
                                    placeholder="0"
                                    className="w-1/3 rounded-3xl border border-gray-300 py-3 px-4 text-center shadow-sm focus:border-primary focus:ring-primary"
                                    value={getRecipeQuantity(recipe.id)}
                                    inputMode="none"
                                    onFocus={(e: any) => e.target.blur()}
                                  />
                                )}
                                {getRecipeQuantity(recipe.id) > 0 && (
                                  <button
                                    data-testid="btnAddPlus"
                                    type="button"
                                    disabled={noOfPacks && recipeCount >= noOfPacks}
                                    className={`btn-cta ml-4 flex h-10 h-[30px] w-10 w-[30px] items-center !px-0 !py-0 !text-4xl disabled:cursor-not-allowed disabled:bg-[#D1D5DA] disabled:grayscale disabled:filter`}
                                    onClick={() => handleAddRecipe(recipe.id, outOfStock)}
                                  >
                                    +
                                  </button>
                                )}
                                {getRecipeQuantity(recipe.id) === 0 && (
                                  <button
                                    data-testid="addBtn"
                                    type="button"
                                    disabled={
                                      disableRecipeAddButton(recipe.id, outOfStock) ||
                                      (noOfPacks && recipeCount >= noOfPacks)
                                    }
                                    className={`${
                                      outOfStock && 'cursor-not-allowed'
                                    } btn-cta !px-8 !text-base !text-white disabled:cursor-not-allowed disabled:bg-[#D1D5DA] disabled:grayscale disabled:filter`}
                                    onClick={() => handleAddRecipe(recipe.id, outOfStock)}
                                  >
                                    {outOfStock ? 'Out of stock' : 'Add'}
                                  </button>
                                )}
                              </div>
                              <div className="my-2 text-center">
                                <button
                                  className="mb-2.5 text-xs text-black underline sm:text-sm"
                                  onClick={() => {
                                    setModalShow(true)
                                    setModalActiveRecipe(recipe)
                                  }}
                                >
                                  Nutritional Information
                                </button>
                              </div>
                            </div>
                          </li>
                        )
                      })}
                  <li className="relative flex h-full flex-col items-center justify-start rounded-3xl bg-[#F8F2EC] p-4 text-center">
                    <h3 className="mb-4">Bianca</h3>
                    <p className="mb-4">Our Nutrition guru</p>
                    <p className="text-sm">
                      Expert Canine Nutritionist (PGDip Nutrition & Canine Nutrition, MSFTR, CPN)
                    </p>
                    <p className="pt-5 text-sm">
                      "We formulated the Health+ range for dogs that need a little extra TLC. Diet makes a huge
                      difference to many health conditions and we're proud to be cooking with natures best ingredients
                      and superfoods to support them."
                    </p>
                    <div className="w-full md:relative md:bottom-[-3rem]">
                      <StaticImage src="../../../images/recipe-Bianca.png" alt="Bianca" />
                    </div>
                  </li>
                </ul>
                <StickyFooter className="fixed bottom-0 left-0 right-0 z-[3] custom:max-h-20 bg-[#F8F2EC] w-full custom:w-11/12 mx-auto custom:rounded-t-3xl shadow-[0_-5px_15px_rgba(0,0,0,0.25)]">
                  <div className="w-full custom:w-full flex flex-col custom:flex-row relative items-center justify-between mx-auto pb-2">
                    <button
                      data-testid="recipesBack"
                      onClick={() => {
                        backClick()
                      }}
                      type="button"
                      className="z-2 rounded-full border border-primary bg-white px-12 py-3 hover:underline focus:underline hidden custom:flex justify-center items-center"
                    >
                      <FontAwesomeIcon icon={faChevronLeft} className="h-4 w-4 mr-2" aria-hidden="true" />
                      Back
                    </button>
                    <div className="z-1 mb-2 custom:mb-0 text-center custom:absolute custom:left-1/2 custom:transform custom:-translate-x-1/2 custom:ml-16">
                      <p className="relative top-0 mx-auto custom:right-16 custom:top-0">
                        You have{' '}
                        <span
                          data-testid="packsSelected"
                          className="rounded bg-[#F58C82] px-2 py-1 text-lg font-bold text-white"
                        >
                          {recipeCount}
                        </span>{' '}
                        out of{' '}
                        <span
                          data-testid="packNbr"
                          className="rounded bg-[#F58C82] px-2 py-1 text-lg font-bold text-white"
                        >
                          {noOfPacks}
                        </span>{' '}
                        packs selected
                      </p>
                    </div>
                    <div className="flex flex-row justify-center items-center w-11/12 custom:w-auto custom:justify-center">
                      <a
                        className={`mr-2 flex h-[3.5rem] w-[3.5rem] items-center text-primary custom:hidden`}
                        onClick={backClick}
                      >
                        <StaticImage src="../../../../images/back-arrow-mobile.png" alt="Back" />
                      </a>
                      <button
                        data-testid="next"
                        onClick={() => props.handleSubmit()}
                        disabled={noOfPacks ? recipeCount < noOfPacks || noOfPacks - recipeCount < 0 || loading : true}
                        type="button"
                        className={`${
                          (noOfPacks ? recipeCount < noOfPacks || noOfPacks - recipeCount < 0 || loading : true)
                            ? '!bg-[#80B2C2]'
                            : ''
                        } btn-cta scale-animation !py-3 custom:!py-4 !text-xl !font-bold !flex justify-center items-center w-11/12 custom:w-auto custom:!px-24 z-2`}
                      >
                        {loading ? (
                          <div className="align-center flex justify-center">
                            <div className="border-white-900 mr-2 h-5 w-5 animate-spin rounded-full border-b-2"></div>
                            <div>Saving</div>
                          </div>
                        ) : (
                          'Next'
                        )}
                        {!loading && (
                          <FontAwesomeIcon icon={faChevronRight} className="h-4 w-4 ml-2" aria-hidden="true" />
                        )}
                      </button>
                    </div>
                  </div>
                </StickyFooter>
              </div>
            )
          }}
        </Formik>
      </div>
      <RecipeOrTreatModalAlter show={modalShow} setShow={setModalShow} activeRecipe={modalActiveRecipe} />
    </>
  )
}

export default Recipes
