import ErrorModal from 'components/ui/modal/ErrorModal'
import ZendeskTicketModal from 'components/ui/modal/ZendeskTicketModal'
import { Field, Form, Formik } from 'formik'
import { Link, navigate } from 'gatsby'
import DeliveryDateImg from 'images/cancel-pause/Date.svg'
import ChangePlanImg from 'images/cancel-pause/Plan.svg'
import SpecialistImg from 'images/cancel-pause/Specialist.svg'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'state'
import { updatePlanRadio } from 'state/actions/account/accountDogAction'
import { updateDogs, updateDogsToDelete } from 'state/actions/account/accountDogAction'
import { updateChangedStatus } from 'state/actions/account/flow/dogAction'
import Api from 'state/apis/api'
import loadingGIF from '../../../../../images/loadingspinner.gif'

const Rainbow: React.FC = ({ subscriptionId, actionType }) => {
  const dispatch = useDispatch()
  const planStatus = useSelector((state: RootState) => state.accountPlan.planStatus)
  const { spoofMode, spoofedUserId } = useSelector((state: RootState) => state.ui)
  const { changed } = useSelector((state: RootState) => state.accountDogFlow)
  const { dogsToDelete, dogs } = useSelector((state: RootState) => state.accountDog)
  const [loading, _setLoading] = useState(false)
  const [showPopup, setShowPopup] = useState(false)
  const [errorMessage, setErrorMessage] = useState({})
  const [isSubmitting, setSubmitting] = useState(false)

  useEffect(() => {
    if (changed) {
      const url = spoofMode ? '/account/plan-recipes/?userId=' + spoofedUserId : '/account/plan-recipes/'
      navigate(url)
    }
    return () => {
      dispatch(updateChangedStatus(false))
    }
  }, [changed])

  useEffect(() => {
    const redirectUrl = spoofMode ? `/account/plan/?userId=` + spoofedUserId : `/account/plan/`
    if (Object.keys(planStatus).length === 0) {
      navigate(redirectUrl)
    }
  }, [planStatus])

  useEffect(() => {
    callDogs()
  }, [])

  const callDogs = () => {
    const url = spoofMode ? '/v1/private/admin/account/dogs?userId=' + spoofedUserId : '/v1/private/account/dogs'
    Api.get('wwwREST', url, {})
      .then(({ dogs }) => {
        dispatch(updateDogs(dogs))
      })
      .catch(err => {
        console.log(err)
        if (err.response.status === 503) {
          navigate('/maintenance')
        }
      })
  }

  if (loading) {
    return (
      <div className="m-auto w-full text-center" style={{ marginTop: '25vh' }}>
        <img className="m-auto" width="200px" src={loadingGIF} alt="Loading, please wait..." />
      </div>
    )
  }
  const handleSubmit = async () => {
    setSubmitting(true)
    const redirectUrl = spoofMode ? `/account/plan/?userId=` + spoofedUserId : `/account/plan/`
    const cancelUrl = spoofMode
      ? `/v1/private/admin/account/plan/cancel?userId=` + spoofedUserId
      : `/v1/private/account/plan/cancel`

    if (dogsToDelete?.length === 1) {
      await dispatch(updateDogsToDelete([dogsToDelete[0].id]))
    }

    if (typeof window.gtag !== 'undefined') {
      window.gtag('event', 'cancelled_plan', {
        reason: 'Rainbow',
        subscription_id: subscriptionId
      })
    }

    Api.post('wwwREST', cancelUrl, {
      body: {
        subscriptionId: subscriptionId,
        text: planStatus.other || '',
        reason: planStatus.reason,
        id: planStatus.id,
        deleteDogs: dogsToDelete
      }
    })
      .then(() => {
        navigate(redirectUrl)
        setSubmitting(false)
      })
      .catch((err: any) => {
        if (err.response.data) {
          setErrorMessage(err.response.data)
        }
        setShowPopup(true)
        setSubmitting(false)
      })
  }

  return (
    <div className="mt-8">
      <div className="mb-2">
        <Link to="/account/plan/">&larr; Go back to plan</Link>
      </div>
      <Formik
        initialValues={{
          removeDog: null
        }}
        onSubmit={async () => {
          await handleSubmit()
        }}
      >
        {_props => (
          <Form className="mx-auto w-full text-center md:max-w-[80%]">
            <h1 className="mb-8">We are sorry for your loss.</h1>
            <p className="mb-8">
              We have loved cooking for {dogs?.length === 1 ? dogs[0].name : 'your dog/s'} and are so sorry to hear they
              have passed away.
            </p>
            <p className="mb-8">
              Dogs are such wonderful members of the family and it is always so heart-breaking when the time comes to
              say goodbye.
            </p>

            {dogs?.length > 1 && (
              <div className="mb-8">
                {<h2 className="mb-4">Please let us know which dog/s from your plan passed away</h2>}

                {dogs.map(dog => {
                  if (dog.subscription.id !== subscriptionId) {
                    return null
                  }

                  return (
                    <label
                      key={dog.id}
                      data-testid="cancelReasonConfirmDog"
                      onClick={async (e: any) => {
                        if (e.target.checked) {
                          const dogsToDeleteLocal = dogsToDelete
                          dogsToDeleteLocal.push(e.target.value)

                          await dispatch(updateDogsToDelete(dogsToDeleteLocal))
                        } else {
                          let dogsToDeleteLocal = dogsToDelete
                          dogsToDeleteLocal = dogsToDelete.filter(item => item !== e.target.value)
                          await dispatch(updateDogsToDelete(dogsToDeleteLocal))
                        }
                      }}
                      className="mb-2 block w-full"
                    >
                      <Field
                        className="text-fourth-checkbox mr-2"
                        type="checkbox"
                        name="removeDog"
                        value={dog.id}
                        checked={dogsToDelete[0] === dog.id}
                      />
                      {dog.name}
                    </label>
                  )
                })}
              </div>
            )}

            <div className="mx-auto mb-8 flex w-max flex-col">
              <button type="submit" className="btn-cta mb-4 disabled:opacity-50" disabled={isSubmitting}>
                Cancel plan
              </button>
            </div>
          </Form>
        )}
      </Formik>
      <div className="mx-auto w-full text-center">
        <Link className="btn-secondary !no-underline" to={`/account/plan/${subscriptionId}/status/${actionType}`}>
          Back
        </Link>
      </div>
      {errorMessage && (
        <ErrorModal
          show={showPopup}
          setShow={setShowPopup}
          errorMessage={errorMessage?.popup?.message ?? errorMessage?.message}
          url={errorMessage?.popup?.url ?? errorMessage?.url}
          action={errorMessage?.popup?.action ?? errorMessage?.action}
          actionText={errorMessage?.popup?.actionText ?? errorMessage?.actionText}
        />
      )}
    </div>
  )
}

export default Rainbow
