export const arrayComparison = (arrayOne: any, arrayTwo: any) => {
  // arrays mis match equals true
  let _misMatch = false

  // First check arrayOne and arrayTwo are actually arrays
  if (!Array.isArray(arrayOne) || !Array.isArray(arrayTwo)) return false

  // Check for mismatching length
  if (arrayOne.length !== arrayTwo.length) return false

  // Check quantities match
  const totalQuantity = (array: any) => array.reduce((sum: number, obj: any) => sum + obj.quantity, 0)

  // Calculate total quantities
  const totalQuantityArrayOne = totalQuantity(arrayOne)
  const totalQuantityArrayTwo = totalQuantity(arrayTwo)

  // Compare the total quantities
  _misMatch = totalQuantityArrayOne !== totalQuantityArrayTwo

  const findObject = (array: any, obj: any) => {
    return array.some((item: any) => item.id === obj.id && item.quantity === obj.quantity)
  }

  // Check if every object in arrayOne is found in arrayTwo
  const arrayOneMatches = arrayOne.every((obj: any) => findObject(arrayTwo, obj))

  // Check if every object in arrayTwo is found in arrayOne
  const arrayTwoMatches = arrayTwo.every((obj: any) => findObject(arrayOne, obj))

  // Return true if both checks pass
  return !arrayOneMatches && !arrayTwoMatches
}
