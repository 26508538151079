import { InformationCircleIcon } from '@heroicons/react/outline'
import React from 'react'

const ResetPasswordBanner: React.FC = () => {
  return (
    <div className="p-4">
      <div className="flex flex-col items-center">
        <div className="flex-shrink-0">
          <InformationCircleIcon className="mb-4 h-11 w-11 text-base" aria-hidden="true" />
        </div>
        <div className="ml-3 flex-1 md:flex md:justify-between">
          <p className="text-center text-sm">
            Please click the link in the email we have sent you. Check your junk folder if you can't find it.
          </p>
        </div>
      </div>
    </div>
  )
}
export default ResetPasswordBanner
