import EditAddressModal from 'components/ui/modal/EditAddressModal'
import ErrorModal from 'components/ui/modal/ErrorModal'
import MovingOutsideDeliveryAreaModal from 'components/ui/modal/MovingOutsideDeliveryAreaModal'
import { Form, Formik } from 'formik'
import { Link, navigate } from 'gatsby'
import DeliveryImg from 'images/cancel-pause/Address.svg'
import CDImg from 'images/cancel-pause/CD.svg'
import ChangeDeliveryFrequencyImg from 'images/cancel-pause/Frequency.svg'
import UKImg from 'images/cancel-pause/Outside area.svg'
import WebsiteImg from 'images/cancel-pause/Website issue.svg'
import Img1 from 'images/cancel-pause/svg-image-1.svg'
import Img2 from 'images/cancel-pause/svg-image-2.svg'
import Img3 from 'images/cancel-pause/svg-image-3.svg'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'state'
import { updateDogsToDelete, updatePlanRadio } from 'state/actions/account/accountDogAction'
import { updateChangedStatus } from 'state/actions/account/flow/dogAction'
import Api from 'state/apis/api'
import loadingGIF from '../../../../../images/loadingspinner.gif'

const Moving: React.FC = ({ subscriptionId, actionType }) => {
  const dispatch = useDispatch()
  const { dogsToDelete } = useSelector((state: RootState) => state.accountDog)
  const { plans } = useSelector((state: RootState) => state.accountPlan)
  const { customer } = useSelector((state: RootState) => state.accountSummary)
  const { spoofMode, spoofedUserId } = useSelector((state: RootState) => state.ui)
  const { changed } = useSelector((state: RootState) => state.accountDogFlow)
  const planStatus = useSelector((state: RootState) => state.accountPlan.planStatus)
  const [loading, _setLoading] = useState(false)
  const [showMovingOutsideDeliveryAreaModal, setShowMovingOutsideDeliveryAreaModal] = useState(false)
  const [showEditAddressModal, setShowEditAddressModal] = useState(false)
  const [showPopup, setShowPopup] = useState(false)
  const [errorMessage, setErrorMessage] = useState({})
  const [isSubmitting, setSubmitting] = useState(false)
  let address = plans?.find(o => o.subscriptionId === subscriptionId)

  useEffect(() => {
    if (changed) {
      const url = spoofMode ? '/account/plan-recipes/?userId=' + spoofedUserId : '/account/plan-recipes/'
      navigate(url)
    }
    return () => {
      dispatch(updateChangedStatus(false))
    }
  }, [changed])

  useEffect(() => {
    const redirectUrl = spoofMode ? `/account/plan/?userId=` + spoofedUserId : `/account/plan/`
    if (Object.keys(planStatus).length === 0) {
      navigate(redirectUrl)
    }
  }, [planStatus])

  if (loading) {
    return (
      <div className="m-auto w-full text-center" style={{ marginTop: '25vh' }}>
        <img className="m-auto" width="200px" src={loadingGIF} alt="Loading, please wait..." />
      </div>
    )
  }

  const handleSubmit = async () => {
    setSubmitting(true)
    const redirectUrl = spoofMode ? `/account/plan/?userId=` + spoofedUserId : `/account/plan/`
    const pauseCancelUrl = spoofMode
      ? `/v1/private/admin/account/plan/${actionType}?userId=` + spoofedUserId
      : `/v1/private/account/plan/${actionType}`

    if (dogsToDelete?.length === 1) {
      await dispatch(updateDogsToDelete([dogsToDelete[0].id]))
    }

    if (typeof window.gtag !== 'undefined') {
      window.gtag('event', 'cancelled_plan', {
        reason: 'Moving',
        subscription_id: subscriptionId
      })
    }

    Api.post('wwwREST', pauseCancelUrl, {
      body: {
        subscriptionId: subscriptionId,
        text: planStatus.other || '',
        reason: planStatus.reason,
        id: planStatus.id,
        deleteDogs: dogsToDelete
      }
    })
      .then(() => {
        navigate(redirectUrl)
        setSubmitting(false)
      })
      .catch((err: any) => {
        if (err.response.data) {
          setErrorMessage(err.response.data)
        }
        setShowPopup(true)
        setSubmitting(false)
      })
  }

  return (
    <div className="mt-8">
      <div className="mb-2">
        <Link to="/account/plan/">&larr; Go back to plan</Link>
      </div>
      <Formik
        initialValues={{}}
        onSubmit={async () => {
          await handleSubmit()
        }}
      >
        {_props => (
          <Form className="mx-auto w-full text-center md:max-w-[80%]">
            <h1 className="mb-8">Before you go...</h1>
            <p className="mb-2">We deliver to most parts of the United Kingdom.</p>
            <div className="mb-8">
              Find out{' '}
              <a
                className="underline"
                target={'_blank'}
                href="https://help.differentdog.com/hc/en-us/articles/4410750105746-Where-do-you-deliver-"
                rel="noreferrer"
              >
                here
              </a>{' '}
              which areas we cover.
            </div>
            <h3 className="mb-8">Here are some options for you</h3>

            <div className="mb-8">
              <div className="flex flex-row flex-wrap justify-center">
                <div className="flex flex-col items-center justify-center my-1">
                  <div className="flex flex-col items-center justify-center rounded-3xl hover:bg-[#B0CDD5] focus:bg-[#B0CDD5] border-2 border-[#006685] min-h-[180px] min-w-[280px] mx-1">
                    <button
                      type="button"
                      onClick={() => {
                        setShowEditAddressModal(true)
                      }}
                      className="flex flex-col items-center justify-center p-4"
                    >
                      <img className="mb-2" src={DeliveryImg} alt="Change delivery address" />
                      <p className="max-w-[80%]">Change delivery address</p>
                    </button>
                  </div>
                </div>
                <div className="flex flex-col items-center justify-center my-1">
                  <div className="flex flex-col items-center justify-center rounded-3xl hover:bg-[#B0CDD5] focus:bg-[#B0CDD5] border-2 border-[#006685] min-h-[180px] min-w-[280px] mx-1">
                    <button
                      type="button"
                      onClick={() => {
                        setShowMovingOutsideDeliveryAreaModal(true)
                      }}
                      className="flex flex-col items-center justify-center p-4"
                    >
                      <img className="mb-2" src={UKImg} alt="Moving outside delivery area" />
                      <p className="max-w-[80%]">Moving outside delivery area</p>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="mx-auto mb-8 flex w-max flex-col">
              <button type="submit" className="btn-cta mb-4 disabled:opacity-50" disabled={isSubmitting}>
                Cancel plan
              </button>
              <button
                type="button"
                onClick={() => {
                  navigate(spoofMode ? `/account/plan/?userId=` + spoofedUserId : `/account/plan/`)
                }}
                className="btn-primary"
              >
                I don't want to cancel anymore
              </button>
            </div>
          </Form>
        )}
      </Formik>
      <div className="mx-auto w-full text-center">
        <Link className="btn-secondary !no-underline" to={`/account/plan/${subscriptionId}/status/${actionType}`}>
          Back
        </Link>
      </div>
      {customer && showMovingOutsideDeliveryAreaModal && (
        <MovingOutsideDeliveryAreaModal
          show={showMovingOutsideDeliveryAreaModal}
          setShow={setShowMovingOutsideDeliveryAreaModal}
          customer={customer}
          actionType={actionType}
        />
      )}
      {showEditAddressModal && (
        <EditAddressModal
          subscriptionId={subscriptionId}
          show={showEditAddressModal}
          setShow={setShowEditAddressModal}
          deliveryAddress={address?.address.shipping}
        />
      )}
      {errorMessage && (
        <ErrorModal
          show={showPopup}
          setShow={setShowPopup}
          errorMessage={errorMessage?.popup?.message ?? errorMessage?.message}
          url={errorMessage?.popup?.url ?? errorMessage?.url}
          action={errorMessage?.popup?.action ?? errorMessage?.action}
          actionText={errorMessage?.popup?.actionText ?? errorMessage?.actionText}
        />
      )}
    </div>
  )
}

export default Moving
