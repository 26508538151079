import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dialog, Transition } from '@headlessui/react'
import ErrorModal from 'components/ui/modal/ErrorModal'
import * as dayjs from 'dayjs'
import React, { Fragment, useRef, useState } from 'react'
import Api from 'state/apis/api'
import Download from '../../../images/download.svg'

export default function OrderModal({ show, setShow, orderItem }) {
  const [showPopup, setShowPopup] = useState(false)
  const [localErrorMessage, setErrorMessage] = useState<string | undefined>()
  const cancelButtonRef = useRef(null)

  const downloadInvoice = () => {
    if (orderItem.invoiceId) {
      const url = '/v1/private/account/invoice/download/' + orderItem.invoiceId
      Api.get('wwwREST', url, {})
        .then(res => {
          window.open(res, '_blank')
        })
        .catch(err => {
          setErrorMessage(
            'There has been a problem downloading the invoice, please try again, or contact Customer Delight on 01743 384 562.'
          )
          setShowPopup(true)
          console.error('Unable to download invoice', err)
        })
      return
    }
  }

  return (
    <>
      <Transition.Root show={show} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={() => setShow(false)}
        >
          <div className="flex min-h-screen items-center justify-center px-4 pt-4 pb-20 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative inline-block w-full max-w-xl transform overflow-hidden rounded-3xl bg-white p-4 text-left align-middle text-sm shadow-xl transition-all sm:my-8 sm:text-base md:p-8 lg:max-w-3xl">
                <div
                  className={`absolute top-0 right-0 flex h-12 w-12 cursor-pointer flex-col items-center justify-center p-2 md:top-4 md:right-4`}
                  onClick={() => setShow(false)}
                >
                  <FontAwesomeIcon icon={faClose} fontSize={24} />
                </div>
                <h3 className="mb-8 text-center">
                  Order no: <span className="font-bold">{orderItem.id}</span>
                </h3>
                <div>
                  <div className="flex flex-col">
                    <div className="mb-8 grid grid-cols-1 gap-x-12 gap-y-6 border-b border-[#C4C4C4] pb-8 sm:grid-cols-2 sm:gap-y-12">
                      <div>
                        <span className="font-bold capitalize">Ship Date: </span>
                        <span>{dayjs(orderItem.shipDate).format('DD/MM/YYYY')}</span>
                        <p>(Orders normally arrive 1 day after this)</p>
                      </div>
                      <div className="capitalize">
                        <span className="font-bold">Order status: </span>
                        <span>{orderItem?.status}</span>
                        <div>
                          <span className="font-bold">Payment status: </span>
                          <span>{orderItem.paymentStatus}</span>
                        </div>
                      </div>
                      <div className="">
                        <span className="font-bold capitalize">Delivery Address: </span>
                        <p>
                          {`
                            ${orderItem.address.shipping.address1},
                            ${orderItem.address.shipping.address2 ? orderItem.address.shipping.address2 + ', ' : ''}
                            ${orderItem.address.shipping.city},
                            ${orderItem.address.shipping.county},
                            ${orderItem.address.shipping.postcode}
                          `}
                        </p>
                      </div>
                    </div>
                    <div className="mb-8 grid grid-cols-1 gap-x-12 gap-y-6 border-b border-[#C4C4C4] pb-8 sm:grid-cols-2 sm:gap-y-12">
                      <div>
                        <p className="font-bold">Recipes:</p>
                        <div className="">
                          <div>
                            {orderItem.recipes.map(recipe => {
                              return <p>{recipe.quantity + ' x ' + recipe.description}</p>
                            })}
                          </div>
                        </div>
                      </div>
                      {orderItem.addons?.length > 0 && (
                        <div>
                          <p className="font-bold">Treats and Extras:</p>
                          <div>
                            <div>
                              {orderItem.addons.map(addon => {
                                return <p>{addon.quantity + ' x ' + addon.description}</p>
                              })}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div
                      className={`mb-4 ${
                        orderItem.discount.code || orderItem.discount.amount > 0 ? 'grid' : 'self-center'
                      } grid-cols-1 gap-x-12 gap-y-6 xs:grid-cols-2 sm:gap-y-12`}
                    >
                      {(orderItem.discount.code || orderItem.discount.amount > 0) && (
                        <div>
                          {orderItem.discount.code && (
                            <>
                              <span className="font-bold">Discount Code: </span>
                              <span>{orderItem.discount.code}</span>
                            </>
                          )}
                          {orderItem.discount.amount > 0 && (
                            <p>
                              <span>Discount Amount: </span>
                              <span>£{(orderItem.discount.amount / 100).toFixed(2)}</span>
                            </p>
                          )}
                        </div>
                      )}
                      <div>
                        <span className="font-bold">Total Price: </span> £{(orderItem.total / 100).toFixed(2)}{' '}
                        {orderItem.discount.amount > 0 && '(after discount)'}
                      </div>
                    </div>
                  </div>
                  <p className="text-center">
                    If you have any questions please contact Customer Delight on{' '}
                    <a className="underline" href="mailto:feedme@differentdog.com">
                      feedme@differentdog.com
                    </a>{' '}
                    or{' '}
                    <a className="underline" href="tel:+441743384562">
                      01743 384 562
                    </a>
                    .
                  </p>
                </div>
                <div className="mt-3 text-center sm:mt-6 ">
                  <div className="flex flex-row mx-auto justify-center items-center">
                    <img src={Download} className="mr-2 h-4 w-4" alt="Download" />
                    <button
                      type="button"
                      className="font-sans text-sm md:text-base"
                      onClick={() => {
                        downloadInvoice()
                      }}
                    >
                      Download
                    </button>
                  </div>
                </div>
                <div className="mt-3 text-center sm:mt-6">
                  {/* //sm:grid sm:grid-flow-row-dense sm:grid-cols-1 sm:gap-3 */}
                  <button
                    type="button"
                    className="btn-secondary"
                    onClick={() => {
                      setShow(false)
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      {localErrorMessage && (
        <ErrorModal
          show={showPopup}
          setShow={setShowPopup}
          errorMessage={localErrorMessage}
          url={''}
          action={''}
          actionText={''}
        />
      )}
    </>
  )
}
