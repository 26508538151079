import { faClose, faExclamation } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dialog, Transition } from '@headlessui/react'
import ErrorModal from 'components/ui/modal/ErrorModal'
import { Form, Formik } from 'formik'
import { Link, navigate } from 'gatsby'
import React, { Fragment, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'state'
import { updateDogsToDelete } from 'state/actions/account/accountDogAction'
import Api from 'state/apis/api'
import * as yup from 'yup'

export default function MovingOutsideDeliveryAreaModal({ show, setShow, customer, actionType }) {
  const dispatch = useDispatch()
  const { spoofMode, spoofedUserId } = useSelector((state: RootState) => state.ui)
  const { dogsToDelete } = useSelector((state: RootState) => state.accountDog)
  const [pauseOrCancelPlan, setPauseOrCancelPlan] = useState<null | 'pause' | 'cancel'>(null)
  const planStatus = useSelector((state: RootState) => state.accountPlan.planStatus)
  const [showPopup, setShowPopup] = useState(false)
  const [errorMessage, setErrorMessage] = useState({})
  const [isSubmitting, setSubmitting] = useState(false)

  var formikRef = useRef()

  const handleSubmit = async () => {
    setSubmitting(true)
    const redirectUrl = spoofMode ? `/account/plan/?userId=` + spoofedUserId : `/account/plan/`
    const pauseCancelUrl = spoofMode
      ? `/v1/private/admin/account/plan/${pauseOrCancelPlan}?userId=` + spoofedUserId
      : `/v1/private/account/plan/${pauseOrCancelPlan}`
    const subscriptionObject = {
      subscriptionId: planStatus.subscriptionId,
      text: '',
      reason: planStatus.reason,
      id: planStatus.id
    }
    let event = 'paused_plan'
    if (pauseOrCancelPlan === 'pause') {
      subscriptionObject.text = 'Moving out of delivery area' // We have to hardcode this in the FE as we dont have the right pause reason
      subscriptionObject.reason = 'Other'
      subscriptionObject.id = 12
    }
    if (pauseOrCancelPlan === 'cancel') {
      if (dogsToDelete?.length === 1) {
        await dispatch(updateDogsToDelete([dogsToDelete[0].id]))
      }
      subscriptionObject.deleteDogs = dogsToDelete
      event = 'cancelled_plan'
    }

    if (typeof window.gtag !== 'undefined') {
      window.gtag('event', event, {
        reason: 'MovingOutsideDeliveryArea',
        subscription_id: planStatus.subscriptionId
      })
    }

    Api.post('wwwREST', pauseCancelUrl, {
      body: subscriptionObject
    })
      .then(() => {
        navigate(redirectUrl)
        setSubmitting(false)
      })
      .catch((err: any) => {
        if (err.response.data) {
          setErrorMessage(err.response.data)
        }
        setShowPopup(true)
        setSubmitting(false)
      })
  }

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-50 overflow-y-auto" onClose={setShow}>
        <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block w-full max-w-3xl transform rounded-3xl bg-white p-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:p-8 sm:align-middle">
              <div>
                <FontAwesomeIcon
                  icon={faClose}
                  fontSize={24}
                  className="absolute top-4 right-4 cursor-pointer md:top-8 md:right-8"
                  onClick={() => setShow(false)}
                />
                <div className="mb-16">
                  <Dialog.Title as="h3" className="mb-8 text-center text-lg font-medium leading-6">
                    Moving outside the delivery area?
                  </Dialog.Title>
                  <p className="mb-4 text-center">
                    Unfortunately our courier does not deliver to some parts of the UK.
                  </p>
                  <p className="text-center">
                    If you are planning on returning to our delivery area, we suggest pausing your plan and resuming it
                    when you are back.
                  </p>
                </div>
                <div>
                  <Formik
                    innerRef={formikRef}
                    initialValues={{}}
                    onSubmit={async () => {
                      await handleSubmit()
                    }}
                  >
                    {_props => (
                      <Form className="mb-8">
                        <div className="mx-auto mb-8 flex w-max flex-col">
                          <div className="flex flex-row justify-center gap-x-2">
                            <button
                              className="btn-cta mb-4 disabled:opacity-50"
                              disabled={isSubmitting}
                              onClick={() => {
                                setPauseOrCancelPlan('pause')
                              }}
                            >
                              Pause plan
                            </button>
                            <button
                              className="btn-cta mb-4 disabled:opacity-50"
                              disabled={isSubmitting}
                              onClick={() => {
                                setPauseOrCancelPlan('cancel')
                              }}
                            >
                              Cancel plan
                            </button>
                          </div>
                          <button
                            type="button"
                            onClick={() => {
                              navigate(spoofMode ? `/account/plan/?userId=` + spoofedUserId : `/account/plan/`)
                            }}
                            className="btn-primary"
                          >
                            I don't want to cancel anymore
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
              <div className="mx-auto grid grid-cols-3">
                <div className="col-start-2 inline-flex justify-center">
                  <button onClick={() => setShow(false)} className="btn-secondary">
                    Close
                  </button>
                </div>
              </div>
            </div>
          </Transition.Child>
          {errorMessage && (
            <ErrorModal
              show={showPopup}
              setShow={setShowPopup}
              errorMessage={errorMessage?.popup?.message ?? errorMessage?.message}
              url={errorMessage?.popup?.url ?? errorMessage?.url}
              action={errorMessage?.popup?.action ?? errorMessage?.action}
              actionText={errorMessage?.popup?.actionText ?? errorMessage?.actionText}
            />
          )}
        </div>
      </Dialog>
    </Transition.Root>
  )
}
