import StickyFooter from 'components/ui/sticky-footer/StickyFooter'
import { Field, Form, Formik } from 'formik'
import { navigate } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'state'
import { updateDogs, updateDogsToDelete } from 'state/actions/account/accountDogAction'
import { cancelPlan } from 'state/actions/account/accountPlanAction'
import Api from 'state/apis/api'

const Rainbow = () => {
  const planStatus = useSelector((state: RootState) => state.accountPlan.planStatus)
  const { spoofMode, spoofedUserId } = useSelector((state: RootState) => state.ui)
  const { dogsToDelete, dogs } = useSelector((state: RootState) => state.accountDog)

  const dispatch = useDispatch()

  const callDogs = () => {
    const url = spoofMode ? '/v1/private/admin/account/dogs?userId=' + spoofedUserId : '/v1/private/account/dogs'
    Api.get('wwwREST', url, {})
      .then(({ dogs }) => {
        dispatch(updateDogs(dogs))
      })
      .catch(err => {
        console.log(err)
        if (err.response.status === 503) {
          navigate('/maintenance')
        }
      })
  }

  useEffect(() => {
    callDogs()
  }, [])

  return (
    <div className="h-full w-full">
      <div>
        {/* sm:ml-48  */}
        <div className="relative top-10 p-4 pb-24 sm:pb-28">
          <main className="flex flex-col items-center justify-center gap-4 md:flex-row md:justify-between">
            <div className="w-full text-center md:w-1/2 md:p-8">
              <div className="mx-auto w-fit">
                <h1 className="text-3xl sm:text-5xl">Before</h1>
                <h2 className="mb-4 text-end text-2xl sm:text-3xl">you go....</h2>
              </div>
              <div className="mx-auto mt-9 w-full text-black">
                <p className="mb-4">
                  We have loved cooking for your dog and are so sorry to hear they have passed away.
                </p>
                <p>
                  Dogs are such wonderful members of the family and it is always so heart-breaking when the time comes
                  to say goodbye.
                </p>
                {dogs && (
                  <div className="mt-4 rounded-xl border border-ddCustomise bg-backgroundThird p-4 text-left">
                    <p className="mb-4 text-center">
                      If you would like to remove the dog that passed away from your account please select them below:
                    </p>
                    <Formik
                      initialValues={{}}
                      onSubmit={() => {
                        return
                      }}
                    >
                      <Form>
                        {dogs.map(dog => {
                          if (dog.subscription.id !== planStatus?.subscriptionId) {
                            return null
                          }

                          return (
                            <label
                              key={dog.id}
                              onClick={async (e: any) => {
                                if (e.target.checked) {
                                  const dogsToDeleteLocal = dogsToDelete
                                  dogsToDeleteLocal.push(e.target.value)

                                  await dispatch(updateDogsToDelete(dogsToDeleteLocal))
                                } else {
                                  let dogsToDeleteLocal = dogsToDelete
                                  dogsToDeleteLocal = dogsToDelete.filter(item => item !== e.target.value)
                                  await dispatch(updateDogsToDelete(dogsToDeleteLocal))
                                }
                              }}
                              className="mb-2 block w-full"
                            >
                              <Field
                                className="text-fourth-checkbox mr-2"
                                type="checkbox"
                                name="removeDog"
                                value={dog.id}
                              />
                              {dog.name}
                            </label>
                          )
                        })}
                      </Form>
                    </Formik>
                  </div>
                )}
                <button
                  onClick={async () => {
                    if (planStatus.actionType === 'cancel') {
                      await dispatch(
                        cancelPlan({
                          subscriptionId: planStatus.subscriptionId,
                          text: planStatus.other || '',
                          reason: planStatus.reason,
                          id: planStatus.id,
                          deleteDogs: dogsToDelete
                        })
                      )
                      if (typeof window.gtag !== 'undefined') {
                        window.gtag('event', 'account_cancel_rainbow', {
                          page: 'account_cancel_plan'
                        })
                      }
                      const url = spoofMode ? `/account/plan/?userId=` + spoofedUserId : `/account/plan/`
                      navigate(url)
                    }
                  }}
                  className="mt-9 inline-flex w-full max-w-xs items-center justify-start gap-3 rounded-full border-2 border-primary bg-transparent py-2 px-4 text-lg text-base font-medium drop-shadow-lg hover:bg-base hover:text-white focus:border-white focus:outline-secondary"
                >
                  <StaticImage alt="cancel plan" src="../../../../images/reason/Cancel.svg" />
                  Cancel your plan
                </button>
              </div>
            </div>
            <div className="max-w-sm md:max-w-full">
              <StaticImage src="../../../../../../images/thankyou-dog.png" alt="Cancel Plan" />
            </div>
          </main>
        </div>
      </div>
      {/*  sm:ml-48 */}
      <div className="p-4">
        <StickyFooter className="border-gray fixed bottom-0 left-0 right-0 z-50 ml-auto border-t-2 bg-white sm:w-[calc(100%-12rem)]">
          <div className="w-full text-center">
            <button
              data-testid="back-button"
              onClick={() => {
                if (typeof window.gtag !== 'undefined') {
                  window.gtag(
                    'event',
                    `account_${
                      planStatus.actionType.charAt(0).toUpperCase() + planStatus.actionType.slice(1)
                    }_subscription_back`,
                    {
                      page: 'account_cancel_plan'
                    }
                  )
                }
                const url = spoofMode
                  ? `/account/plan/${planStatus.subscriptionId}/status/cancel/?userId=` + spoofedUserId
                  : `/account/plan/${planStatus.subscriptionId}/status/cancel/`
                navigate(url)
              }}
              className="btn-secondary"
            >
              Back
            </button>
          </div>
        </StickyFooter>
      </div>
    </div>
  )
}

export default Rainbow
