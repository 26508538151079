import { Field } from 'formik'
import { StaticImage } from 'gatsby-plugin-image'
import React, { useState, useEffect, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'state'
import { removeAddons, removeRecipes, updatePlanEditedStatus } from 'state/actions/account/flow/planAction'
import { PlanCtx } from '.'
import CustomPlanAlternate from './CustomPlanAlternate'

interface IDog {
  ageStage: string
  dob: string
  exercise: string
  name: string
  neutered: string
  physique: string
  weight: number
}

interface PlansAccordionProps {
  items: Array<{ title: string; type: string }>
  prevIsDefaultPlan: boolean
  isDefaultPlan: boolean
  handleIsDefaultPlan: (val: any) => void
  handleFixedOrCustomPlan: (val: any) => void
  fixedOrCustomPlan: string
}

const PlansAccordion: React.FC<PlansAccordionProps> = ({
  isDefaultPlan,
  handleFixedOrCustomPlan,
  handleIsDefaultPlan,
  fixedOrCustomPlan,
  openTab,
  setOpenTab,
  setCustomisePackSizeSelection,
  setCustomiseNoOfPacksSelection,
  setCustomiseWeeksSelection,
  customisePackSizeSelection,
  customiseNoOfPacksSelection,
  customiseWeeksSelection
}) => {
  const [sortedDogInfo, setSortedDogInfo] = useState<Array<IDog>>([])

  const planCtx = useContext(PlanCtx)
  const {
    pack,
    packSize,
    noOfPacks,
    weeks,
    packType,
    handlePackType,
    handleSetNoOfPacks,
    handleSetPackSize,
    handleSetWeeks
  } = planCtx
  const { dogInfo, defaultPack, summary } = useSelector((state: RootState) => state.accountDogFlow)
  const [planUpdated, setPlanUpdated] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    // Check if plan type have been updated
    if (packType && defaultPack !== packType) setPlanUpdated(true)
  }, [packType])

  useEffect(() => {
    // Abandon popup handler
    const abandonHandler = (e: any) => {
      e.preventDefault()
      // Chrome requires returnValue to be set
      e.returnValue = ''
    }
    if (planUpdated) {
      window.addEventListener('beforeunload', abandonHandler)
      dispatch(updatePlanEditedStatus(true))
    }
    return () => {
      window.removeEventListener('beforeunload', abandonHandler)
    }
  }, [planUpdated])

  useEffect(() => {
    // We have to do this because the dogs object contains empty values
    const tempSortedDogInfo: Array<IDog> = []

    dogInfo.dogs.map(dog => {
      if (dog.name) {
        tempSortedDogInfo.push(dog)
      }
    })

    setSortedDogInfo(tempSortedDogInfo)
  }, [])

  useEffect(() => {
    if (packType) {
      setOpenTab(packType)
    }
  }, [packType])

  const getDogNames = () => {
    return sortedDogInfo.map((dog, i) => {
      if (i === sortedDogInfo.length - 1 && sortedDogInfo.length > 1) {
        return ' and ' + dog.name
      }

      if (i > 0) {
        return ', ' + dog.name
      }

      return dog.name
    })
  }

  const getTotalGrams = () => {
    let totalGrams = 0

    summary.map(dogInfo => {
      if (dogInfo.gramsPerDay) {
        totalGrams += dogInfo.gramsPerDay
      }
    })

    return totalGrams
  }

  return (
    <div className="w-full">
      <div className="mb-4 mt-8 text-center text-base">
        <p>Select one of our recommended plans below</p>
      </div>
      <div
        className={`flex w-full flex-row items-stretch ${
          pack?.full.numberOfPacks && pack?.partial.numberOfPacks ? `md:max-w-[700px]` : `md:max-w-[350px]`
        } m-auto gap-x-4`}
      >
        {pack?.full.numberOfPacks && (
          <div
            className={`mb-4 flex min-h-full w-full flex-grow flex-col items-center justify-center rounded-3xl p-4 drop-shadow-lg ${
              packType === 'FULL' && fixedOrCustomPlan === 'FIXED'
                ? 'border-4 border-primary bg-ddBlue'
                : 'border-4 border-transparent bg-ddBlue'
            }`}
          >
            <h3 className="mb-4 text-center text-lg sm:text-3xl">Full Plan</h3>
            <StaticImage className="mb-4 w-3/6 p-4" alt="Full Diet" src="../../../../../images/full-diet-bowl.png" />
            <p data-testid="feedsAll" className="text-center">
              Feeds all of {getDogNames()}'s needs
            </p>
            <div className="mt-4 flex w-full grow flex-row flex-wrap items-end justify-center text-center md:justify-between md:text-left">
              <p className="mb-4 flex w-full grow flex-col md:mb-0 md:w-1/2">
                <span>
                  <span className="font-bold">{getTotalGrams()}g</span> a day
                </span>
                <span>
                  <span className="font-bold">{pack?.full.numberOfPacks} packs</span> x{' '}
                  <span className="font-bold">{pack?.full.packSize}g</span>
                </span>
                <span>
                  Every <span className="font-bold">{pack?.full.weeks} weeks</span>
                </span>
              </p>
              <button
                data-testid="selected"
                className={`${
                  packType === 'FULL' && fixedOrCustomPlan === 'FIXED'
                    ? 'btn-primary-active hover:border-secondary hover:bg-secondary focus:border-secondary focus:bg-secondary'
                    : 'btn-primary'
                }`}
                onClick={async () => {
                  await dispatch(removeRecipes())
                  await dispatch(removeAddons())
                  handlePackType('FULL')
                  handleFixedOrCustomPlan('FIXED')
                  handleIsDefaultPlan(true)
                  handleSetWeeks(pack?.full.weeks)
                  handleSetNoOfPacks(pack?.full.numberOfPacks)
                  handleSetPackSize(pack?.full.packSize)
                }}
              >
                <Field type="radio" name="packType" value="FULL" className="hidden" />
                {packType === 'FULL' && fixedOrCustomPlan === 'FIXED' ? 'Selected' : 'Select'}
              </button>
            </div>
          </div>
        )}
        {pack?.partial.numberOfPacks && (
          <div
            className={`mb-4 flex min-h-full w-full flex-grow flex-col items-center justify-center rounded-3xl bg-white p-4 drop-shadow-lg ${
              packType === 'PARTIAL' && fixedOrCustomPlan === 'FIXED'
                ? 'border-4 border-primary bg-ddPinkLightest'
                : 'border-4 border-transparent bg-ddPinkLightest'
            }`}
          >
            <h3 className="mb-4 text-center text-xl sm:text-3xl">Partial Plan</h3>
            <StaticImage
              className="mb-4 w-3/6 p-4"
              alt="Partial Diet"
              src="../../../../../images/partial-diet-bowl.png"
            />
            <p className="text-center">Mix with current food</p>
            <div className="mt-4 flex w-full grow flex-row flex-wrap items-end justify-center text-center md:justify-between md:text-left">
              <p className="mb-4 flex w-full grow flex-col md:mb-0 md:w-1/2">
                <span>
                  <span className="font-bold">{pack?.partial.gramsPerDay}g</span> a day
                </span>
                <span>
                  <span className="font-bold">{pack?.partial.numberOfPacks} packs</span> x{' '}
                  <span className="font-bold">{pack?.partial.packSize}g</span>
                </span>
                <span>
                  Every <span className="font-bold">{pack?.partial.weeks} weeks</span>
                </span>
              </p>
              <button
                data-testid="selectPartialDiet"
                className={`${
                  packType === 'PARTIAL' && fixedOrCustomPlan === 'FIXED'
                    ? 'btn-primary-active hover:border-secondary hover:bg-secondary focus:border-secondary focus:bg-secondary'
                    : 'btn-primary'
                }`}
                onClick={async () => {
                  await dispatch(removeRecipes())
                  await dispatch(removeAddons())
                  handlePackType('PARTIAL')
                  handleFixedOrCustomPlan('FIXED')
                  handleIsDefaultPlan(true)
                  handleSetWeeks(pack?.partial.weeks)
                  handleSetNoOfPacks(pack?.partial.numberOfPacks)
                  handleSetPackSize(pack?.partial.packSize)
                }}
              >
                <Field type="radio" name="packType" value="PARTIAL" className="hidden" />
                {packType === 'PARTIAL' && fixedOrCustomPlan === 'FIXED' ? 'Selected' : 'Select'}
              </button>
            </div>
          </div>
        )}
      </div>
      <h3 className="mb-4 mt-8 text-center text-lg sm:text-3xl">Prefer to customise?</h3>
      <p className="mb-4 text-center">Choose your pack size, quantity and frequency</p>
      <button
        data-testid="createCustomPlan"
        className={`mx-auto flex flex-col justify-center rounded-full border-2 border-base p-2 text-center hover:underline focus:underline ${
          openTab === 'CUSTOM' ? 'mb-4 hidden underline' : ''
        }`}
        onClick={() => {
          setOpenTab('CUSTOM')
        }}
      >
        Create custom plan
      </button>

      <div className={`${openTab === 'CUSTOM' ? 'block' : 'hidden'}`}>
        <CustomPlanAlternate
          openTab={openTab}
          setCustomisePackSizeSelection={setCustomisePackSizeSelection}
          setCustomiseNoOfPacksSelection={setCustomiseNoOfPacksSelection}
          setCustomiseWeeksSelection={setCustomiseWeeksSelection}
          customisePackSizeSelection={customisePackSizeSelection}
          customiseNoOfPacksSelection={customiseNoOfPacksSelection}
          customiseWeeksSelection={customiseWeeksSelection}
        />
      </div>
    </div>
  )
}

export default PlansAccordion
