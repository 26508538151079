import ErrorModal from 'components/ui/modal/ErrorModal'
import ZendeskTicketModal from 'components/ui/modal/ZendeskTicketModal'
import { Field, Form, Formik } from 'formik'
import { Link, navigate } from 'gatsby'
import DeliveryDateImg from 'images/cancel-pause/Date.svg'
import ChangePlanImg from 'images/cancel-pause/Plan.svg'
import SpecialistImg from 'images/cancel-pause/Specialist.svg'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'state'
import { updateDogsToDelete, updatePlanRadio } from 'state/actions/account/accountDogAction'
import { createDogsExistingPlan, updateChangedStatus } from 'state/actions/account/flow/dogAction'
import Api from 'state/apis/api'
import * as yup from 'yup'
import loadingGIF from '../../../../../images/loadingspinner.gif'

const Vet: React.FC = ({ subscriptionId, actionType }) => {
  const dispatch = useDispatch()
  const { dogsToDelete } = useSelector((state: RootState) => state.accountDog)
  const { customer } = useSelector((state: RootState) => state.accountSummary)
  const { spoofMode, spoofedUserId } = useSelector((state: RootState) => state.ui)
  const { changed } = useSelector((state: RootState) => state.accountDogFlow)
  const planStatus = useSelector((state: RootState) => state.accountPlan.planStatus)
  const [loading, _setLoading] = useState(false)
  const [showZendeskTicketModal, setShowZendeskTicketModal] = useState(false)
  const [showPopup, setShowPopup] = useState(false)
  const [errorMessage, setErrorMessage] = useState({})
  const [isSubmitting, setSubmitting] = useState(false)

  useEffect(() => {
    if (changed) {
      const url = spoofMode ? '/account/plan-recipes/?userId=' + spoofedUserId : '/account/plan-recipes/'
      navigate(url)
    }
    return () => {
      dispatch(updateChangedStatus(false))
    }
  }, [changed])

  useEffect(() => {
    const redirectUrl = spoofMode ? `/account/plan/?userId=` + spoofedUserId : `/account/plan/`
    if (Object.keys(planStatus).length === 0) {
      navigate(redirectUrl)
    }
  }, [planStatus])

  if (loading) {
    return (
      <div className="m-auto w-full text-center" style={{ marginTop: '25vh' }}>
        <img className="m-auto" width="200px" src={loadingGIF} alt="Loading, please wait..." />
      </div>
    )
  }

  const handleSubmit = async (values: any) => {
    setSubmitting(true)
    const redirectUrl = spoofMode ? `/account/plan/?userId=` + spoofedUserId : `/account/plan/`
    const pauseCancelUrl = spoofMode
      ? `/v1/private/admin/account/plan/${actionType}?userId=` + spoofedUserId
      : `/v1/private/account/plan/${actionType}`
    const subscriptionObject = {
      subscriptionId: subscriptionId,
      text: values.message || '',
      reason: planStatus.reason,
      id: planStatus.id
    }
    let event = 'paused_plan'
    if (actionType === 'cancel') {
      if (dogsToDelete?.length === 1) {
        await dispatch(updateDogsToDelete([dogsToDelete[0].id]))
      }
      subscriptionObject.deleteDogs = dogsToDelete
      event = 'cancelled_plan'
    }

    if (typeof window.gtag !== 'undefined') {
      window.gtag('event', event, {
        reason: 'Vet',
        subscription_id: subscriptionId
      })
    }

    Api.post('wwwREST', pauseCancelUrl, {
      body: subscriptionObject
    })
      .then(() => {
        navigate(redirectUrl)
        setSubmitting(false)
      })
      .catch((err: any) => {
        if (err.response.data) {
          setErrorMessage(err.response.data)
        }
        setShowPopup(true)
        setSubmitting(false)
      })
  }
  return (
    <div className="mt-8">
      <div className="mb-2">
        <Link to="/account/plan/">&larr; Go back to plan</Link>
      </div>
      <Formik
        initialValues={{}}
        onSubmit={async (values: any) => {
          await handleSubmit(values)
        }}
        validationSchema={yup.object({
          message: yup.string().test('other validation', 'Plain text only', value => {
            if (!value || value.match(/^[+a-z0-9\s().,\-_!?"']*$/gi)) {
              return true
            }
            return false
          })
        })}
      >
        {props => (
          <Form className="mx-auto w-full text-center md:max-w-[80%]">
            <h1 className="mb-8">Before you go...</h1>

            {actionType === 'cancel' && (
              <div>
                <p className="mb-8">
                  Did you know we offer a free service to speak with our superstar vet, Alison who can help with any
                  health concerns you have about your dog?
                </p>
              </div>
            )}

            {actionType === 'pause' && planStatus?.id === 6 && (
              <div>
                <p className="mb-8">
                  We know subscriptions can feel scary but ours is completely flexible to suit yours and your dog's
                  needs.
                </p>
              </div>
            )}

            <h3 className="mb-8">Here are some options for you</h3>

            <div className="mb-8">
              <div className="flex flex-row flex-wrap justify-center">
                <div className="flex flex-col items-center justify-center my-1">
                  <div className="flex flex-col items-center justify-center rounded-3xl hover:bg-[#B0CDD5] focus:bg-[#B0CDD5] border-2 border-[#006685] min-h-[180px] min-w-[290px] mx-1">
                    <button
                      type="button"
                      onClick={() => {
                        setShowZendeskTicketModal(true)
                      }}
                      className="flex flex-col items-center justify-center p-4"
                    >
                      <img className="mb-2" src={SpecialistImg} alt="Speak with one of our specialists" />
                      <p className="max-w-[80%]">Speak with one of our specialists</p>
                    </button>
                  </div>
                </div>
                <div className="flex flex-col items-center justify-center my-1">
                  <div className="flex flex-col items-center justify-center rounded-3xl hover:bg-[#B0CDD5] focus:bg-[#B0CDD5] border-2 border-[#006685] min-h-[180px] min-w-[290px] mx-1">
                    <button
                      type="button"
                      onClick={() => {
                        const url = spoofMode
                          ? '/account/plan/edit-delivery/?userId=' + spoofedUserId + '&subscriptionId=' + subscriptionId
                          : '/account/plan/edit-delivery/?subscriptionId=' + subscriptionId
                        navigate(url)
                      }}
                      className="flex flex-col items-center justify-center p-4"
                    >
                      <img className="mb-2" src={DeliveryDateImg} alt="Change delivery date" />
                      <p className="max-w-[80%]">Change delivery date</p>
                    </button>
                  </div>
                </div>
                <div className="flex flex-col items-center justify-center my-1">
                  <div className="flex flex-col items-center justify-center rounded-3xl hover:bg-[#B0CDD5] focus:bg-[#B0CDD5] border-2 border-[#006685] min-h-[180px] min-w-[290px] mx-1">
                    <button
                      type="button"
                      onClick={() => {
                        if (subscriptionId) {
                          dispatch(updatePlanRadio(subscriptionId))
                          dispatch(
                            createDogsExistingPlan({
                              subscriptionId: subscriptionId,
                              dogs: []
                            })
                          )
                        }
                      }}
                      className="flex flex-col items-center justify-center p-4"
                    >
                      <img className="mb-2" src={ChangePlanImg} alt="Change plan" />
                      <p className="max-w-[80%]">Change plan</p>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-1">
              <h3 className="mb-8 text-center text-sm font-medium leading-6">
                If you have any additional information about your decision you can share it below.
              </h3>
            </div>
            <div className="mb-8 w-full mx-auto">
              <Field
                className="w-full rounded-xl focus:border-[#B0CDD5] focus:outline-none focus:ring-[#B0CDD5]"
                name="message"
                as="textarea"
                placeholder="Insert text here (optional)"
                rows={3}
              />
              {props.errors.message && props.touched.message ? (
                <p className="text-xs text-red-500">{props.errors.message}</p>
              ) : (
                ''
              )}
            </div>
            <div className="mx-auto mb-8 flex w-max flex-col">
              <button type="submit" className="btn-cta mb-4 disabled:opacity-50" disabled={isSubmitting}>
                {actionType === 'cancel' ? 'Cancel' : 'Pause'} plan
              </button>
              <button
                type="button"
                onClick={() => {
                  navigate(spoofMode ? `/account/plan/?userId=` + spoofedUserId : `/account/plan/`)
                }}
                className="btn-primary"
              >
                I don't want to {actionType === 'cancel' ? 'cancel' : 'pause'} anymore
              </button>
            </div>
          </Form>
        )}
      </Formik>
      <div className="mx-auto w-full text-center">
        <Link className="btn-secondary !no-underline" to={`/account/plan/${subscriptionId}/status/${actionType}`}>
          Back
        </Link>
      </div>
      {customer && showZendeskTicketModal && (
        <ZendeskTicketModal
          show={showZendeskTicketModal}
          setShow={setShowZendeskTicketModal}
          customer={customer}
          actionType={actionType}
        />
      )}
      {errorMessage && (
        <ErrorModal
          show={showPopup}
          setShow={setShowPopup}
          errorMessage={errorMessage?.popup?.message ?? errorMessage?.message}
          url={errorMessage?.popup?.url ?? errorMessage?.url}
          action={errorMessage?.popup?.action ?? errorMessage?.action}
          actionText={errorMessage?.popup?.actionText ?? errorMessage?.actionText}
        />
      )}
    </div>
  )
}

export default Vet
