import { Cache } from 'aws-amplify'
import deliveryNotificationIcon from 'images/delivery-notification.svg'
import React, { useState, useEffect, useRef } from 'react'

interface AccordionProps {
  nextPlan: any
}

const OrderSummeryAccordion: React.FC<AccordionProps> = ({ nextPlan }) => {
  const [collapseOpen, setCollapseOpen] = useState(false)
  const [rotate, setRotate] = useState(false)
  const [collapseStyle, setCollapseStyle] = useState<undefined | number>(undefined)
  const [animation, setAnimation] = useState(false)
  const collapseRef = useRef<HTMLDivElement>(null)
  const deliveryNotification: boolean = Cache.getItem('deliveryNotification')

  useEffect(() => {
    setCollapseOpen(deliveryNotification ?? false)
    setRotate(deliveryNotification ?? false)
  }, [])

  const openAnimation = () => {
    if (!deliveryNotification && collapseStyle === undefined) {
      setCollapseStyle(0)
    }
    setCollapseOpen(true)
    Cache.setItem('deliveryNotification', true)

    setTimeout(function () {
      setCollapseStyle(collapseRef.current?.scrollHeight)
    }, 0)
    setTimeout(function () {
      setAnimation(false)
    }, 100)
  }

  const closeAnimation = () => {
    let timeOutTime = 0
    if (deliveryNotification && collapseStyle === undefined) {
      setCollapseStyle(collapseRef.current?.scrollHeight)
      timeOutTime = 0
    }
    setTimeout(function () {
      setCollapseStyle(0)
    }, timeOutTime)
    setTimeout(function () {
      setAnimation(false)
      setCollapseOpen(false)
      Cache.setItem('deliveryNotification', false)
    }, 100)
  }

  const startAnimation = (e: any) => {
    e.preventDefault()
    if (!animation) {
      setAnimation(true)
      setRotate(!rotate)
      if (collapseOpen) {
        closeAnimation()
      } else {
        openAnimation()
      }
    }
  }

  useEffect(() => {
    if (!rotate && !deliveryNotification) {
      setCollapseStyle(collapseRef.current?.scrollHeight)
      setRotate(false)
      setTimeout(function () {
        setCollapseStyle(0)
      }, 0)
      setTimeout(function () {
        setAnimation(false)
        setCollapseOpen(false)
        Cache.setItem('deliveryNotification', false)
      }, 100)
    }
  }, [rotate, deliveryNotification])
  return (
    <div className={`relative mx-auto mt-16 mb-10 w-full rounded-3xl bg-[#D7EAD2] md:mt-8 lg:w-1/2`}>
      <div
        className={`relative grid w-full grid-flow-col grid-cols-[auto_1fr] gap-x-8 overflow-visible p-4 px-8 md:flex-row ${
          !collapseOpen && 'items-center'
        }`}
      >
        <div>
          <div className="order-1 mx-auto flex">
            <div className="flex flex-col items-center justify-center">
              <img src={deliveryNotificationIcon} alt="upcoming delivery" />
            </div>
          </div>
        </div>
        <div className={`flex-grow text-sm`}>
          <div>
            <span className="h3 mr-5 text-base font-medium md:text-2xl">It's on its way!</span>
            <p className="text-xs md:text-sm">
              Upcoming delivery date: <span className="w-full font-bold">{nextPlan?.nextDelivery}</span>
            </p>
          </div>
          <div
            ref={collapseRef}
            className={`transition-all duration-200 ease-in-out ${collapseOpen ? 'block' : 'hidden'} mb-9`}
            style={{
              height: collapseStyle
            }}
          >
            <div className="mt-[20px] grid grid-cols-1 gap-x-12 gap-y-9 pb-6 text-xs md:grid-cols-2 md:text-sm">
              {nextPlan.items?.length > 0 && (
                <div>
                  <p className="font-bold">{`Recipes (${nextPlan?.quantityPack})`}:</p>
                  <div className="">
                    <div>
                      {nextPlan.items.map(recipe => {
                        return <p>{recipe}</p>
                      })}
                    </div>
                  </div>
                </div>
              )}
              {nextPlan.addons?.length > 0 && (
                <div>
                  <p className="font-bold">Treats & extras:</p>
                  <div>
                    <div>
                      {nextPlan.addons.map(addon => {
                        return <p>{addon}</p>
                      })}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="pb-6 text-xs md:text-sm">
              <span className="font-bold capitalize">Address: </span>
              <p>
                {`
                   ${nextPlan.address.shipping.address1},
                   ${nextPlan.address.shipping.address2 ? nextPlan.address.shipping.address2 + ', ' : ''}
                   ${nextPlan.address.shipping.city},
                   ${nextPlan.address.shipping.county},
                   ${nextPlan.address.shipping.postcode}
                `}
              </p>
            </div>
            <p className="w-full text-xs italic">
              We are preparing this order so delivery cannot be changed. Please contact Customer Delight with any issue.
              If you wish to change your ongoing plan, then you can do so below.
            </p>
          </div>
          <div className="flex items-center justify-center">
            <button
              onClick={startAnimation}
              className={`z-10 flex cursor-pointer items-center justify-center gap-4 pr-4 text-xs font-bold underline md:text-sm ${
                !collapseOpen ? 'hidden' : 'block'
              }`}
            >
              Close
            </button>
          </div>
        </div>
        <button
          onClick={startAnimation}
          className={`z-10 cursor-pointer text-xs font-bold underline md:text-sm ${collapseOpen ? 'hidden' : 'block'}`}
        >
          View more
        </button>
      </div>
    </div>
  )
}

export default OrderSummeryAccordion
