import { Storage } from 'aws-amplify'
import { StaticImage } from 'gatsby-plugin-image'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import DDProfile from '../../../../images/DDProfilePicPlaceholder.png'
import CropperModal from './CropperModal'

export default function ProfilePicture({ dogId, dogView, setProfilePicUploaded }) {
  const { spoofMode } = useSelector((state: RootState) => state.ui)
  const [src, setSrc] = useState(null)
  const [preview, setPreview] = useState(DDProfile)
  const [modalOpen, setModalOpen] = useState(false)
  const [isUploadedImage, setIsUploadedImage] = useState(false)

  const inputRef = useRef(null)

  useEffect(() => {
    const getProfilePic = async () => {
      try {
        const imageUrl = await Storage.get(`${dogId}.jpg`, {
          level: 'private',
          download: false,
          validateObjectExistence: true
        })
        setPreview(imageUrl)
        setIsUploadedImage(true)
        setProfilePicUploaded(true)
      } catch (err) {
        console.warn('Error fetching profile picture', err)
      }
    }

    if (src === null) {
      getProfilePic()
    }
  }, [])

  const handleInputClick = (e: any) => {
    e.preventDefault()
    inputRef.current.click()
  }

  const handleImgChange = (e: any) => {
    if (e.target.files.length > 0) {
      setSrc(URL.createObjectURL(e.target.files[0]))
      setModalOpen(true)
    }
  }

  return (
    <main className="relative">
      <CropperModal modalOpen={modalOpen} src={src} setPreview={setPreview} setModalOpen={setModalOpen} dogId={dogId} />
      <img
        data-testid="dogProfilePicture"
        className={`-ml-2 rounded-full ${!isUploadedImage ? 'border-2 border-[#006685]' : ''} transform scale-90`}
        width={150}
        height={150}
        src={preview}
        alt={'Dog profile picture'}
      />
      {!isUploadedImage && !spoofMode && (
        <a
          data-testid="addDogImg"
          className="rounded-full absolute bottom-3 left-24"
          href="/"
          onClick={handleInputClick}
        >
          <StaticImage className="" width={35} src="../../../../images/plusIcon.svg" alt="Plus" />
        </a>
      )}
      {!dogView && isUploadedImage && !spoofMode && (
        <a className="rounded-full absolute bottom-3 left-24" href="/" onClick={handleInputClick}>
          <StaticImage className="" width={35} src="../../../../images/Camera.svg" alt="Camera" />
        </a>
      )}
      <input className="hidden" type="file" accept="image/*" ref={inputRef} onChange={handleImgChange} />
    </main>
  )
}
