import ErrorModal from 'components/ui/modal/ErrorModal'
import { Field, Formik, useFormikContext } from 'formik'
import { StaticImage } from 'gatsby-plugin-image'
import React, { useEffect, useState, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'state'
import { postPlanIdForCustom, removeAddons, removeRecipes } from 'state/actions/account/flow/planAction'
import { PlanCtx } from '.'

/**
 * This is a formik form where the form options get populated by the BE response.
 * The numberOfPacks has to be over a certain amount depending on pack size. (MOQ)
 * selectionSameAsState checks whether the selected values in the dropdowns are the same values
 * as whats in state. This will prevent any MOQ issues from ever happening.
 */
const CustomPlanAlternate: React.FC = ({
  openTab,
  setCustomisePackSizeSelection,
  setCustomiseNoOfPacksSelection,
  setCustomiseWeeksSelection,
  customisePackSizeSelection,
  customiseNoOfPacksSelection,
  customiseWeeksSelection
}) => {
  const { packSizes, summary, dogInfo, error } = useSelector((state: RootState) => state.accountDogFlow)
  const dispatch = useDispatch()

  const [packSizeOptions, setPackSizeOptions] = useState([])
  const [noOfPacksOptions, setNoOfPacksOptions] = useState([])
  const [weeksOptions, setWeeksOptions] = useState([])

  const [showPopup, setShowPopup] = useState(false)

  const planCtx = useContext(PlanCtx)
  const {
    packSize,
    noOfPacks,
    weeks,
    prevPackSize,
    prevNoOfPacks,
    prevWeeks,
    packType,
    handlePackType,
    handleSetPackSize,
    handleSetNoOfPacks,
    handleSetWeeks,
    handleIsDefaultPlan,
    handleFixedOrCustomPlan
  } = planCtx

  useEffect(() => {
    if (prevPackSize) {
      handleSetPackSize(prevPackSize)
    }
  }, [prevPackSize])

  useEffect(() => {
    if (prevNoOfPacks) {
      handleSetNoOfPacks(prevNoOfPacks)
    }
  }, [prevNoOfPacks])

  useEffect(() => {
    if (prevWeeks) {
      handleSetWeeks(prevWeeks)
    }
  }, [prevWeeks])

  useEffect(() => {
    if (error) {
      setShowPopup(true)
    } else {
      setShowPopup(false)
    }
  }, [error])

  useEffect(() => {
    setCustomisePackSizeSelection(packSize ? packSize : prevPackSize)
    setCustomiseNoOfPacksSelection(noOfPacks ? noOfPacks : prevNoOfPacks)
    setCustomiseWeeksSelection(weeks ? weeks : prevWeeks)
  }, [openTab])

  useEffect(() => {
    if (packSize) {
      const range: number[] = []
      const index = packSizes?.pack.findIndex((item: { size: number }) => item.size === packSize)

      for (let i = packSizes.pack[index].minQuantity; i <= packSizes.pack[index].maxQuantity; i++) {
        range.push(i)
      }

      setNoOfPacksOptions(range)
      setWeeksOptions(packSizes.frequency)
      setPackSizeOptions(packSizes.pack)

      if (packSizes.pack[index].minQuantity > noOfPacks) {
        handleSetNoOfPacks(packSizes.pack[index].minQuantity)
      }

      if (packSizes.pack[index].maxQuantity < noOfPacks) {
        handleSetNoOfPacks(packSizes.pack[index].maxQuantity)
      }
    }
  }, [packSize, packType])

  useEffect(() => {
    if (packType) {
      if (packType === 'PARTIAL' || packType === 'FULL') {
        handleIsDefaultPlan(true)
        handleFixedOrCustomPlan('FIXED')
      } else {
        handleIsDefaultPlan(false)
        handleFixedOrCustomPlan('CUSTOM')
      }
    }
  }, [packType])

  useEffect(() => {
    if (packType && packType === 'CUSTOM' && weeks && packSize && noOfPacks) {
      handlePostCustomise()
      dispatch(removeRecipes())
      dispatch(removeAddons())
    }
  }, [packType, packSize, weeks, noOfPacks])

  const handlePostCustomise = () => {
    if (packType === 'CUSTOM' && !dogInfo.isInTrial && packSize && weeks && noOfPacks) {
      const currentPackSize = packSizes.pack.filter((pack: any) => pack.size === packSize)[0]
      if (noOfPacks >= currentPackSize.minQuantity && noOfPacks <= currentPackSize.maxQuantity) {
        let planId = `${packSize}g-${weeks}w`
        dispatch(
          postPlanIdForCustom({
            subscriptionId: dogInfo.subscriptionId,
            planId,
            noOfPacks: noOfPacks,
            packSize: packSize,
            weeks: weeks,
            isDefaultPlan: false,
            packType: 'CUSTOM',
            fixedOrCustomPlan: 'CUSTOM'
          })
        )
      }
    }
  }

  const getTotalGrams = () => {
    let totalGrams = 0
    if (summary?.length) {
      summary.map(dogInfo => {
        if (dogInfo.gramsPerDay) {
          totalGrams += dogInfo.gramsPerDay
        }
      })
    } else {
      totalGrams = dogInfo?.defaultPackSize?.gramsPerDay
    }

    return totalGrams
  }

  const roundToNearest5Percent = (digit: number) => {
    return Math.round(digit / 5) * 5
  }

  const getGramsPerDay = () => {
    if (packSize && noOfPacks && weeks) {
      return roundToNearest5Percent((packSize * noOfPacks) / (weeks * 7))
    }

    return roundToNearest5Percent((prevPackSize * prevNoOfPacks) / (prevWeeks * 7))
  }

  const getCustomPercentage = () => {
    return roundToNearest5Percent((getGramsPerDay() / getTotalGrams()) * 100)
  }

  const selectionSameAsState = (w, n, p) => {
    return parseInt(w) === weeks && parseInt(n) === noOfPacks && parseInt(p) === packSize
  }

  const validMoq = () => {
    if (packSize && noOfPacks && packSizes) {
      const index = packSizes?.pack.findIndex((item: { size: number }) => item.size === packSize)
      return packSizes?.pack[index].minQuantity <= noOfPacks && noOfPacks <= packSizes.pack[index].maxQuantity
    }

    return false
  }

  const handleCustomSubmit = (change: string, value: number) => {
    handlePackType('CUSTOM')
    handleIsDefaultPlan(false)

    if (change === 'packSize') {
      handleSetPackSize(value)
      setCustomisePackSizeSelection(value)
    }
    if (change === 'noOfPacks') {
      handleSetNoOfPacks(value)
    }
    if (change === 'weeks') {
      handleSetWeeks(value)
      setCustomiseWeeksSelection(value)
    }
  }

  return (
    <Formik
      key={`customiseForm`}
      enableReinitialize
      initialValues={{
        packSize: packSize ? packSize : prevPackSize,
        noOfPacks: noOfPacks ? noOfPacks : prevNoOfPacks,
        weeks: weeks ? weeks : prevWeeks
      }}
      onSubmit={_e => {
        return
      }}
    >
      {props => (
        <div key={`customise`}>
          <div
            className={`m-auto mb-5 w-full rounded-3xl bg-ddCustomise p-4 md:w-1/2 ${
              packType === 'CUSTOM' ? 'border border-4 border-primary' : ''
            }`}
          >
            <h3 data-testid="customisePlan" className="mb-4 text-center">
              Custom plan
            </h3>
            {packSize && (
              <p className="mb-4 text-center">
                This plan below provides around <span className="font-bold">{getGramsPerDay()}g</span> a day, which is
                around <span className="font-bold">{getCustomPercentage()}%</span> of your dog's recommended daily diet.
              </p>
            )}

            <div>
              <div className="mb-4 flex flex-wrap items-center justify-between">
                <h3 className="w-1/2">Pack Size</h3>
                <Field
                  className="w-1/2 rounded-md border border-gray-300 bg-white p-3 px-4 focus:border-secondary focus:ring-secondary"
                  onChange={e => {
                    if (typeof window.gtag !== 'undefined') {
                      window.gtag('event', 'change_pack_size', {
                        page: 'account_flow_plan'
                      })
                    }

                    const range = []
                    const index = packSizes?.pack.findIndex(
                      (item: { size: number }) => item.size === parseInt(e.target.value)
                    )

                    for (let i = packSizes.pack[index].minQuantity; i <= packSizes.pack[index].maxQuantity; i++) {
                      range.push(i)
                    }

                    setNoOfPacksOptions(range)

                    if (packSizes.pack[index].minQuantity >= noOfPacks) {
                      setCustomisePackSizeSelection(parseInt(e.target.value))
                      setCustomiseNoOfPacksSelection(parseInt(packSizes.pack[index].minQuantity))
                    }

                    if (noOfPacks >= packSizes.pack[index].maxQuantity) {
                      setCustomisePackSizeSelection(parseInt(e.target.value))
                      setCustomiseNoOfPacksSelection(parseInt(packSizes.pack[index].maxQuantity))
                    }

                    setCustomisePackSizeSelection(parseInt(e.target.value))

                    props.handleChange(e)
                    handleCustomSubmit('packSize', parseInt(e.target.value))
                  }}
                  data-testid="packSize"
                  id="packSize"
                  name="packSize"
                  as="select"
                >
                  {packSizeOptions &&
                    packSizeOptions.map(({ size }) => (
                      <option key={size} value={size}>
                        {size}g
                      </option>
                    ))}
                </Field>
              </div>

              <div className="mb-4 flex flex-wrap items-center justify-between">
                <h3 className="w-1/2">Number of Packs</h3>
                <Field
                  className="w-1/2 rounded-md border border-gray-300 bg-white p-3 px-4 focus:border-secondary focus:ring-secondary"
                  onChange={e => {
                    if (typeof window.gtag !== 'undefined') {
                      window.gtag('event', 'change_number_of_packs', {
                        page: 'account_flow_plan'
                      })
                    }

                    setCustomiseNoOfPacksSelection(parseInt(e.target.value))

                    props.handleChange(e)
                    handleCustomSubmit('noOfPacks', parseInt(e.target.value))
                  }}
                  data-testid="noOfPacks"
                  id="noOfPacks"
                  name="noOfPacks"
                  as="select"
                >
                  {noOfPacksOptions &&
                    noOfPacksOptions.map(num => (
                      <option key={num} value={num}>
                        {num}
                      </option>
                    ))}
                </Field>
              </div>

              <div className="flex flex-wrap items-center justify-between">
                <h3 className="w-1/2">Delivery Frequency</h3>

                <Field
                  className="w-1/2 rounded-md border border-gray-300 bg-white p-3 px-4 focus:border-secondary focus:ring-secondary"
                  onChange={e => {
                    if (typeof window.gtag !== 'undefined') {
                      window.gtag('event', 'change_delivery_frequency', {
                        page: 'account_flow_plan'
                      })
                    }

                    setCustomiseWeeksSelection(parseInt(e.target.value))

                    props.handleChange(e)
                    handleCustomSubmit('weeks', parseInt(e.target.value))
                  }}
                  data-testid="weeks"
                  id="weeks"
                  name="weeks"
                  as="select"
                >
                  {weeksOptions &&
                    weeksOptions.map(week => (
                      <option key={week} value={week}>
                        {week} weeks
                      </option>
                    ))}
                </Field>
              </div>
            </div>
            <div className="mt-4 flex justify-end">
              <button
                data-testid="customSelected"
                className={
                  packType === 'CUSTOM' &&
                  selectionSameAsState(customiseWeeksSelection, customiseNoOfPacksSelection, customisePackSizeSelection)
                    ? `btn-cta`
                    : `btn-primary`
                }
                onClick={() => {
                  handlePackType('CUSTOM')
                  handleIsDefaultPlan(false)

                  if (props.values.weeks) {
                    handleSetWeeks(parseInt(props.values.weeks))
                    setCustomiseWeeksSelection(parseInt(props.values.weeks))
                  }

                  if (props.values.noOfPacks) {
                    handleSetNoOfPacks(parseInt(props.values.noOfPacks))
                  }

                  if (props.values.packSize) {
                    handleSetPackSize(parseInt(props.values.packSize))
                    setCustomisePackSizeSelection(parseInt(props.values.packSize))
                  }
                }}
              >
                {packType === 'CUSTOM' &&
                validMoq() &&
                selectionSameAsState(customiseWeeksSelection, customiseNoOfPacksSelection, customisePackSizeSelection)
                  ? 'Selected'
                  : 'Select'}
              </button>
            </div>
          </div>
          <div className="m-auto mt-32 mb-8 flex w-full items-center justify-between rounded-[40px] bg-[#D7EAD2] p-4 md:w-1/2 lg:p-8">
            <div className="relative left-16 w-1/3 md:left-0 xl:w-0">
              <StaticImage
                imgStyle={{ position: 'absolute' }}
                style={{ top: 0, left: 0, position: 'absolute', transform: `translate(-50%, -50%)` }}
                className="m-auto h-44 w-44 md:h-44 md:w-44 lg:h-52 lg:w-52"
                src={'../../../../images/crumble-top-tip-new.png'}
                alt="Crumble's Top Tip"
              />
            </div>
            <div className="w-1/2 md:w-2/3 xl:w-full">
              <h3 className="font-third mb-4 text-center text-4xl sm:text-3xl lg:text-5xl">Crumble's top tip</h3>
              <p className="text-center text-xs sm:text-sm xl:text-lg">Our bigger packs are better value!</p>
            </div>
          </div>
          {error && (
            <ErrorModal
              show={showPopup}
              setShow={setShowPopup}
              errorMessage={''}
              url={null}
              action={null}
              actionText={null}
            />
          )}
        </div>
      )}
    </Formik>
  )
}

export default CustomPlanAlternate
