import EditFutureOrderModal from 'components/ui/modal/EditFutureOrderModal'
import EditPlanModal from 'components/ui/modal/EditPlanModal'
import ErrorModal from 'components/ui/modal/ErrorModal'
import StickyFooter from 'components/ui/sticky-footer/StickyFooter'
import { Link, navigate } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'state'
import { updatePlanRadio } from 'state/actions/account/accountDogAction'
import { cancelPlan, pausePlan } from 'state/actions/account/accountPlanAction'
import { resetPlansAndOrders } from 'state/actions/account/accountPlanAction'
import { updatePlans } from 'state/actions/account/accountPlanAction'
import { createDogsExistingPlan, resetErrorMessage } from 'state/actions/account/flow/dogAction'

const CancelPausePlanScreen = () => {
  const { changed, error } = useSelector((state: RootState) => state.accountDogFlow)
  const { planCancelStatus, planPauseStatus, planStatus, errorMessage, plans } = useSelector(
    (state: RootState) => state.accountPlan
  )
  const { dogsToDelete } = useSelector((state: RootState) => state.accountDog)
  const { spoofMode, spoofedUserId } = useSelector((state: RootState) => state.ui)

  const [showRedZoneModal, setShowRedZoneModal] = useState(false)
  const [redZoneConfirmButton, setRedZoneConfirmButton] = useState(<></>)
  const [showRescheduleModal, setShowRescheduleModal] = useState(false)
  const [showEditPlanModal, setShowEditPlanModal] = useState(false)
  const [showPopup, setShowPopup] = useState(false)
  const [loading, setLoading] = useState(false)

  const [is250Or600, setIs250Or600] = useState(false)

  const dispatch = useDispatch()

  // useEffect(() => {
  //   if (planStatus && Object.keys(planStatus).length === 0 && Object.getPrototypeOf(planStatus) === Object.prototype) {
  //     navigate(`/account/plan`)
  //   }
  // }, [])

  useEffect(() => {
    dispatch(resetErrorMessage())
  }, [])

  useEffect(() => {
    dispatch(updatePlans())
  }, [])

  useEffect(() => {
    plans?.map(p => {
      if (planStatus.subscriptionId === p.subscriptionId) {
        if (p.plan.includes('250') || p.plan.includes('600')) {
          setIs250Or600(true)
        }
      }
    })
  }, [plans])

  useEffect(() => {
    if (error) {
      setShowPopup(true)
    } else {
      setShowPopup(false)
    }
  }, [error])

  useEffect(() => {
    if (changed) {
      const url = spoofMode ? `/account/plan-recipes/?userId=` + spoofedUserId : `/account/plan-recipes/`
      navigate(url)
    }
  }, [changed])

  useEffect(() => {
    const resetCheckAndRedirect = async () => {
      if (planCancelStatus && planCancelStatus === 'success') {
        dispatch(resetPlansAndOrders())
        const url = spoofMode ? `/account/plan/?userId=` + spoofedUserId : `/account/plan/`
        navigate(url)
        setShowPopup(false)
      } else if (planCancelStatus && planCancelStatus === 'error') {
        console.log('Error canceling plan')
        setShowPopup(true)
      }

      if (planPauseStatus && planPauseStatus === 'success') {
        dispatch(resetPlansAndOrders())
        const url = spoofMode ? `/account/plan/?userId=` + spoofedUserId : `/account/plan/`
        navigate(url)
        setShowPopup(false)
      } else if (planPauseStatus && planPauseStatus === 'error') {
        console.log('Error pausing plan')
        setShowPopup(true)
      }
    }

    resetCheckAndRedirect()
  }, [planCancelStatus, planPauseStatus])

  const Description = (
    <div className="mt-8 font-normal">
      <div className="mb-4 flex w-full items-center">
        <StaticImage
          className="mr-4 flex w-10 sm:w-auto"
          src={'../../../../../images/reason/svg-image-1.svg'}
          alt="Reason 1"
          objectFit="contain"
          width={100}
        />
        <p className="w-full text-left text-sm">
          Gently warm the food in the microwave for 30 seconds to help bring out the aroma.
        </p>
      </div>
      <div className="mb-4 flex items-center">
        <StaticImage
          className="mr-4 w-10 sm:w-auto"
          src={'../../../../../images/reason/svg-image-2.svg'}
          alt="Reason 2"
          objectFit="contain"
          width={100}
        />
        <p className="w-full text-left text-sm">Try adding our Bone Broth on top for extra flavour and moisture.</p>
      </div>
      <div className="mb-4 flex items-center">
        <StaticImage
          className="mr-4 w-10 sm:w-auto"
          src={'../../../../../images/reason/svg-image-3.svg'}
          alt="Reason 3"
          objectFit="contain"
          width={100}
        />
        <p className="w-full text-left text-sm">Sprinkle their favourite treats on top of their food.</p>
      </div>

      <div className="my-8 sm:my-12">
        <p className="text-sm sm:text-base">Our customer delight team are always on hand to help.</p>
        <p className="mb-4 text-sm sm:text-base">
          Call us on{' '}
          <Link to="tel:+441743384562" className="font-bold">
            01743 384 562
          </Link>{' '}
          or email{' '}
          <Link to="mailto:feedme@differentdog.com" className="underline">
            feedme@differentdog.com
          </Link>
        </p>
      </div>
    </div>
  )

  const handleEditPlan = () => {
    if (planStatus.activePlan.redZone) {
      setRedZoneConfirmButton(
        <button
          type="button"
          className="btn-cta inline-flex w-full justify-center px-4 py-2 sm:col-start-1 sm:mt-0"
          onClick={() => {
            setShowRedZoneModal(false)
            dispatch(updatePlanRadio(planStatus.subscriptionId))
            dispatch(
              createDogsExistingPlan({
                subscriptionId: planStatus.subscriptionId,
                dogs: planStatus.activePlan.dogs
              })
            )
          }}
        >
          Edit plan
        </button>
      )
      setShowRedZoneModal(true)
    } else {
      dispatch(updatePlanRadio(planStatus.subscriptionId))
      dispatch(
        createDogsExistingPlan({
          subscriptionId: planStatus.subscriptionId,
          dogs: planStatus.activePlan.dogs
        })
      )
    }
  }

  const handlePlanCancel = async () => {
    if (planStatus?.activePlan?.redZone) {
      setShowRedZoneModal(true)
      setRedZoneConfirmButton(
        <button
          type="button"
          onClick={async () => {
            setRedZoneConfirmButton(
              <button
                type="button"
                disabled={true}
                data-testid="cancelFuturePlan"
                className="btn-cta inline-flex w-full justify-center px-4 py-2 opacity-50 sm:col-start-1 sm:mt-0"
              >
                {planStatus.actionType === 'pause' ? 'Pause plan' : 'Cancel plan'}
              </button>
            )
            if (planStatus.actionType === 'cancel') {
              setLoading(true)
              dispatch(
                cancelPlan({
                  subscriptionId: planStatus.subscriptionId,
                  text: planStatus.text || '',
                  id: planStatus.id,
                  reason: planStatus.reason,
                  deleteDogs: dogsToDelete
                })
              )
              if (typeof window.gtag !== 'undefined') {
                window.gtag('event', 'account_cancel_plan', {
                  page: 'account_cancel_plan'
                })
              }
              setLoading(false)
            } else if (planStatus.actionType === 'pause') {
              setLoading(true)
              await dispatch(
                pausePlan({
                  subscriptionId: planStatus.subscriptionId,
                  text: planStatus.text || '',
                  id: planStatus.id,
                  reason: planStatus.reason
                })
              )
              if (typeof window.gtag !== 'undefined') {
                window.gtag('event', 'account_pause_plan', {
                  page: 'account_pause_plan'
                })
              }
              setLoading(false)
            }
          }}
          data-testid="cancelFuturePlan"
          className="btn-cta inline-flex w-full justify-center px-4 py-2 sm:col-start-1 sm:mt-0"
        >
          {planStatus.actionType === 'pause' ? 'Pause plan' : 'Cancel plan'}
        </button>
      )
      return
    } else if (planStatus.actionType === 'cancel') {
      setLoading(true)
      await dispatch(
        cancelPlan({
          subscriptionId: planStatus.subscriptionId,
          text: planStatus.text || '',
          id: planStatus.id,
          reason: planStatus.reason,
          deleteDogs: dogsToDelete
        })
      )
      if (typeof window.gtag !== 'undefined') {
        window.gtag('event', 'account_cancel_plan', {
          page: 'account_cancel_plan'
        })
      }
      setLoading(false)
    } else if (planStatus.actionType === 'pause') {
      setLoading(true)
      await dispatch(
        pausePlan({
          subscriptionId: planStatus.subscriptionId,
          text: planStatus.text || '',
          id: planStatus.id,
          reason: planStatus.reason
        })
      )
      if (typeof window.gtag !== 'undefined') {
        window.gtag('event', 'account_pause_plan', {
          page: 'account_pause_plan'
        })
      }
      setLoading(false)
    }
  }

  return (
    <div className="min-h-screen">
      <div className="flex flex-1 flex-col">
        <main className="flex flex-col items-center justify-center gap-4 p-4 md:flex-row md:justify-between">
          <div className="w-full md:w-1/2">
            <div className="mx-auto w-fit">
              <h1 className="text-3xl sm:text-5xl">Before</h1>
              <h2 className="mb-4 text-end text-2xl sm:text-3xl">you go...</h2>
            </div>
            <div className="mx-auto w-full text-center">
              {planStatus.id !== 2 && (
                <p
                  className="text-sm sm:text-base"
                  dangerouslySetInnerHTML={{ __html: planStatus?.incentive?.subHeading }}
                />
              )}

              {planStatus.id === 2 && (
                <p className="mb-4 text-sm sm:text-base">
                  Thank you for being one of our wonderful pupstomers: as a small business, having you with us for the
                  journey means a lot. If you are trying to save money but don't want to lose the nutritional benefits
                  of our food, then here are some ways we can help:
                </p>
              )}

              {planStatus.id === 2 && (
                <ul className="mb-4 list-disc text-left text-sm sm:text-base">
                  <li className="mb-2">
                    The best way to regularly save on your deliveries is to refer a friend; every time someone uses your
                    code, you'll save £10 on your next box.
                  </li>
                  {is250Or600 && <li>Ensure you are on the most economical pack size by editing your plan below.</li>}
                  <li className="mb-2">
                    Consider switching from a complete Different Dog diet to a partial diet. Studies suggest that
                    feeding as little as 20% fresh has significant health benefits, so feeding our recipes as a topper
                    is an affordable way of accessing the nutrients your dog needs to thrive.
                  </li>
                </ul>
              )}

              {planStatus.id === 2 && (
                <p className="mb-4 text-sm sm:text-base">
                  You and your four-legged-friend are important to us, so why not call our Customer Delight team on{' '}
                  <a className="font-bold" href="tel:+441743384562">
                    01743 384 562
                  </a>{' '}
                  and quote "CRUMBLE" for a discount on your next box.
                </p>
              )}

              {planStatus.id === 2 && (
                <div>
                  <p className="font-serif text-sm sm:text-base">Charlie</p>
                  <p className="mb-4 font-serif text-sm sm:text-base">Co-Founder of Different Dog</p>
                </div>
              )}

              <div className="mt-4 flex flex-col items-center justify-center gap-4">
                {planStatus.id === 1 && Description}
                {planStatus.id !== 2 && (
                  <button
                    className="inline-flex w-full max-w-xs items-center justify-center gap-3 rounded-full border-2 border-primary bg-transparent py-2 px-4 text-lg text-base font-medium drop-shadow-lg hover:bg-base hover:text-white focus:border-white focus:outline-secondary"
                    onClick={() => {
                      if (typeof window.gtag !== 'undefined') {
                        window.gtag('event', `account_${planStatus.actionType}_change_date`, {
                          page: 'account_cancel_plan'
                        })
                      }
                      if (planStatus.activePlan.redZone) {
                        setRedZoneConfirmButton(
                          <button
                            type="button"
                            className="btn-cta inline-flex justify-center px-4 py-2 sm:col-start-1 sm:mt-0"
                            onClick={() => {
                              setShowRedZoneModal(false)
                              setShowRescheduleModal(true)
                              setShowEditPlanModal(true)
                            }}
                          >
                            Edit future date
                          </button>
                        )
                        setShowRedZoneModal(true)
                      } else {
                        setShowRescheduleModal(true)
                        setShowEditPlanModal(true)
                      }
                    }}
                  >
                    Change delivery date
                  </button>
                )}

                {planStatus.id === 2 && (
                  <button
                    className="inline-flex w-full max-w-xs items-center justify-center gap-3 rounded-full border-2 border-primary bg-transparent py-2 px-4 text-lg text-base font-medium drop-shadow-lg hover:bg-base hover:text-white focus:border-white focus:outline-secondary"
                    onClick={() => {
                      setLoading(true)
                      if (typeof window.gtag !== 'undefined') {
                        window.gtag('event', `account_${planStatus.actionType}_change_date`, {
                          page: 'account_cancel_plan'
                        })
                      }
                      const url = spoofMode ? `/account/rewards/?userId=` + spoofedUserId : `/account/rewards/`
                      navigate(url)
                      setLoading(false)
                    }}
                  >
                    Refer a friend and save £10!
                  </button>
                )}

                {!planStatus?.activePlan?.isInTrial && planStatus.id !== 2 && (
                  <button
                    className="inline-flex w-full max-w-xs items-center justify-center gap-3 rounded-full border-2 border-primary bg-transparent py-2 px-4 text-lg text-base font-medium drop-shadow-lg hover:bg-base hover:text-white focus:border-white focus:outline-secondary"
                    onClick={() => {
                      setLoading(true)
                      if (typeof window.gtag !== 'undefined') {
                        window.gtag('event', `account_${planStatus.actionType}_change_frequency`, {
                          page: 'account_cancel_plan'
                        })
                      }
                      handleEditPlan()
                      setLoading(false)
                    }}
                  >
                    Change delivery frequency
                  </button>
                )}

                {!planStatus?.activePlan?.isInTrial && (
                  <button
                    className="inline-flex w-full max-w-xs items-center justify-center gap-3 rounded-full border-2 border-primary bg-transparent py-2 px-4 text-lg text-base font-medium drop-shadow-lg hover:bg-base hover:text-white focus:border-white focus:outline-secondary"
                    onClick={() => {
                      setLoading(true)
                      if (typeof window.gtag !== 'undefined') {
                        window.gtag('event', `account_${planStatus.actionType}_edit_plan`, {
                          page: 'account_cancel_plan'
                        })
                      }
                      handleEditPlan()
                      setLoading(false)
                    }}
                  >
                    Change pack quantity or size
                  </button>
                )}

                {planStatus.actionType === 'cancel' &&
                  (planStatus?.activePlan?.status === 'future' || planStatus?.activePlan?.status === 'active') && (
                    <button
                      data-testid="cancelYourPlan"
                      type="button"
                      disabled={loading || !planStatus.reason}
                      className="btn-cta inline-flex w-full max-w-xs items-center justify-center gap-3 rounded-full border-2 border-primary bg-transparent py-2 px-4 text-lg text-base font-medium drop-shadow-lg hover:bg-base hover:text-white focus:border-white focus:outline-secondary disabled:opacity-50"
                      onClick={handlePlanCancel}
                    >
                      Cancel your plan
                    </button>
                  )}
                {planStatus?.actionType === 'pause' && planStatus?.activePlan?.status === 'active' && (
                  <button
                    type="button"
                    disabled={loading || !planStatus.reason}
                    className="btn-cta inline-flex w-full max-w-xs items-center justify-center gap-3 rounded-full border-2 border-primary bg-transparent py-2 px-4 text-lg text-base font-medium drop-shadow-lg hover:bg-base hover:text-white focus:border-white focus:outline-secondary disabled:opacity-50"
                    onClick={handlePlanCancel}
                  >
                    Pause your plan
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="max-w-sm md:max-w-full">
            <StaticImage src="../../../../../images/thankyou-dog.png" alt="Cancel Plan" />
          </div>
        </main>

        <StickyFooter className="border-gray fixed bottom-0 left-0 right-0 z-50 ml-auto border-t-2 bg-white sm:w-[calc(100%-12rem)]">
          <div className="w-full text-center">
            <button
              onClick={() => {
                if (typeof window.gtag !== 'undefined') {
                  window.gtag('event', `account_${planStatus.actionType}_back`, {
                    page: 'account_cancel_plan'
                  })
                }
                if (planStatus.actionType === 'cancel') {
                  const url = spoofMode
                    ? `/account/plan/${planStatus.subscriptionId}/status/cancel/?userId=` + spoofedUserId
                    : `/account/plan/${planStatus.subscriptionId}/status/cancel/`
                  navigate(url)
                } else if (planStatus.actionType === 'pause') {
                  const url = spoofMode
                    ? `/account/plan/${planStatus.subscriptionId}/status/pause/?userId=` + spoofedUserId
                    : `/account/plan/${planStatus.subscriptionId}/status/pause/`
                  navigate(url)
                }
              }}
              className="btn-secondary"
            >
              Back
            </button>
          </div>
        </StickyFooter>
      </div>
      {showRedZoneModal && (
        <EditFutureOrderModal
          show={showRedZoneModal}
          setShow={setShowRedZoneModal}
          confirmButton={redZoneConfirmButton}
        />
      )}
      {showEditPlanModal && (
        <EditPlanModal
          key={`editPlanModal`}
          show={showEditPlanModal}
          setShow={setShowEditPlanModal}
          showReschedule={showRescheduleModal}
          setShowReschedule={setShowRescheduleModal}
          subscriptionId={planStatus.subscriptionId}
          status={planStatus.activePlan.status}
          nextDelivery={planStatus.activePlan.nextDelivery}
        />
      )}
      {((planCancelStatus && planCancelStatus === 'error') ||
        (planPauseStatus && planPauseStatus === 'error') ||
        error) && (
        <ErrorModal
          show={showPopup}
          setShow={setShowPopup}
          errorMessage={errorMessage?.popup?.message ?? errorMessage?.message}
          url={errorMessage?.popup?.url ?? errorMessage?.url}
          action={errorMessage?.popup?.action ?? errorMessage?.action}
          actionText={errorMessage?.popup?.actionText ?? errorMessage?.actionText}
        />
      )}
    </div>
  )
}

export default CancelPausePlanScreen
