import { faClose, faExclamation } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dialog, Transition } from '@headlessui/react'
import { Field, Form, Formik } from 'formik'
import { Link } from 'gatsby'
import React, { Fragment, useState } from 'react'
import Api from 'state/apis/api'
import * as yup from 'yup'

export default function ReportWebsiteIssueModal({ show, setShow, customer, actionType }) {
  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-50 overflow-y-auto" onClose={setShow}>
        <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block w-full max-w-3xl transform rounded-3xl bg-white p-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:p-8 sm:align-middle">
              <div>
                <FontAwesomeIcon
                  icon={faClose}
                  fontSize={24}
                  className="absolute top-4 right-4 cursor-pointer md:top-8 md:right-8"
                  onClick={() => setShow(false)}
                />
                <div className="mb-1">
                  <Dialog.Title as="h3" className="mb-8 text-center text-lg font-medium leading-6">
                    Please describe your experience of using our website
                  </Dialog.Title>
                </div>
                <div>
                  <Formik
                    initialValues={{
                      message: ''
                    }}
                    onSubmit={(values, { setFieldError, setSubmitting }) => {
                      setSubmitting(true)
                      Api.post('wwwREST', '/v1/public/contact', {
                        body: {
                          name: customer?.name,
                          surname: customer?.surname,
                          email: customer?.email,
                          telephone: customer?.telephone,
                          subject: `${customer?.name} ${customer.surname} ${actionType} website feedback`,
                          message: values.message
                        }
                      })
                        .then(_res => {
                          setSubmitting(false)
                          setShow(false)
                        })
                        .catch(() => {
                          setSubmitting(false)
                          setFieldError('message', 'There has been a problem sending the request.')
                        })
                    }}
                    validationSchema={yup.object({
                      message: yup.string().required()
                    })}
                  >
                    {props => (
                      <Form className="mb-8">
                        <div className="mb-8 w-full">
                          <Field
                            className="w-full rounded-xl focus:border-[#B0CDD5] focus:outline-none focus:ring-[#B0CDD5]"
                            name="message"
                            as="textarea"
                            placeholder="Write your message here"
                            rows={5}
                          />
                          {props.errors.message && props.touched.message ? (
                            <p className="text-xs text-red-500">{props.errors.message}</p>
                          ) : (
                            ''
                          )}
                        </div>
                        <div className="flex w-full flex-col items-center justify-center">
                          <button
                            type="submit"
                            disabled={!props.isValid || props.isSubmitting}
                            className="btn-cta w-full max-w-[385px] disabled:opacity-50"
                          >
                            Send
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
              <div className="mx-auto grid grid-cols-3">
                <div className="col-start-2 inline-flex justify-center">
                  <button onClick={() => setShow(false)} className="btn-secondary">
                    Close
                  </button>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
