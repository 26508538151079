import { Dialog, Transition } from '@headlessui/react'
import { StaticImage } from 'gatsby-plugin-image'
import React, { Fragment } from 'react'

const TreatDiscountModal = ({ show, setShow, discount, discountSelected }) => {
  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-50 overflow-y-auto" onClose={() => {}}>
        <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block max-w-[20rem] transform rounded-2xl bg-white text-left align-bottom shadow-xl transition-all sm:my-8 md:w-full md:max-w-[40rem] md:align-middle">
              <div className="z-index-2 relative ml-[20%] -mt-7 w-[60%] md:ml-[30%] md:w-[40%]">
                <div className="z-index-2 inline-flex rounded-full bg-third py-3 justify-center items-center w-full">
                  {/* Mobile view */}
                  <div className="md:hidden block text-center text-white text-2xl font-bold px-1">{discount}% OFF</div>
                  {/* Desktop view */}
                  <div className="md:block hidden text-center text-white text-2xl font-bold">
                    {discount}% OFF TREATS
                  </div>
                </div>
              </div>
              <div className="z-index-1 relative flex h-[180px] flex-col items-center overflow-hidden md:h-[250px] rounded-t-2xl -mt-7">
                <StaticImage
                  objectFit="cover"
                  className="-mt-10 h-72 max-w-full lg:h-80"
                  src="../../../images/treats-modal.png"
                  alt="Treats"
                />
              </div>

              <div className="px-4 md:px-8">
                <div className="w-full pb-4 text-center md:pb-4">
                  {/* Mobile view */}
                  <p className="block md:hidden h3 mb-8 mt-6 pt-2 text-center font-medium text-sm md:text-xl">
                    Enjoy {discount}% off all our treats <br /> on your next two boxes
                  </p>
                  {/* Desktop view */}
                  <p className="hidden md:block h3 mb-8 mt-6 pt-2 text-center font-medium text-sm md:text-xl">
                    Enjoy {discount}% off all our treats on your next two boxes
                  </p>
                  {/* Mobile view */}
                  <div className="block md:hidden text-xs md:text-base mb-10 lg:mb-4 font-sans">
                    <p>
                      Discount is limited to £30 total <br /> per customer, per box.
                    </p>
                  </div>
                  {/* Desktop view */}
                  <div className="hidden md:block text-xs md:text-base mb-10 lg:mb-4 font-sans">
                    <p>Discount is limited to £30 total per customer, per box.</p>
                  </div>

                  <button
                    type="button"
                    className="btn-cta my-4 !px-8"
                    onClick={() => {
                      discountSelected(discount)
                      setShow(false)
                    }}
                  >
                    Apply discount
                  </button>
                </div>
                <div className="mx-auto mb-8 grid grid-cols-3">
                  <div className="col-start-2 inline-flex justify-center">
                    <button
                      onClick={() => {
                        setShow(false)
                        discountSelected(0)
                      }}
                      className="btn-secondary"
                    >
                      No Thanks
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default TreatDiscountModal
