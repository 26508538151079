import dayjs from 'dayjs'
import deliveryNotificationIcon from 'images/delivery-notification.svg'
import React from 'react'

interface AccordionProps {
  order: any
}

const TrackOrderAccordion: React.FC<AccordionProps> = ({ order }) => {
  const formatDate = (inputDate: string) => {
    // Parse the input date string in the format 'DD/MM/YYYY'
    const parts = inputDate.split('/')
    const day = parseInt(parts[0], 10)
    const month = parseInt(parts[1], 10)
    const year = parseInt(parts[2], 10)

    // Use day.js to create a date object
    const dateObject = dayjs(`${year}-${month}-${day}`)

    // Format the date
    const formattedDate = dateObject.format('dddd Do MMMM')

    return formattedDate
  }
  return (
    <div className="relative mx-auto mt-8 mb-2 md:mb-0 w-full rounded-3xl bg-[#D7EAD2] md:w-3/4 lg:w-5/6 2xl:w-1/2">
      <div className="relative grid w-full grid-flow-col grid-cols-[auto_1fr] gap-x-1 md:gap-x-8 overflow-visible p-2 md:p-4 px-2 md:px-8 md:flex-row justify-center items-center">
        <div>
          <div className="order-1 mx-auto flex">
            <div className="flex flex-col items-center justify-center">
              <img src={deliveryNotificationIcon} alt="upcoming delivery" />
            </div>
          </div>
        </div>
        <div className="text-sm">
          <div>
            <span className="h3 mr-5 text-base font-medium md:text-2xl">It's on its way!</span>
            <p className="text-xs md:text-sm">
              Upcoming delivery date:
              <span className="w-full font-bold">
                {' '}
                {formatDate(dayjs(order?.shipDate).add(1, 'day').format('DD/MM/YYYY'))}
              </span>
            </p>
          </div>
        </div>
        <a
          className="btn-primary w-full justify-center !border-[#2A6482] hover:!bg-[#2A6482] hover:!text-white focus:!bg-[#2A6482] focus:!text-white md:w-auto my-6 md:my-0"
          target="_blank"
          href={`${order?.tracking?.url}`}
          rel="noreferrer"
        >
          Track order
        </a>
      </div>
    </div>
  )
}

export default TrackOrderAccordion
