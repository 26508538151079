import { faCheck, faEye, faEyeSlash, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useLocation } from '@reach/router'
import { Amplify, Auth, Cache } from 'aws-amplify'
import ErrorToast from 'components/ui/toast/ErrorToast'
import Toast from 'components/ui/toast/Toast'
import { Field, Form, Formik } from 'formik'
import { navigate } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import ReactTooltip from 'react-tooltip'
import * as yup from 'yup'
//@ts-ignore
import awsconfig from '../../../../aws-exports'
import ResetPasswordBanner from '../../../ui/banner/ResetPasswordBanner'
Amplify.configure(awsconfig)

interface LoginType {
  email?: string
  password?: string
}

interface forgotPassType {
  email?: string
}
interface ResetPassType {
  password?: string
  confirmPassword?: string
  code?: number
}

export const PassValid: React.FC = ({ value = '' }: any) => {
  const validArr = [
    {
      Msg: <span className="ml-1 underline decoration-dotted">minimum of 8 characters</span>,
      condition: value?.length >= 8
    },
    {
      Msg: <span className="ml-1 underline decoration-dotted">lower case letter</span>,
      condition: /[a-z]+/.test(value)
    },
    {
      Msg: <span className="ml-1 underline decoration-dotted">capital letter</span>,
      condition: /[A-Z]+/.test(value)
    },
    {
      Msg: <span className="ml-1 underline decoration-dotted">number</span>,
      condition: /\d+/.test(value)
    }
    // {
    //   Msg: <span className="ml-1 underline decoration-dotted">special character</span>,
    //   condition: /[!"#$%&£'()*+,-./:;<=>?@[\\\]^_`{|}~]+/.test(value)
    // }
  ]

  const IconToDisplay = ({ condition }: any) => {
    return (
      <FontAwesomeIcon
        icon={faCheck}
        color="green"
        className={`mr-2 ${condition ? 'visible' : 'invisible'}`}
        size="1x"
      />
    )
  }

  return (
    <div className="mt-4 text-xs">
      Your password must:
      <div className="ml-4 mt-3 leading-6">
        {validArr.map(itm => (
          <p className="flex items-center">
            <IconToDisplay condition={itm.condition} /> Contain a {itm.Msg}
          </p>
        ))}
      </div>
    </div>
  )
}
export const PassValidAlternate: React.FC = ({ value = '', errors = null, touched = null }: any) => {
  const validArr = [
    {
      Msg: <span className="ml-1">minimum of 8 characters</span>,
      condition: value?.length >= 8
    },
    {
      Msg: <span className="ml-1">lower case letter</span>,
      condition: /[a-z]+/.test(value)
    },
    {
      Msg: <span className="ml-1">capital letter</span>,
      condition: /[A-Z]+/.test(value)
    },
    {
      Msg: <span className="ml-1">number</span>,
      condition: /\d+/.test(value)
    }
  ]

  const IconToDisplay = ({ condition }: any) => {
    return (
      <FontAwesomeIcon
        icon={faCheck}
        color="#004355"
        className={`mr-2 ${condition ? 'visible' : 'invisible'}`}
        size="1x"
      />
    )
  }

  return (
    <div className={`pt-4 text-xs`}>
      <span className={`ml-4 text-sm ${errors && touched ? 'text-[#FF220C]' : ''}`}>Your password must contain:</span>
      <div className="mt-2 leading-6">
        {validArr.map(itm => (
          <p className="ml-1 mr-1 flex items-center text-sm">
            <IconToDisplay condition={itm.condition} /> a {itm.Msg}
          </p>
        ))}
      </div>
    </div>
  )
}

const SignIn: React.FC = () => {
  const { href, search } = useLocation()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [code, setCode] = useState('')
  const [showCode, setShowCode] = useState(true)
  const [awsError, setAWSError] = useState(undefined)
  const [errors, setErrors] = useState<LoginType | undefined>(undefined)
  const [resetPasswordErrors, setResetPasswordErrors] = useState<ResetPassType | undefined>(undefined)
  const [forgotPasswordErrors, setForgotPasswordErrors] = useState<forgotPassType | undefined>(undefined)
  const [forgotPasswordErrorsMessage, setForgotPasswordErrorsMessage] = useState<string | undefined>(undefined)
  const [isSubmittingSignIn, setIsSubmittingSignIn] = useState(false)
  const [isSubmittingResetPassword, setIsSubmittingResetPassword] = useState(false)
  const [isSubmittingForgotPassword, setIsSubmittingForgotPassword] = useState(false)
  const [showSignInForm, setShowSignInForm] = useState(true)
  const [showResetPasswordForm, setShowResetPasswordForm] = useState(false)
  const [showForgotPasswordForm, setShowForgotPasswordForm] = useState(false)
  const [loading, setLoading] = useState(false)

  const [showCodeSentMessage, setShowCodeSentMessage] = useState(false)
  const [showToast, setShowToast] = useState(false)
  const [errorToast, setErrorToast] = useState(false)

  const [zendeskScript, setZendeskScript] = useState(null)

  const url = new URL(href)
  const searchValue = url.searchParams

  const loginSchema = yup.object().shape({
    email: yup.string().email().required(),
    password: yup.string().required()
  })

  const forgotPaswordSchema = yup.object().shape({
    email: yup.string().email().required()
  })

  const resetPasswordSchema = yup.object().shape({
    password: yup
      .string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters')
      .max(99, 'Password must be at most 99 characters')
      .matches(/[a-z]+/, 'Must include at least one lowercase character')
      .matches(/[A-Z]+/, 'Must include at least one uppercase character')
      // .matches(/[!"#$%&£'()*+,-./:;<=>?@[\\\]^_`{|}~]+/, 'Must include at least one special character')
      .matches(/\d+/, 'Must include at least one number'),
    // .matches(/^\\s+$/, 'Cannot include space'),
    confirmPassword: yup
      .string()
      .label('Confirm password')
      .oneOf([yup.ref('password'), ''], 'Passwords do not match')
      .required('Confirm password is required'),
    code: yup.number().required('Code is required')
  })

  const resetSignInForm = () => {
    setPassword('')
    setErrors(undefined)
    setForgotPasswordErrors(undefined)
    setResetPasswordErrors(undefined)
    setIsSubmittingSignIn(false)
    setIsSubmittingForgotPassword(false)
    setShowSignInForm(false)
    setShowForgotPasswordForm(false)
    setShowResetPasswordForm(false)
    setShowCodeSentMessage(true)
    setForgotPasswordErrorsMessage(undefined)
  }

  const handleResetPassword = async () => {
    try {
      await Auth.forgotPassword(email)
      resetSignInForm()
    } catch (err: any) {
      console.log(err)
      if (err.message.includes('Username/client id combination not found')) {
        setForgotPasswordErrorsMessage('Email does not exist')
      } else {
        setForgotPasswordErrorsMessage(err.message)
      }
      setIsSubmittingForgotPassword(false)
    }
  }

  const redirectToSignForm = () => {
    navigate(href)
    setShowResetPasswordForm(true)
    setShowSignInForm(false)
  }

  const handleForgotPassword = () => {
    setShowSignInForm(false)
    setShowForgotPasswordForm(true)
  }

  const handleErrors = async () => {
    setAWSError(undefined)
    setErrors(undefined)
    let formErrors: LoginType | undefined = undefined
    await loginSchema.validate({ email, password }, { abortEarly: false }).catch(errs => {
      errs.inner.forEach(({ path, message }: any) => {
        if (path != null) {
          formErrors = { ...formErrors, [path]: message }
        }
      })
    })

    if (formErrors) {
      setErrors(formErrors)
      return
    }
    setIsSubmittingSignIn(true)
    return true
  }

  const handleSignIn = async () => {
    setIsPasswordVisible(false)
    const submit = await handleErrors()
    if (submit || isSubmittingSignIn) {
      try {
        setLoading(true)
        const success = await Auth.signIn(email, password)
        const userGroup = success.signInUserSession.accessToken.payload['cognito:groups'][0]
        if (success) {
          setLoading(false)
        }
        if (success.challengeName === 'NEW_PASSWORD_REQUIRED') {
          handleResetPassword()
        } else {
          const redirectValue = searchValue.get('r')
          if (redirectValue && redirectValue.match('^/account')) {
            navigate(redirectValue)
          } else if (userGroup === 'customers') {
            navigate('/account/')
          } else if (userGroup === 'admin' || userGroup === 'staff') navigate('/admin/')
        }
      } catch (err: any) {
        console.error(JSON.stringify(err, null, 2))

        setLoading(false)
        setAWSError(err.message)
        if (err.name === 'PasswordResetRequiredException') {
          handleResetPassword()
        }
      }
      setIsSubmittingSignIn(false)
    }
  }

  const handleForgotPasswordSubmit = async (e: any) => {
    e.preventDefault()
    setForgotPasswordErrors(undefined)
    let errors: ResetPassType | undefined
    await forgotPaswordSchema.validate({ email }, { abortEarly: false }).catch(errs => {
      errs.inner.forEach(({ path, message }: any) => {
        if (path != null) {
          errors = { ...errors, [path]: message }
        }
      })
    })
    if (errors) {
      setForgotPasswordErrors(errors)
    }
    setIsSubmittingForgotPassword(true)
  }

  useEffect(() => {
    if (isSubmittingForgotPassword) {
      if (forgotPasswordErrors) {
        setIsSubmittingForgotPassword(false)
        return
      }
      handleResetPassword()
    }
  }, [isSubmittingForgotPassword])

  useEffect(() => {
    if (search) {
      setShowSignInForm(false)
      setShowResetPasswordForm(true)
      const emailValue = searchValue.get('e')
      const codeValue = searchValue.get('c')
      const redirectValue = searchValue.get('r')

      if (emailValue && codeValue) {
        const emailBuffer = atob(emailValue)
        setEmail(emailBuffer)
        setCode(codeValue)
        setShowCodeSentMessage(false)
        setShowResetPasswordForm(true)
        setShowCode(false)
      } else {
        setShowResetPasswordForm(false)
        setShowSignInForm(true)
        !redirectValue && navigate('/account/signin')
      }
    }
  }, [search])

  const handleResetPasswordSubmit = async (values: any) => {
    setIsPasswordVisible(false)
    setIsConfirmPasswordVisible(false)
    if (values) {
      setPassword(values.password)
      setCode(`${values.code}`)
      setIsSubmittingResetPassword(true)
    }
  }

  useEffect(() => {
    if (isSubmittingResetPassword) {
      if (resetPasswordErrors) {
        setIsSubmittingResetPassword(false)
        return
      }
      ;(async function () {
        try {
          await Auth.forgotPasswordSubmit(email, code, password)
          setShowToast(true)
          handleSignIn()
        } catch (_err) {
          setIsSubmittingResetPassword(false)
          setShowResetPasswordForm(false)
          setShowSignInForm(true)
          setErrorToast(true)
        }
      })()
    }
  }, [isSubmittingResetPassword])

  const handleSetShowToast = (show: boolean) => {
    setShowToast(show)
  }

  const handleSetErrorToast = (show: boolean) => {
    setErrorToast(show)
  }

  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false)
  const [isFocus, setIsFocus] = useState(false)

  useEffect(() => {
    const zendeskScript = (
      <script type="text/javascript">{`
        if (typeof window.zE !== 'undefined') {
          window.zE('webWidget', 'helpCenter:setSuggestions', { search: 'reset password' })
        }
    `}</script>
    )
    // This is a hack because zendesk takes a long time to load onto the page
    window.setTimeout(() => {
      setZendeskScript(zendeskScript)
    }, 1000)
  }, [])

  return (
    <>
      <Helmet>{zendeskScript && zendeskScript}</Helmet>
      <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <div className="flex w-full justify-center">
            <StaticImage className="h-14 w-14" src="../../../../images/heart-logo.png" alt="Different Dog" />
          </div>
          <h2 className="mt-6 text-center text-3xl">
            {showSignInForm ? 'Sign in to your account' : 'Reset your password'}
          </h2>
        </div>
        <div className="w-11/12 mx-auto lg:w-full">
          {!showSignInForm && (
            <p className="mt-8 text-center text-lg">
              Use this form to create a new password or reset your existing one
            </p>
          )}
        </div>

        <div className="mb-8 mt-4 sm:mx-auto sm:w-full sm:max-w-xl">
          <div className="min-h-full rounded-3xl bg-[#F6F1ED] py-8 px-8">
            {showSignInForm && (
              <form
                className="relative space-y-4"
                action="#"
                method="POST"
                onSubmit={e => {
                  e.preventDefault()
                  handleSignIn()
                }}
              >
                <div>
                  <div className="relative flex w-full flex-row items-center">
                    <input
                      data-testid="email"
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      value={email}
                      required
                      className={`peer block w-full appearance-none rounded-md border pt-6 placeholder-gray-400 shadow-sm focus:outline-none focus:ring-0 sm:text-sm ${
                        errors && errors.email
                          ? 'border-2 border-[#F58C82] focus:border-[#F58C82]'
                          : 'border-gray-300 focus:border-2 focus:border-secondary'
                      }`}
                      onChange={e => {
                        setErrors({ ...errors, email: undefined })
                        setEmail(e.target.value)
                      }}
                    />
                    <label
                      htmlFor="email"
                      className={`absolute top-0 inline-flex h-full transform cursor-text appearance-none items-center duration-300 peer-focus:ml-0 peer-focus:-translate-y-3 peer-focus:scale-75 ${
                        email ? 'ml-0 -translate-y-3 scale-75' : 'ml-4'
                      }`}
                    >
                      Email address
                    </label>
                  </div>
                </div>

                {errors && errors.email && <div className="text-sm text-[#FF220C]">{errors.email}</div>}

                <div>
                  <div className="relative flex w-full flex-row items-center">
                    <input
                      data-testid="password"
                      id="password"
                      name="password"
                      type={isPasswordVisible ? 'text' : 'password'}
                      autoComplete="current-password"
                      required
                      className={`peer block w-full appearance-none rounded-md border pt-6 placeholder-gray-400 shadow-sm focus:outline-none focus:ring-0 sm:text-sm ${
                        errors && errors.password
                          ? 'border-2 border-[#F58C82] focus:border-[#F58C82]'
                          : 'border-gray-300 focus:border-2 focus:border-secondary'
                      }`}
                      onChange={e => {
                        setErrors({ ...errors, password: undefined })
                        setPassword(e.target.value)
                      }}
                    />
                    <label
                      htmlFor="password"
                      className={`absolute top-0 inline-flex h-full transform cursor-text appearance-none items-center duration-300 peer-focus:ml-1 peer-focus:-translate-y-3 peer-focus:scale-75 ${
                        password ? 'ml-1 -translate-y-3 scale-75' : 'ml-4'
                      }`}
                    >
                      Password
                    </label>
                    <FontAwesomeIcon
                      icon={isPasswordVisible ? faEyeSlash : faEye}
                      className="absolute right-4 top-1/2 -translate-y-1/2 cursor-pointer"
                      onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                    />
                  </div>
                </div>

                <div className="flex items-center justify-end">
                  <div className="text-sm">
                    <button
                      type="button"
                      className="font-medium hover:underline focus:underline"
                      onClick={handleForgotPassword}
                    >
                      Reset or create password
                    </button>
                  </div>
                  <span>
                    <FontAwesomeIcon
                      data-tip
                      data-for="resetPasswordTooltip"
                      icon={faQuestionCircle}
                      className="relative cursor-pointer px-2 h-5 pt-1"
                    />
                    <ReactTooltip
                      id="resetPasswordTooltip"
                      effect="solid"
                      type="light"
                      place="bottom"
                      border={true}
                      borderColor="#6BA2B3"
                      className="opacity-100 !rounded-xl "
                    >
                      <p className="font-sans-serif my-1 text-left text-sm">
                        <span>
                          Use this option to create a new password <br /> or reset your existing one
                        </span>{' '}
                      </p>
                    </ReactTooltip>
                  </span>
                </div>

                <div className="flex justify-center">
                  <button
                    className={loading ? 'btn-cta' : 'btn-cta scale-animation'}
                    data-testid="signin"
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? 'Signing in...' : 'Sign in'}
                  </button>
                </div>
                {awsError && <div className="text-sm text-[#FF220C]">{awsError}</div>}
              </form>
            )}
            {showCodeSentMessage && (
              <div>
                <div className="mb-8">
                  <ResetPasswordBanner />
                </div>
                <div className="flex flex-col justify-center">
                  <button
                    type="button"
                    className="hover:underline focus:underline"
                    onClick={() => {
                      navigate('/')
                    }}
                  >
                    &larr; Go back to homepage
                  </button>
                </div>
              </div>
            )}
            {showForgotPasswordForm && (
              <form className="relative space-y-8">
                <div className="relative flex w-full flex-row items-center">
                  <input
                    date-testid="email"
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className={`peer block w-full appearance-none rounded-md border pt-6 placeholder-gray-400 shadow-sm focus:outline-none focus:ring-0 sm:text-sm ${
                      forgotPasswordErrors && forgotPasswordErrors.email
                        ? 'border-2 border-[#F58C82] focus:border-[#F58C82]'
                        : 'border-gray-300 focus:border-2 focus:border-secondary'
                    }`}
                    onChange={e => setEmail(e.target.value)}
                  />
                  <label
                    htmlFor="email"
                    className={`absolute top-0 inline-flex h-full transform cursor-text appearance-none items-center duration-300 peer-focus:-ml-3 peer-focus:-translate-y-3 peer-focus:scale-75 ${
                      email ? '-ml-3 -translate-y-3 scale-75' : 'ml-4'
                    }`}
                  >
                    Enter your email address
                  </label>
                </div>

                {forgotPasswordErrors && forgotPasswordErrors.email && (
                  <div className="text-sm text-[#FF220C]">{forgotPasswordErrors.email}</div>
                )}
                {forgotPasswordErrorsMessage && (
                  <div className="text-sm text-[#FF220C]">{forgotPasswordErrorsMessage}</div>
                )}
                <div className="flex flex-col justify-center">
                  <div className="flex justify-center">
                    <button
                      type="submit"
                      className={`${isSubmittingForgotPassword ? 'opacity-50' : ''} btn-cta mb-8`}
                      disabled={isSubmittingForgotPassword}
                      onClick={handleForgotPasswordSubmit}
                    >
                      Reset password
                    </button>
                  </div>
                  <button
                    className="hover:underline focus:underline"
                    type="button"
                    onClick={() => {
                      setShowSignInForm(true)
                      setShowForgotPasswordForm(false)
                    }}
                  >
                    &larr; Go back to login
                  </button>
                </div>
              </form>
            )}
            {showResetPasswordForm && (
              <Formik
                initialValues={{
                  password: password,
                  confirmPassword: '',
                  code: code
                }}
                validationSchema={resetPasswordSchema}
                onSubmit={values => handleResetPasswordSubmit(values)}
              >
                {props => (
                  <Form className="relative space-y-4">
                    <div>
                      <div className="relative flex w-full flex-row items-center">
                        <Field
                          id="password"
                          name="password"
                          type={isPasswordVisible ? 'text' : 'password'}
                          autoComplete="current-password"
                          className={`peer block w-full appearance-none rounded-md border pt-6 placeholder-gray-400 shadow-sm focus:outline-none focus:ring-0 sm:text-sm ${
                            resetPasswordErrors && resetPasswordErrors.password
                              ? 'border-2 border-[#F58C82] focus:border-[#F58C82]'
                              : 'border-gray-300 focus:border-2 focus:border-secondary'
                          }`}
                          onFocus={() => setIsFocus(true)}
                        />
                        <label
                          htmlFor="password"
                          className={`absolute top-0 inline-flex h-full transform cursor-text appearance-none items-center duration-300 peer-focus:ml-0 peer-focus:-translate-y-3 peer-focus:scale-75 ${
                            props.values.password ? 'ml-0 -translate-y-3 scale-75' : 'ml-4'
                          }`}
                        >
                          New password
                        </label>
                        <FontAwesomeIcon
                          icon={isPasswordVisible ? faEyeSlash : faEye}
                          className="absolute right-4 top-1/2 -translate-y-1/2 cursor-pointer"
                          onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                        />
                      </div>
                      {!isFocus && (
                        <span className="my-1 block text-xs font-medium text-gray-400">
                          Password must contain upper and lower case letters and numbers{' '}
                        </span>
                      )}
                    </div>
                    {props.errors.password && props.touched.password && (
                      <div className="text-xs text-[#F58C82]">{props.errors.password}</div>
                    )}
                    {isFocus && <PassValid value={props.values.password} />}

                    <div>
                      <div className="relative flex w-full flex-row items-center">
                        <Field
                          id="confirmPassword"
                          name="confirmPassword"
                          type={isConfirmPasswordVisible ? 'text' : 'password'}
                          autoComplete="current-password"
                          className={`peer block w-full appearance-none rounded-md border pt-6 placeholder-gray-400 shadow-sm focus:outline-none focus:ring-0 sm:text-sm ${
                            resetPasswordErrors && resetPasswordErrors.password
                              ? 'border-2 border-[#F58C82] focus:border-[#F58C82]'
                              : 'border-gray-300 focus:border-2 focus:border-secondary'
                          }`}
                        />
                        <label
                          htmlFor="confirmPassword"
                          className={`absolute top-0 inline-flex h-full transform cursor-text appearance-none items-center duration-300 peer-focus:-ml-2 peer-focus:-translate-y-3 peer-focus:scale-75 ${
                            props.values.confirmPassword ? '-ml-2 -translate-y-3 scale-75' : 'ml-4'
                          }`}
                        >
                          Confirm new password
                        </label>
                        <FontAwesomeIcon
                          icon={isConfirmPasswordVisible ? faEyeSlash : faEye}
                          className="absolute right-4 top-1/2 -translate-y-1/2 cursor-pointer"
                          onClick={() => setIsConfirmPasswordVisible(!isConfirmPasswordVisible)}
                        />
                      </div>
                    </div>
                    {props.errors.confirmPassword && props.touched.confirmPassword && (
                      <div className="text-xs text-[#F58C82]">{props.errors.confirmPassword}</div>
                    )}
                    {showCode && (
                      <div>
                        <div className="relative flex w-full flex-row items-center">
                          <Field
                            id="code"
                            name="code"
                            type="number"
                            required
                            className={`peer block w-full appearance-none rounded-md border pt-6 placeholder-gray-400 shadow-sm focus:outline-none focus:ring-0 sm:text-sm ${
                              props.errors.code
                                ? 'border-2 border-[#F58C82] focus:border-[#F58C82]'
                                : 'border-gray-300 focus:border-2 focus:border-secondary'
                            }`}
                          />
                          <label
                            htmlFor="code"
                            className={`absolute top-0 inline-flex h-full transform cursor-text appearance-none items-center duration-300 peer-focus:ml-2 peer-focus:-translate-y-3 peer-focus:scale-75 ${
                              props.values.code ? 'ml-2 -translate-y-3 scale-75' : 'ml-4'
                            }`}
                          >
                            Code
                          </label>
                        </div>
                      </div>
                    )}
                    {props.errors.code && props.touched.code && (
                      <div className="text-xs text-[#F58C82]">{props.errors.code}</div>
                    )}
                    <div className="flex flex-row justify-center">
                      <div>
                        <button type="submit" className="btn-cta">
                          Set password
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            )}
            <ErrorToast
              showToast={errorToast}
              msg={'Password reset failed, please click here and try again'}
              handleOnClick={redirectToSignForm}
              handleSetShowToast={handleSetErrorToast}
            />
            <Toast showToast={showToast} msg={'Password succesfully updated'} handleSetShowToast={handleSetShowToast} />
          </div>
        </div>
        <div className="flex w-full flex-row justify-center">
          <StaticImage
            className={`w-full md:w-1/2 lg:max-w-[1024px]`}
            src="../../../../images/crumble.png"
            alt="Crumble"
          />
        </div>
      </div>
    </>
  )
}

export default SignIn
