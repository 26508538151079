import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dialog, Transition } from '@headlessui/react'
import ErrorModal from 'components/ui/modal/ErrorModal'
import dayjs from 'dayjs'
import { Field, Form, Formik } from 'formik'
import { navigate } from 'gatsby'
import React, { Fragment, useRef, useState, useEffect } from 'react'
import { CalendarContainer } from 'react-datepicker'
import { DatePicker } from 'react-formik-ui'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'state'
import { updatePlanRadio } from 'state/actions/account/accountDogAction'
import { getPlanSchedule, postReschedulePlan } from 'state/actions/account/accountPlanAction'
import { createDogsExistingPlan } from 'state/actions/account/flow/dogAction'
import { fetchPlanSchedule } from 'state/apis/account/planApi'

export default function ResumePlanModal({
  show,
  setShow,
  subscriptionId,
  status,
  dogs = [],
  setShowRedZoneModal,
  redZone
}) {
  const cancelButtonRef = useRef(null)
  const [showReschedule, setShowReschedule] = useState(false)
  const [updatePlan, setUpdatePlan] = useState(false)
  const dispatch = useDispatch()
  const { changed, error } = useSelector((state: RootState) => state.accountDogFlow)
  const {
    postReschedulePlanStatus,
    errorMessage = false,
    planFailError
  } = useSelector((state: RootState) => state.accountPlan)
  const { spoofMode, spoofedUserId } = useSelector((state: RootState) => state.ui)
  const [showPopup, setShowPopup] = useState(false)
  const [activeDeliveryPrice, setActiveDeliveryPrice] = useState(0)
  var formikRef = useRef<any>()

  useEffect(() => {
    if (changed) {
      const url = spoofMode ? `/account/plan-recipes/?userId=` + spoofedUserId : '/account/plan-recipes/'
      navigate(url)
    }
  }, [changed])

  const [shippingDates, setShippingDates] = useState([])

  useEffect(() => {
    const getRescheduleDates = async () => {
      const dates = await fetchPlanSchedule(subscriptionId)
      setShippingDates(dates.shippingDates)
    }

    if (subscriptionId) {
      getRescheduleDates()
    }
  }, [])

  useEffect(() => {
    if (postReschedulePlanStatus && postReschedulePlanStatus === 'success') {
      const url = spoofMode ? `/account/plan/?userId=` + spoofedUserId : '/account/plan/'
      navigate(url)
    } else if (postReschedulePlanStatus && postReschedulePlanStatus === 'error') console.log('Error')
  }, [postReschedulePlanStatus])

  useEffect(() => {
    if (planFailError || error || errorMessage) {
      setShowPopup(true)
    } else {
      setShowPopup(false)
    }
  }, [planFailError, error, errorMessage])

  useEffect(() => {
    if (formikRef?.current?.values?.shippingDates) {
      const selectedDate: any[] = shippingDates?.filter(
        (dateFromShippingDates: any) =>
          dayjs(dateFromShippingDates.date).format('DD/MM/YYYY') ===
          dayjs(formikRef?.current?.values?.shippingDates).format('DD/MM/YYYY')
      )

      if (selectedDate.length === 1) {
        setActiveDeliveryPrice(selectedDate[0].price)
      }
    }
  }, [formikRef?.current?.values?.shippingDates])

  return (
    <Transition.Root show={show} as={'div'}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={() => setShow(false)}
      >
        <div className="flex min-h-screen items-center justify-center px-4 pt-4 pb-20 text-center sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block w-full max-w-xl transform rounded-3xl bg-white p-4 text-left align-middle shadow-xl transition-all sm:my-8 md:p-8">
              <div>
                <FontAwesomeIcon
                  icon={faClose}
                  fontSize={24}
                  className="absolute top-4 right-4 cursor-pointer md:top-8 md:right-8"
                  onClick={() => setShow(false)}
                />
                <div>
                  <div>
                    <h3 className="mb-2 text-center">Resume Plan</h3>
                    {!showReschedule && (
                      <div className="mb-2">
                        <p>Follow the instructions to optionally update your plan and select a new delivery date</p>
                      </div>
                    )}
                    <Formik
                      initialValues={{
                        shippingDates: null
                      }}
                      onSubmit={async (formikValues, { setSubmitting }) => {
                        setSubmitting(true)
                        if (redZone) {
                          setShowRedZoneModal(true)
                          setSubmitting(false)
                          return
                        }
                        if (status === 'Paused' && updatePlan) {
                          await dispatch(updatePlanRadio(subscriptionId))
                          await dispatch(
                            createDogsExistingPlan({
                              subscriptionId,
                              dogs
                            })
                          )
                          setSubmitting(false)
                        }

                        if (status === 'Cancelled' && updatePlan) {
                          await dispatch(updatePlanRadio(subscriptionId))
                          await dispatch(
                            createDogsExistingPlan({
                              subscriptionId,
                              dogs
                            })
                          )
                          setSubmitting(false)
                        }

                        if (formikValues.shippingDates && !updatePlan) {
                          await dispatch(
                            postReschedulePlan({
                              subscriptionId,
                              nextDelivery: dayjs(formikValues.shippingDates).format('YYYY-MM-DD')
                            })
                          )
                          setSubmitting(false)
                        }
                      }}
                    >
                      {props => {
                        return (
                          <Form>
                            {showReschedule && (
                              <div className="mb-4">
                                {/* @ts-ignore This library has some type conflicts with one of its dependency */}
                                <DatePicker
                                  name="shippingDates"
                                  disabled={shippingDates?.length === 0}
                                  label="Select New Delivery Date"
                                  placeholder="Please select delivery date"
                                  calendarStartDay={1}
                                  dateFormat="dd/MM/yyyy"
                                  includeDates={shippingDates.map(date => new Date(date.date))}
                                  className="block w-full rounded-md border border-gray-300 bg-white py-3 px-4 text-left focus:border-highlight focus:ring-highlight"
                                  inputmode="none"
                                  onFocus={(e: any) => e.target.blur()}
                                  dayClassName={(date: any) => {
                                    const dateObj = new Date(date)
                                    const validDate = shippingDates?.filter(
                                      dateFromShippingDates =>
                                        dayjs(dateFromShippingDates.date).format('DD/MM/YYYY') ===
                                        dayjs(date).format('DD/MM/YYYY')
                                    )

                                    if (validDate.length === 1 && dateObj.getDay() === 6) {
                                      return '!text-red-500'
                                    } else if (validDate.length === 0 && dateObj.getDay() === 6) {
                                      return '!text-red-300'
                                    } else {
                                      return undefined
                                    }
                                  }}
                                  calendarContainer={({ className, children }) => (
                                    <div>
                                      <CalendarContainer className={className}>
                                        <div style={{ position: 'relative' }}>{children}</div>
                                        <div className="p-2 pt-0">
                                          <span className="flex w-full font-bold">
                                            {activeDeliveryPrice === 0 && 'Free delivery'}
                                            {activeDeliveryPrice === 3.99 && 'Weekday delivery'}
                                          </span>
                                          <span className="flex w-full font-bold text-red-400">
                                            £4.99 - Saturday delivery
                                          </span>
                                        </div>
                                      </CalendarContainer>
                                    </div>
                                  )}
                                />
                                {props.errors.shippingDates && props.touched.shippingDates ? (
                                  <div className="text-xs text-red-500">{props.errors.shippingDates}</div>
                                ) : null}
                              </div>
                            )}
                            {!showReschedule && (
                              <div className="mx-auto grid grid-cols-3">
                                <div className="col-start-2 inline-flex justify-center">
                                  <button
                                    type="button"
                                    onClick={() => setShow(false)}
                                    disabled={props.isSubmitting}
                                    className="btn-secondary"
                                  >
                                    Cancel
                                  </button>
                                </div>
                                <div className="col-start-3 flex justify-end">
                                  <button
                                    data-testid="updatePlan"
                                    type="submit"
                                    onClick={() => {
                                      if (typeof window.gtag !== 'undefined') {
                                        window.gtag('event', 'select_resume_plan', {
                                          page: 'modal_resume_plan',
                                          subscription_id: subscriptionId
                                        })
                                      }

                                      if (redZone) {
                                        setShowRedZoneModal(true)
                                      } else {
                                        setUpdatePlan(true)
                                      }
                                    }}
                                    disabled={props.isSubmitting}
                                    className={`${updatePlan ? 'opacity-50' : ''} btn-cta`}
                                  >
                                    Resume plan
                                  </button>
                                </div>
                              </div>
                            )}
                            {showReschedule && (
                              <div className="mx-auto grid grid-cols-3">
                                <div className="col-start-2 inline-flex justify-center">
                                  <button
                                    type="button"
                                    className="btn-secondary"
                                    onClick={() => {
                                      setShowReschedule(false)
                                    }}
                                  >
                                    Back
                                  </button>
                                </div>
                                <div className="col-start-3 flex justify-end">
                                  <button
                                    type="submit"
                                    className="btn-cta"
                                    onClick={() => {
                                      if (typeof window.gtag !== 'undefined') {
                                        window.gtag('event', 'confirm_resume_plan', {
                                          page: 'modal_resume_plan',
                                          subscription_id: subscriptionId
                                        })
                                      }
                                    }}
                                    disabled={props.isSubmitting}
                                  >
                                    Confirm
                                  </button>
                                </div>
                              </div>
                            )}
                          </Form>
                        )
                      }}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
      {(errorMessage || planFailError?.popup || error) && (
        <ErrorModal
          show={showPopup}
          setShow={setShowPopup}
          errorMessage={errorMessage?.popup?.message || planFailError?.popup?.message || errorMessage?.errorMessage}
          url={errorMessage?.popup?.url || planFailError?.popup?.url}
          action={errorMessage?.popup?.action || planFailError?.popup?.action}
          actionText={errorMessage?.popup?.actionText || planFailError?.popup?.actionText}
        />
      )}
    </Transition.Root>
  )
}
