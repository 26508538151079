import { useLocation } from '@reach/router'
import { Cache } from 'aws-amplify'
import ErrorToast from 'components/ui/toast/ErrorToast'
import Toast from 'components/ui/toast/Toast'
import { getVisitorId } from 'components/util/UserId'
import dayjs from 'dayjs'
import { Field, Form, Formik } from 'formik'
import { StaticImage } from 'gatsby-plugin-image'
import React, { useEffect, useState } from 'react'
import Api from 'state/apis/api'
import * as yup from 'yup'

const FeedbackFormAlternative = ({
  name,
  email,
  isNewCustomer,
  orders
}: { name: string; email: string; isNewCustomer: boolean; orders: any }) => {
  const [showTab, setShowTab] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showForm, setShowForm] = useState(false)
  const [isSubmitting, setSubmitting] = useState(false)
  const [mobileMessageRows, setMobileMessageRows] = useState(3)
  const [showToast, setShowToast] = useState(false)
  const [showError, setErrorToast] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [visitorId, setVisitorId] = useState('')
  const location = useLocation()

  // Show feedback form to 10% of customers
  useEffect(() => {
    const customerAccountFeedbackFormComplete: string =
      Cache.getItem('_customer_account_feedback_form_complete') ?? null
    // Filter for users with more than one order
    if (orders && orders?.length && !customerAccountFeedbackFormComplete) {
      const randomNumber = Math.random()
      const show = randomNumber <= 0.1 ? true : false
      setShowTab(show)
    }
  }, [orders])

  useEffect(() => {
    let isMounted = true
    const getUserId = async () => {
      if (isMounted) {
        setVisitorId(await getVisitorId())
      }
    }
    getUserId()
    return () => {
      isMounted = false
    }
  }, [])

  useEffect(() => {
    const windowWidth = window?.innerWidth
    if (windowWidth > 400) {
      setMobileMessageRows(5)
    }
  }, [])

  const handleSetShowToast = (show: boolean) => {
    setShowToast(show)
  }

  const handleSetErrorToast = (show: boolean) => {
    setErrorToast(show)
  }

  return (
    <>
      {showTab && (
        <div className="fixed bottom-16 right-0 z-50 w-10">
          <div className="flex h-[7.5rem] rounded-l-2xl bg-white drop-shadow-lg">
            <button
              className="inline-block transform -rotate-90 absolute -right-6 bottom-12 text-base font-sans font-bold"
              type="button"
              onClick={() => {
                setShowTab(false)
                setShowModal(true)
              }}
            >
              Feedback
            </button>
          </div>
        </div>
      )}
      <div
        className={`fixed right-0  bottom-8 z-50 w-full lg:w-1/2 transition-transform duration-300 ${
          !showTab ? 'transform translate-x-0' : 'transform translate-x-full'
        }`}
      >
        {showModal && (
          <div className="fixed bottom-4 right-4 w-11/12 mx-auto transform rounded-3xl bg-primary text-left align-bottom transition-all sm:my-8 md:w-full md:max-w-[25rem] md:align-middle z-20">
            <div className="flex justify-center items-center my-4">
              <span className="font-sans font-bold text-white text-xl">We’d love your feedback!</span>
            </div>

            <div className="px-8">
              <div className="w-full pb-4 text-center">
                <div className="block text-sm font-medium text-white mb-4 font-sans">
                  <p>
                    Tell us what you think about your experience on our website,
                    {isNewCustomer
                      ? ' including how easy you found our sign up process.'
                      : ' including how easy you are able to manage your subscription.'}
                  </p>
                </div>

                <button
                  type="button"
                  className="btn-cta !bg-white !text-base font-sans !font-bold my-4 w-10/12"
                  onClick={() => {
                    setShowForm(true)
                    setShowModal(false)
                  }}
                >
                  Give feedback
                </button>
              </div>
              <div className="mx-auto mb-8 grid grid-cols-3">
                <div className="col-start-2 inline-flex justify-center">
                  <button
                    onClick={() => {
                      setShowModal(false)
                      setShowTab(true)
                    }}
                    className="btn-secondary text-white font-sans font-semibold underline"
                    style={{ textUnderlineOffset: '8px' }}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {showForm && (
          <div className="fixed bottom-4 right-4 w-11/12 mx-auto transform rounded-3xl bg-white text-left align-bottom transition-all sm:my-8 md:w-full md:max-w-[30rem] md:align-middle z-20 border-2">
            <div className="flex justify-center items-center mt-4">
              <span className="font-sans font-bold text-base lg:text-lg text-center lg:px-2">
                How would you rate <br /> your experience on differentdog.com?
              </span>
            </div>

            <div className="flex flex-col w-full h-68 p-4 pb-6">
              <Formik
                initialValues={{
                  name: name,
                  email: email,
                  rating: '',
                  message: ''
                }}
                onSubmit={(values, { resetForm }) => {
                  setSubmitting(true)
                  const url = '/v1/private/account/feedback'
                  const { name = '', email = '', rating, message } = values
                  if (!message) {
                    setErrorMessage('Please enter message')
                    setSubmitting(false)
                  } else {
                    Api.post('wwwREST', url, {
                      body: {
                        name,
                        email,
                        rating,
                        feedback: message,
                        visitorId: visitorId,
                        path: location?.pathname
                      }
                    })
                      .then((_res: any) => {
                        setShowToast(true)
                        setSubmitting(false)
                        setShowForm(false)
                        resetForm()
                        setShowTab(true)
                      })
                      .catch((err: any) => {
                        setErrorToast(true)
                        setSubmitting(false)
                        setErrorToast(false)
                        console.log('Error submitting feedback')
                        console.error(JSON.stringify(err, null, 2))
                      })
                    // Set cache feedback form completed
                    const expiration = dayjs().add(30, 'day')
                    Cache.setItem('_customer_account_feedback_form_complete', true, {
                      expires: expiration.toDate().getTime()
                    })
                  }
                }}
              >
                {props => (
                  <Form className="">
                    <div className="w-full lg:w-11/12 mx-auto">
                      <div className="flex items-center justify-between w-full lg:px-10">
                        <span className="text-left font-sans text-sm font-medium lg:text-base">Not great</span>

                        <div className="flex justify-between w-1/2 mx-4">
                          <label
                            tabIndex={0}
                            onClick={() => {
                              props.setFieldValue('rating', 'NOTGREAT')
                            }}
                            className={`flex flex-col items-center justify-center cursor-pointer`}
                          >
                            <Field type="radio" name="rating" value="NOTGREAT" className="hidden" />
                            {props.values.rating === 'NOTGREAT' ? (
                              <StaticImage
                                objectFit={'contain'}
                                className="h-8 w-full"
                                src="../../../images/ui-components/feedbackNotGreatActive.svg"
                                alt="Sad face"
                              />
                            ) : (
                              <StaticImage
                                objectFit={'contain'}
                                className="h-8 w-full"
                                src="../../../images/ui-components/feedbackNotGreat.svg"
                                alt="Happy face"
                              />
                            )}
                          </label>

                          <label
                            tabIndex={0}
                            onClick={() => {
                              props.setFieldValue('rating', 'NEUTRAL')
                            }}
                            className={`flex flex-col items-center justify-center cursor-pointer`}
                          >
                            <Field type="radio" name="rating" value="NEUTRAL" className="hidden" />
                            {props.values.rating === 'NEUTRAL' ? (
                              <StaticImage
                                objectFit={'contain'}
                                className="h-8 w-full"
                                src="../../../images/ui-components/feedbackNeutralActive.svg"
                                alt="Neutral face"
                              />
                            ) : (
                              <StaticImage
                                objectFit={'contain'}
                                className="h-8 w-full"
                                src="../../../images/ui-components/feedbackNeutral.svg"
                                alt="Neutral face"
                              />
                            )}
                          </label>

                          <label
                            tabIndex={0}
                            onClick={() => {
                              props.setFieldValue('rating', 'GREAT')
                            }}
                            className={`flex flex-col items-center justify-center cursor-pointer`}
                          >
                            <Field type="radio" name="rating" value="GREAT" className="hidden" />
                            {props.values.rating === 'GREAT' ? (
                              <StaticImage
                                objectFit={'contain'}
                                className="h-8 w-full"
                                src="../../../images/ui-components/feedbackGreatActive.svg"
                                alt="Sad face"
                              />
                            ) : (
                              <StaticImage
                                objectFit={'contain'}
                                className="h-8 w-full"
                                src="../../../images/ui-components/feedbackGreat.svg"
                                alt="Happy face"
                              />
                            )}
                          </label>
                        </div>

                        <span className="text-left font-sans font-medium text-sm lg:text-base">Great</span>
                      </div>

                      <div className="flex w-full justify-center items-center mx-auto my-4">
                        <p className="font-medium text-sans text-sm lg:text-base text-center px-2">
                          Please tell us a bit more about how you feel about your time on our website.
                        </p>
                      </div>
                      <Field
                        className="w-full rounded-xl border-[#B0CDD5] focus:border-[#B0CDD5] focus:outline-none focus:ring-[#B0CDD5]"
                        name="message"
                        as="textarea"
                        placeholder="Start typing here..."
                        rows={mobileMessageRows}
                        onChange={e => {
                          props.handleChange(e)
                          setErrorMessage('')
                        }}
                      />
                    </div>
                    {errorMessage && !props.message && (
                      <div className="text-center text-sm text-red-500 mb-2">{errorMessage}</div>
                    )}
                    <div className="mx-auto flex w-max flex-col items-center justify-center">
                      <button
                        type="submit"
                        className={`${!props.values.message || isSubmitting ? 'opacity-50' : ''} btn-cta mb-4 w-full max-w-[385px] !px-16 mt-4`}
                      >
                        Send
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
              <button
                onClick={() => {
                  setShowForm(false)
                  setShowTab(true)
                }}
                className="text-base font-sans underline"
                style={{ textUnderlineOffset: '8px' }}
              >
                Close
              </button>
            </div>
          </div>
        )}
      </div>

      <ErrorToast
        showToast={showError}
        msg={'There has been a problem submitting your feedback, please try again.'}
        handleSetShowToast={handleSetErrorToast}
        handleOnClick={() => {}}
      />
      <Toast showToast={showToast} msg={'Thank you for your feedback'} handleSetShowToast={handleSetShowToast} />
    </>
  )
}

export default FeedbackFormAlternative
