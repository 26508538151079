import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { values } from 'cypress/types/lodash'
import { useFormikContext } from 'formik'
import React from 'react'
import { CardElement, ElementState } from '../../util/stripe-custom/index'

interface CheckoutType {
  errorMessage: string | undefined

  onFocus?: () => void
}

const Checkout: React.FC<CheckoutType> = ({ errorMessage, onFocus }) => {
  const { errors, touched, values } = useFormikContext<{
    'card-element': ElementState

    cardNumberElement: ElementState
  }>()
  return (
    <div>
      <div className="flex flex-row items-center">
        <div className="flex w-full flex-col rounded-md border border-gray-300 bg-white p-4 text-center focus:border-highlight focus:ring-highlight">
          <div>
            <CardElement
              name="card-element"
              className="w-full"
              options={{
                hidePostalCode: true,

                style: {
                  base: {
                    fontSize: '16px',

                    fontFamily: 'sans-serif',

                    color: '#004355',

                    '::placeholder': {
                      color: '#004355'
                    }
                  },

                  invalid: {
                    color: '#fa755a'
                  }
                }
              }}
              onFocus={onFocus}
            />
          </div>
        </div>
        {/*process.env.USER_BRANCH !== 'production' && (
          <div>
            <p>Test successful payment - 4242424242424242</p>
            <p>Generic Card declined - 4000000000000002</p>
            <p>Insufficient funds - 4000008260003178</p>
            <p>3DS - 4000002500003155</p>
            <p>Test cards - https://stripe.com/docs/testing</p>
          </div>
        )}*/}
        {/* {errorMessage && <div className="card-validation text-xs text-red-500">{errorMessage}</div>} */}
      </div>
    </div>
  )
}

export default Checkout
