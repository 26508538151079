import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dialog, Transition } from '@headlessui/react'
import { navigate } from '@reach/router'
import React, { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import EditFutureOrderModal from './EditFutureOrderModal'

export default function SelectPlanAddTreatModal({ show, setShow, plans, setSelectedPlan }) {
  const { spoofMode, spoofedUserId } = useSelector((state: RootState) => state.ui)
  const [subscriptionId, setSubscriptionId] = useState('')
  const [redZoneConfirmButton, setRedZoneConfirmButton] = useState(<></>)
  const [redZoneModalShow, setRedZoneModalShow] = useState(false)

  useEffect(() => {
    if (subscriptionId) {
      setSelectedPlan(subscriptionId)
    }
  }, [subscriptionId])

  return (
    <>
      {plans?.length === 0 && (
        <Transition.Root show={show} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-50 overflow-y-auto"
            onClose={() => {
              setShow(false)
              navigate('/account/plan/')
            }}
          >
            <div className="flex min-h-screen items-center justify-center px-4 pt-4 pb-20 text-center sm:p-0">
              <Transition.Child
                as={'div'}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span className="hidden align-middle sm:inline-block sm:h-full" aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                as={'div'}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                className={'w-full'}
              >
                <div className="relative inline-block w-full max-w-xl transform rounded-3xl bg-white p-4 text-left align-middle shadow-xl transition-all sm:my-8 sm:p-8">
                  <div
                    className={`absolute top-0 right-0 flex h-12 w-12 cursor-pointer flex-col items-center justify-center p-2 md:top-4 md:right-4`}
                    onClick={() => {
                      setShow(false)
                      navigate('/account/plan/')
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faClose}
                      fontSize={24}
                      onClick={() => {
                        setShow(false)
                        navigate('/account/plan/')
                      }}
                    />
                  </div>
                  {plans?.length === 0 && (
                    <>
                      <Dialog.Title as="h3" className="mb-2 text-center text-lg font-medium leading-6">
                        No active plans available
                      </Dialog.Title>
                      <div className="max-h-ful my-8 flex max-w-full flex-col gap-y-3">
                        <div className="justify-between text-center">
                          <div className="flex-shrink-0">
                            <div className="hidden lg:block">
                              <button
                                type="button"
                                className="btn-cta mt-4 w-full max-w-xs mx-auto items-center justify-center gap-3 rounded-full border-2 border-primary bg-transparent py-2 px-4 text-lg text-base font-medium drop-shadow-lg hover:bg-base hover:text-white focus:border-white focus:outline-secondary disabled:opacity-50 lg:mt-8"
                                onClick={() => {
                                  const url = spoofMode ? `/account/plan/?userId=` + spoofedUserId : `/account/plan/`
                                  navigate(url)
                                }}
                              >
                                Continue to Plan page
                              </button>
                            </div>
                            <div className="block lg:hidden">
                              <button
                                type="button"
                                className="btn-cta mt-4 w-5/6 max-w-xs items-center justify-center gap-3 rounded-full border-2 border-primary bg-transparent py-2 px-4 text-lg text-base font-medium drop-shadow-lg hover:bg-base hover:text-white focus:border-white focus:outline-secondary disabled:opacity-50 lg:mt-8"
                                onClick={() => {
                                  const url = spoofMode ? `/account/plan/?userId=` + spoofedUserId : `/account/plan/`
                                  navigate(url)
                                }}
                              >
                                Go to Plan page
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </Transition.Child>
            </div>
            <EditFutureOrderModal
              show={redZoneModalShow}
              setShow={setRedZoneModalShow}
              confirmButton={redZoneConfirmButton}
            />
          </Dialog>
        </Transition.Root>
      )}
      {plans?.length >= 1 && (
        <Transition.Root show={show} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-50 overflow-y-auto"
            onClose={() => {
              setShow(false)
              navigate('/account/plan/')
            }}
          >
            <div className="flex min-h-screen items-center justify-center px-4 pt-4 pb-20 text-center sm:p-0">
              <Transition.Child
                as={'div'}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span className="hidden align-middle sm:inline-block sm:h-full" aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                as={'div'}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                className={'w-full'}
              >
                <div className="relative inline-block w-full max-w-xl transform rounded-3xl bg-white p-4 text-left align-middle shadow-xl transition-all sm:my-8 sm:p-8">
                  <div
                    className={`absolute top-0 right-0 flex h-12 w-12 cursor-pointer flex-col items-center justify-center p-2 md:top-4 md:right-4`}
                    onClick={() => {
                      setShow(false)
                      navigate('/account/plan/')
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faClose}
                      fontSize={24}
                      onClick={() => {
                        setShow(false)
                        navigate('/account/plan/')
                      }}
                    />
                  </div>

                  <Dialog.Title as="h3" className="mb-2 text-center text-lg font-medium leading-6">
                    Select for which plan you want to add treats to
                  </Dialog.Title>
                  <div className="max-h-ful my-8 flex max-w-full flex-col gap-y-3">
                    {plans?.map((plan: any) => (
                      <div key={plan.subscriptionId} className="flex-shrink-0">
                        <div className="justify-between text-center">
                          <button
                            data-testid="editAddress"
                            className="btn-primary w-3/5 justify-center !border-primary !bg-transparent !px-6 !text-base hover:underline"
                            onClick={() => {
                              if (plan.redZone) {
                                setRedZoneConfirmButton(
                                  <button
                                    type="button"
                                    className="btn-cta inline-flex justify-center px-4 py-2 sm:col-start-1 sm:mt-0"
                                    onClick={() => {
                                      setRedZoneModalShow(false)
                                      setSubscriptionId(plan.subscriptionId)
                                    }}
                                  >
                                    Edit treats
                                  </button>
                                )
                                setRedZoneModalShow(true)
                              } else {
                                setSubscriptionId(plan.subscriptionId)
                              }
                            }}
                          >
                            {plan.dogNames}
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </Transition.Child>
            </div>
            <EditFutureOrderModal
              show={redZoneModalShow}
              setShow={setRedZoneModalShow}
              confirmButton={redZoneConfirmButton}
            />
          </Dialog>
        </Transition.Root>
      )}
    </>
  )
}
