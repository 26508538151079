import { faWarning } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ErrorModal from 'components/ui/modal/ErrorModal'
import * as dayjs from 'dayjs'
import { Link, navigate } from 'gatsby'
import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'state'
import { updatePlanRadio } from 'state/actions/account/accountDogAction'
import { createDogsExistingPlan } from 'state/actions/account/flow/dogAction'
import { prefillRecipes, updateChangedStatus } from 'state/actions/account/flow/planAction'
import Image from '../../../ui/image/StaticImage'
import EditAddressModal from '../../../ui/modal/EditAddressModal'
import EditFutureOrderModal from '../../../ui/modal/EditFutureOrderModal'
import EditPlanModal from '../../../ui/modal/EditPlanModal'
import ResumePlanModal from '../../../ui/modal/ResumePlanModal'
// import bgimg from "../../../images/plan-recipe.png"

interface PackInfo {
  quantityAndPackSize: string
  nextDelivery: string
  deliveryTo: string
  payment: string
  nextPaymentDate: string
  targetPaymentDate: number
}

interface AccordionItemProps {
  key: string
  dogs: []
  dogNames: string
  status: String
  packInfo: PackInfo
  isOpened: boolean
  redZone: boolean
  isInTrial: boolean
  plan: any
  nextPlan: any
  quantity: number
  setOpen: (val: any) => void
  showEditPlanModal: boolean
  setShowEditPlanModal: (val: boolean) => void
  showResumePlanModal: boolean
  setShowResumePlanModal: (val: boolean) => void
  showEditAddressModal: boolean
  setShowEditAddressModal: (val: boolean) => void
  showRescheduleModal: boolean
  setShowRescheduleModal: (val: boolean) => void
  showRedZoneModal: boolean
  setShowRedZoneModal: (val: boolean) => void
  redZoneConfirmButton: any
  setRedZoneConfirmButton: (val: any) => void
  subscriptionId: string
  // images: Array<any>
  isMultiplePlan: boolean
}

const AccordionItem: React.FC<AccordionItemProps> = ({
  key,
  dogs,
  dogNames,
  status,
  packInfo,
  isOpened = false,
  setOpen,
  showEditPlanModal,
  setShowEditPlanModal,
  showResumePlanModal,
  setShowResumePlanModal,
  showEditAddressModal,
  setShowEditAddressModal,
  showRescheduleModal,
  setShowRescheduleModal,
  showRedZoneModal,
  setShowRedZoneModal,
  redZoneConfirmButton,
  setRedZoneConfirmButton,
  subscriptionId,
  redZone,
  isInTrial,
  plan,
  nextPlan,
  quantity,
  isMultiplePlan
}) => {
  const [_collapseOpen, _setCollapseOpen] = useState(true)
  const [_rotate, _setRotate] = useState(isOpened)
  const [collapseStyle, _setCollapseStyle] = useState<undefined | number>(undefined)
  const [_animation, _setAnimation] = useState(false)
  const collapseRef = useRef<HTMLDivElement>(null)
  const { defaultPack, dogInfo, changed, error } = useSelector((state: RootState) => state.accountDogFlow)
  const [nextPage, setNextPage] = useState<string | undefined>(undefined)
  const dispatch = useDispatch()

  const [showPopup, setShowPopup] = useState(false)

  const { spoofMode, spoofedUserId } = useSelector((state: RootState) => state.ui)

  useEffect(() => {
    if (error) {
      setShowPopup(true)
    } else {
      setShowPopup(false)
    }
  }, [error])

  useEffect(() => {
    if (changed && nextPage === 'plan-recipes') {
      const url = spoofMode ? '/account/plan-recipes/?userId=' + spoofedUserId : '/account/plan-recipes/'
      navigate(url)
    }
    if (changed && nextPage === 'treats') {
      const url = spoofMode ? '/account/treats/?userId=' + spoofedUserId : '/account/treats/'
      navigate(url)
    }
    if (changed && nextPage === 'recipes') {
      const url = spoofMode ? '/account/recipes/?userId=' + spoofedUserId : '/account/recipes/'
      navigate(url)
      dispatch(prefillRecipes(packInfo?.recipes))
    }
    return () => {
      dispatch(updateChangedStatus(false))
    }
  }, [changed])

  useEffect(() => {
    if (window.location.pathname && window.location.pathname.includes('edit-delivery')) {
      if (status === 'Active' || status === 'Future') {
        if (isMultiplePlan) {
          const searchParams = new URLSearchParams(window.location.search)
          const subscriptionIdFromParams = searchParams.get('subscriptionId')

          if (subscriptionId === subscriptionIdFromParams) {
            setOpen(key)
            setShowRescheduleModal(true)
            setShowEditPlanModal(true)
          }
        } else {
          setShowRescheduleModal(true)
          setShowEditPlanModal(true)
        }
      }
    }
  }, [])

  const getTargetPaymentDateText = (targetPaymentDate: number) => {
    const lastTwoDigits = targetPaymentDate % 100
    const lastDigit = targetPaymentDate % 10
    let suffix = 'th'

    if (lastTwoDigits < 11 || lastTwoDigits > 13) {
      // Determine the suffix based on the last digit
      if (lastDigit === 1) {
        suffix = 'st'
      } else if (lastDigit === 2) {
        suffix = 'nd'
      } else if (lastDigit === 3) {
        suffix = 'rd'
      }
    }

    return `${targetPaymentDate}${suffix}`
  }

  return (
    <div className="flex h-full flex-grow flex-col items-stretch px-4 py-5 md:px-11 md:py-9" key={key}>
      <div className="bg-transparent">
        <div className="mb-2 flex flex-col items-center justify-start transition-all duration-300 ease-in-out">
          <div className="flex items-center">
            <span className="h3 text-2xl font-medium">{dogNames ? `${dogNames}'s Plan` : 'Plan'}</span>
          </div>{' '}
          <div className="flex items-center justify-between gap-2">
            <div className="mt-2 mb-6 flex items-center">
              <span className="mx-2 font-bold">Status:</span>
              <span
                data-testid="active"
                className={`mr-1 h-3 w-3 rounded-full ${
                  status === 'Active' || status === 'Future' ? 'bg-activeStatus' : 'bg-pauseCancelStatus'
                }`}
              ></span>
              <button
                className={`font-bold ${
                  status === 'Active' || status === 'Future' ? 'text-activeStatus' : 'text-pauseCancelStatus'
                } underline`}
                data-testid="editStatus"
                onClick={() => {
                  setOpen(key)
                  if (status === 'Active' || status === 'Future') {
                    if (typeof window.gtag !== 'undefined') {
                      window.gtag('event', 'clicked_edit_plan', {
                        page: 'account_plan'
                      })
                    }

                    if (redZone) {
                      setRedZoneConfirmButton(
                        <button
                          data-testid="editFutureStatus"
                          type="button"
                          className="btn-cta inline-flex justify-center px-4 py-2 sm:col-start-1 sm:mt-0"
                          onClick={() => {
                            setOpen(key)
                            setShowRedZoneModal(false)
                            setShowRescheduleModal(false)
                            setShowEditPlanModal(true)
                          }}
                        >
                          Edit status
                        </button>
                      )
                      setShowRedZoneModal(true)
                    } else {
                      setShowRescheduleModal(false)
                      setShowEditPlanModal(true)
                    }
                  } else if (status === 'Cancelled' || status === 'Paused') {
                    setOpen(key)
                    setShowResumePlanModal(true)
                    if (typeof window.gtag !== 'undefined') {
                      window.gtag('event', 'selected_resume_plan', {
                        page: 'account_plan'
                      })
                    }
                  } else {
                    if (typeof window.gtag !== 'undefined') {
                      window.gtag('event', 'selected_resume_plan', {
                        page: 'account_plan'
                      })
                    }
                  }
                }}
              >
                {status}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`transition-all duration-300 ease-in-out`}
        style={{
          height: collapseStyle
        }}
        ref={collapseRef}
      >
        <div className="relative flex-auto leading-relaxed">
          <div className="justify-center ">
            <div className="rounded-3xl bg-white text-center">
              <div className="mb-4 px-4 py-8">
                <p data-testid="planSummary" className="h3 text-xl font-medium">
                  Plan summary
                </p>
                <div className="mt-2 sm:mt-0">
                  {isInTrial ? (
                    <>
                      {quantity && plan && (
                        <p className="mt-2 text-base font-bold">
                          Your starter box contains {quantity} x {plan} packs
                        </p>
                      )}
                      {nextPlan && nextPlan.quantity && nextPlan.plan && (
                        <p className="mt-2 text-base font-bold">
                          Ongoing, it will be {nextPlan.quantity} x {nextPlan.plan} packs every {nextPlan.weeks} weeks
                        </p>
                      )}
                    </>
                  ) : (
                    <>
                      {(status === 'Cancelled' || status === 'Paused') && (
                        <p className="mt-2 text-base font-bold">Your dogs plan was {packInfo?.quantityAndPackSize}</p>
                      )}
                      {(status === 'Active' || status === 'Future') && (
                        <p className="mt-2 text-base font-bold">{packInfo?.quantityAndPackSize}</p>
                      )}
                    </>
                  )}
                </div>
                <div>
                  {(status === 'Active' || status === 'Future') && (
                    <div className="mt-4">
                      <button
                        data-testid="editPlan"
                        className="btn-primary justify-center !border-primary !bg-transparent !px-6 !text-base hover:underline"
                        onClick={() => {
                          setOpen(key)
                          if (typeof window.gtag !== 'undefined') {
                            window.gtag('event', 'clicked_edit_plan', {
                              page: 'account_plan'
                            })
                          }
                          if (redZone) {
                            setRedZoneConfirmButton(
                              <button
                                data-testid="editFuturePlan"
                                type="button"
                                className="btn-cta inline-flex justify-center px-4 py-2"
                                onClick={() => {
                                  setOpen(key)
                                  setNextPage('plan-recipes')
                                  setShowRedZoneModal(false)
                                  dispatch(updatePlanRadio(subscriptionId))
                                  dispatch(
                                    createDogsExistingPlan({
                                      subscriptionId,
                                      dogs
                                    })
                                  )
                                }}
                              >
                                Edit plan
                              </button>
                            )
                            setShowRedZoneModal(true)
                          } else {
                            setNextPage('plan-recipes')
                            dispatch(updatePlanRadio(subscriptionId))
                            dispatch(
                              createDogsExistingPlan({
                                subscriptionId,
                                dogs
                              })
                            )
                          }
                        }}
                      >
                        Edit plan
                      </button>
                    </div>
                  )}
                  {(status === 'Cancelled' || status === 'Paused') && (
                    <div className="mt-4">
                      <button
                        data-testid="reactivatePlan"
                        className="btn-cta justify-center px-6 text-primary hover:underline"
                        onClick={() => {
                          setOpen(key)
                          if (typeof window.gtag !== 'undefined') {
                            window.gtag('event', 'clicked_edit_plan', {
                              page: 'account_plan'
                            })
                          }
                          if (redZone) {
                            setRedZoneConfirmButton(
                              <button
                                data-testid="editFuturePlan"
                                type="button"
                                className="btn-cta inline-flex justify-center px-4 py-2"
                                onClick={() => {
                                  setOpen(key)
                                  setNextPage('plan-recipes')
                                  setShowRedZoneModal(false)
                                  dispatch(updatePlanRadio(subscriptionId))
                                  dispatch(
                                    createDogsExistingPlan({
                                      subscriptionId,
                                      dogs
                                    })
                                  )
                                }}
                              >
                                Edit plan
                              </button>
                            )
                            setShowRedZoneModal(true)
                          } else {
                            setNextPage('plan-recipes')
                            dispatch(updatePlanRadio(subscriptionId))
                            dispatch(
                              createDogsExistingPlan({
                                subscriptionId,
                                dogs
                              })
                            )
                          }
                        }}
                      >
                        Reactivate plan
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="relative flex-auto leading-relaxed">
          <div className="plan-banner h-28 w-full rounded-t-3xl bg-transparent bg-cover"></div>
          <div className="mx-auto mb-4 flex h-auto w-full flex-col overflow-hidden md:flex-row">
            <div className="md:rounded-br-0 mb-0 w-full bg-white py-2 md:mb-4 md:w-1/2 md:rounded-none md:rounded-bl-3xl md:pt-4 md:pb-4 ">
              <div className="flex flex-col items-center justify-between gap-2">
                {packInfo?.recipes && packInfo?.recipes?.length > 0 && (
                  <div className="h-auto w-full overflow-hidden px-6 py-2 md:pb-20">
                    <p className="h3 mb-2 hidden text-xl font-medium md:block">Your dog's menu</p>
                    <p className="h3 mb-2 block text-center text-xl font-medium md:hidden">
                      {dogNames ? `${dogNames}'s menu` : 'menu'}
                    </p>
                    {packInfo.recipes.map((recipe: any, i: number) => (
                      <div key={i} className="flex items-baseline gap-2">
                        <div className="my-auto h-4 w-4 flex-shrink-0">
                          <Image filename="BulletPoint.svg" alt={'Bullet point'} />
                        </div>
                        <p className="text-xs sm:text-sm">
                          {recipe.quantity !== 0 ? `${recipe.quantity} x ${recipe.name}` : recipe.name}
                          {recipe.range === 'health' && (
                            <span className="ml-2 rounded-lg bg-fourth px-2 text-xs font-normal">Health+</span>
                          )}
                        </p>
                      </div>
                    ))}
                  </div>
                )}
                {(status === 'Active' || status === 'Future') && (
                  <div
                    className={`flex-shrink-0 justify-center py-4 md:absolute ${
                      packInfo?.addons?.length === 0 ? 'bottom-8' : 'md:bottom-6'
                    }`}
                  >
                    <button
                      className="btn-primary justify-center !border-primary !bg-transparent !px-6 !text-base hover:underline"
                      onClick={() => {
                        if (typeof window.gtag !== 'undefined') {
                          window.gtag('event', 'clicked_edit_plan', {
                            page: 'account_plan'
                          })
                        }
                        if (redZone) {
                          setRedZoneConfirmButton(
                            <button
                              data-testid="editFuturePlan"
                              type="button"
                              className="btn-cta inline-flex justify-center px-4 py-2"
                              onClick={() => {
                                setOpen(key)
                                setNextPage('recipes')
                                setShowRedZoneModal(false)
                                dispatch(updatePlanRadio(subscriptionId))
                                dispatch(
                                  createDogsExistingPlan({
                                    subscriptionId,
                                    dogs
                                  })
                                )
                              }}
                            >
                              Edit recipes
                            </button>
                          )
                          setShowRedZoneModal(true)
                        } else {
                          setNextPage('recipes')
                          dispatch(updatePlanRadio(subscriptionId))
                          dispatch(
                            createDogsExistingPlan({
                              subscriptionId,
                              dogs
                            })
                          )
                        }
                      }}
                    >
                      Edit recipes
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className="md:rounded-bl-0 mb-0 w-full rounded-b-3xl bg-white py-2 md:mb-4 md:w-1/2 md:rounded-none md:rounded-br-3xl md:pt-4 md:pb-4 ">
              <div className="flex flex-col items-center justify-between gap-2">
                <div className="h-auto w-full overflow-hidden px-6 py-2 md:pb-20">
                  <p className="h3 mb-2 text-center text-xl font-medium md:text-left">Treats &amp; extras</p>
                  {packInfo.addons.map((addon: any) => (
                    <div key={addon.id} className="flex items-baseline gap-2">
                      <div className="my-auto h-4 w-4 flex-shrink-0">
                        <Image filename="BulletPoint.svg" alt={'Bullet point'} />
                      </div>
                      <p className="text-xs sm:text-sm">
                        {addon.quantity !== 0 ? `${addon.quantity} x ${addon.name}` : recipe.name}
                      </p>
                    </div>
                  ))}
                  {status !== 'Paused' && status !== 'Cancelled' && packInfo?.addons?.length === 0 && (
                    <span className="mb-2 text-sm font-bold">Explore our treats, broths and supplements</span>
                  )}
                  {(status === 'Paused' || status === 'Cancelled') && packInfo?.addons?.length === 0 && (
                    <span className="mb-2 text-sm">No treats &amp; extras</span>
                  )}
                </div>

                {(status === 'Active' || status === 'Future') && (
                  <div
                    className={`flex-shrink-0 justify-center py-4 md:absolute ${
                      packInfo?.addons?.length === 0 ? 'bottom-8' : 'md:bottom-6'
                    }`}
                  >
                    <button
                      className="btn-primary justify-center !border-primary !bg-transparent !px-6 !text-base hover:underline"
                      onClick={() => {
                        setOpen(key)
                        if (typeof window.gtag !== 'undefined') {
                          window.gtag('event', 'clicked_edit_plan', {
                            page: 'account_plan'
                          })
                        }
                        if (redZone) {
                          setRedZoneConfirmButton(
                            <button
                              data-testid="editFuturePlan"
                              type="button"
                              className="btn-cta inline-flex justify-center px-4 py-2"
                              onClick={() => {
                                setOpen(key)
                                setNextPage('treats')
                                setShowRedZoneModal(false)
                                dispatch(updatePlanRadio(subscriptionId))
                                dispatch(
                                  createDogsExistingPlan({
                                    subscriptionId,
                                    dogs
                                  })
                                )
                              }}
                            >
                              {packInfo?.addons?.length > 0 ? 'Edit' : 'Add'} treats
                            </button>
                          )
                          setShowRedZoneModal(true)
                        } else {
                          setNextPage('treats')
                          dispatch(updatePlanRadio(subscriptionId))
                          dispatch(
                            createDogsExistingPlan({
                              subscriptionId,
                              dogs
                            })
                          )
                        }
                      }}
                    >
                      {packInfo?.addons?.length > 0 ? 'Edit' : 'Add'} treats
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className={`relative flex-auto leading-relaxed ${
            status === 'Cancelled' || status === 'Paused' ? 'hidden' : ''
          }`}
        >
          <div className="mx-auto my-4 flex h-auto w-full flex-col overflow-hidden rounded-3xl md:flex-row">
            <div className="mb-2 w-full rounded-3xl bg-white px-6 py-6 md:mb-0 md:mr-2 md:w-1/2">
              <div className="flex flex-col items-center justify-between gap-2">
                <p data-testid="nextDeliveryDate" className="h3 mb-2 text-xl font-medium">
                  Next delivery
                </p>
                <span className="my-2 sm:mt-0">
                  {(packInfo.nextDeliveryDate && (
                    <span className="flex md:inline">
                      {dayjs(packInfo.nextDeliveryDate).format('dddd MMMM Do, YYYY')}
                    </span>
                  )) ||
                    '-'}
                </span>
                {(status === 'Active' || status === 'Future') && (
                  <div className="flex-shrink-0 justify-center py-4 md:absolute md:bottom-4">
                    <button
                      data-testid="editDate"
                      className="btn-primary justify-center !border-primary !bg-transparent !px-6 !text-base hover:underline "
                      onClick={() => {
                        setOpen(key)
                        if (typeof window.gtag !== 'undefined') {
                          window.gtag('event', 'clicked_edit_date', {
                            page: 'account_plan'
                          })
                        }
                        if (redZone) {
                          setRedZoneConfirmButton(
                            <button
                              type="button"
                              className="btn-cta inline-flex justify-center px-4 py-2 sm:col-start-1 sm:mt-0"
                              onClick={() => {
                                setOpen(key)
                                setShowRedZoneModal(false)
                                setShowRescheduleModal(true)
                                setShowEditPlanModal(true)
                              }}
                            >
                              Edit date
                            </button>
                          )
                          setShowRedZoneModal(true)
                        } else {
                          setShowRescheduleModal(true)
                          setShowEditPlanModal(true)
                        }
                      }}
                    >
                      Edit date
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className="mt-2 w-full rounded-3xl bg-white py-4 md:mt-0 md:ml-2 md:w-1/2">
              <div className="flex flex-row justify-between">
                {packInfo?.deliveryTo && (
                  <div className="w-full">
                    <p className="h3 mb-2 pt-2 text-center text-xl font-medium">Delivery address</p>
                    <div className="flex w-full flex-row  px-6 py-6 text-left md:flex-col">
                      <span className="ml-1 text-base md:ml-0">
                        {packInfo.deliveryTo.name && <> {packInfo.deliveryTo.name}</>}{' '}
                        {packInfo.deliveryTo.surname && <> {packInfo.deliveryTo.surname},</>}{' '}
                      </span>
                      <span className="ml-1 text-base md:ml-0">
                        {packInfo.deliveryTo.address1 && <> {packInfo.deliveryTo.address1}</>}
                        {packInfo.deliveryTo.address2 && <>, {packInfo.deliveryTo.address2}</>}
                        {packInfo.deliveryTo.city && <>, {packInfo.deliveryTo.city}</>}
                        {packInfo.deliveryTo.county && <>, {packInfo.deliveryTo.county}</>}
                        {packInfo.deliveryTo.postcode && <>, {packInfo.deliveryTo.postcode}</>}
                      </span>
                    </div>
                    {(status === 'Active' || status === 'Future') && (
                      <div className="flex-shrink-0 py-4">
                        <div className="justify-between text-center">
                          <button
                            data-testid="editAddress"
                            className="btn-primary justify-center !border-primary !bg-transparent !px-6 !text-base hover:underline"
                            onClick={() => {
                              setOpen(key)
                              if (typeof window.gtag !== 'undefined') {
                                window.gtag('event', 'clicked_edit_address', {
                                  page: 'account_plan'
                                })
                              }
                              if (redZone) {
                                setRedZoneConfirmButton(
                                  <button
                                    data-testid="editFutureDeliveryAddress"
                                    type="button"
                                    className="btn-cta inline-flex justify-center px-4 py-2 sm:col-start-1 sm:mt-0"
                                    onClick={() => {
                                      setShowRedZoneModal(false)
                                      setShowEditAddressModal(true)
                                    }}
                                  >
                                    Edit address
                                  </button>
                                )
                                setShowRedZoneModal(true)
                              } else {
                                setShowEditAddressModal(true)
                              }
                            }}
                          >
                            Edit address
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className={`relative flex-auto leading-relaxed ${
            status === 'Cancelled' || status === 'Paused' ? '' : 'hidden'
          }`}
        >
          <div className="mx-auto my-4 flex h-auto w-full flex-col overflow-hidden rounded-3xl md:flex-row">
            <div className="mt-2 w-full rounded-3xl bg-white py-4 md:mt-0 md:ml-2">
              <div className="flex flex-row justify-between">
                {packInfo?.deliveryTo && (
                  <div className="w-full">
                    <p className="h3 mb-2 pt-2 text-center text-xl font-medium">Delivery address</p>
                    <div className="flex w-full flex-row  px-6 py-6 text-left md:flex-col">
                      <span className="ml-1 text-base md:ml-0">
                        {packInfo.deliveryTo.name && <> {packInfo.deliveryTo.name}</>}{' '}
                        {packInfo.deliveryTo.surname && <> {packInfo.deliveryTo.surname},</>}{' '}
                      </span>
                      <span className="ml-1 text-base md:ml-0">
                        {packInfo.deliveryTo.address1 && <> {packInfo.deliveryTo.address1}</>}
                        {packInfo.deliveryTo.address2 && <>, {packInfo.deliveryTo.address2}</>}
                        {packInfo.deliveryTo.city && <>, {packInfo.deliveryTo.city}</>}
                        {packInfo.deliveryTo.county && <>, {packInfo.deliveryTo.county}</>}
                        {packInfo.deliveryTo.postcode && <>, {packInfo.deliveryTo.postcode}</>}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div
          className={`relative flex-auto leading-relaxed ${
            status === 'Cancelled' || status === 'Paused' ? 'hidden' : ''
          }`}
        >
          <div className={`mx-auto my-4 flex h-auto w-full flex-col overflow-hidden rounded-3xl md:flex-row`}>
            <div className="mb-2 w-full rounded-3xl bg-white px-6 py-4 md:mb-0 md:mr-2 md:w-1/2">
              <div className="flex flex-col items-center justify-between gap-2">
                <p className="h3 mb-2 pt-2 text-xl font-medium">Next payment</p>
                <span className="mt-2 sm:mt-0">
                  {(packInfo.nextPaymentDate && dayjs(packInfo.nextPaymentDate).format('dddd MMMM Do, YYYY')) || '-'}
                </span>
                <span className="leading-tight">
                  {packInfo.nextPaymentDate && (
                    <div>
                      {!packInfo.targetPaymentDate && (
                        <span className="text-xs">Payment is taken up to 4 days before your next delivery. </span>
                      )}
                      {packInfo.targetPaymentDate && (
                        <span className="text-xs">
                          Payment will always be taken on the [{getTargetPaymentDateText(packInfo.targetPaymentDate)}]
                          of the month.{' '}
                        </span>
                      )}
                      <span className="text-xs">Please note changes cannot be made after this date.</span>
                    </div>
                  )}
                </span>
              </div>
            </div>
            <div className="mt-2 w-full rounded-3xl bg-white px-6 py-6 md:mt-0 md:ml-2 md:w-1/2">
              <div className="flex flex-col items-center justify-between gap-2">
                <p className="h3 mb-2 text-xl font-medium">Payment amount</p>
                {/* Promotional credits */}
                {packInfo?.promotionalCredits + packInfo?.refundableCredits > 0 && (
                  <div className="flex w-full flex-row justify-between px-0">
                    <span className="text-left text-base">Account credit</span>
                    <div className="flex flex-col justify-start items-start lg:items-end">
                      <span className="whitespace-nowrap text-right text-base font-bold">
                        £{((packInfo?.promotionalCredits + packInfo?.refundableCredits) / 100).toFixed(2)}
                      </span>
                    </div>
                  </div>
                )}

                {packInfo?.coupons && (
                  <div className="flex w-full flex-row justify-between px-0">
                    <span className="text-left text-base">Discount</span>
                    <div className="flex flex-col justify-start items-start lg:items-end">
                      {packInfo.coupons.map(coupon => (
                        <span key={`coupons${coupon}`} className="whitespace-nowrap text-right text-base font-bold">
                          {coupon}
                        </span>
                      ))}
                    </div>
                  </div>
                )}
                {packInfo.price && (
                  <div className="flex w-full flex-row justify-between px-0">
                    <span className="text-left text-base">Total to pay</span>
                    <span data-testid="nextBillingAmount" className="text-right text-base font-bold">
                      £{(packInfo.price / 100).toFixed(2)}
                    </span>
                  </div>
                )}
                {packInfo?.hasCredit && (
                  <div className="flex w-full flex-row justify-between px-0">
                    <span className="text-left text-xs">(excl. credits)</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {error && (
        <ErrorModal
          show={showPopup}
          setShow={setShowPopup}
          errorMessage={''}
          url={null}
          action={null}
          actionText={null}
        />
      )}
    </div>
  )
}

interface AccordionProps {
  items: Array<{
    dogNames: string
    dogs: []
    status: string
    packInfo: PackInfo
    subscriptionId: string
    redZone: boolean
    isInTrial: boolean
    plan: any
    nextPlan: any
    quantity: number
    validDeliveryDate: boolean
    nextDelivery: string
    targetPaymentDate?: number
  }>
}

const Accordion: React.FC<AccordionProps> = ({ items }) => {
  const [open, setOpen] = useState(0)
  const [showEditPlanModal, setShowEditPlanModal] = useState(false)
  const [showResumePlanModal, setShowResumePlanModal] = useState(false)
  const [showEditAddressModal, setShowEditAddressModal] = useState(false)
  const [showRescheduleModal, setShowRescheduleModal] = useState(false)
  const [showRedZoneModal, setShowRedZoneModal] = useState(false)
  const [redZoneConfirmButton, setRedZoneConfirmButton] = useState(<></>)

  let callBackAction = (key: number) => {
    setOpen(key)
  }
  return (
    <>
      <div
        className={`-mr-4 flex flex-col md:mr-0 lg:-mr-4 lg:flex-row lg:flex-wrap ${
          items?.length === 1 && 'justify-center'
        } lg:justify-none md:justify-center`}
      >
        {items?.length > 0 &&
          items?.map((item, key) => (
            <div
              className={`flex w-full flex-col ${
                item?.length === 0 ? 'md:w-3/5' : ' md:w-full lg:w-1/2'
              } pr-4 md:min-w-[500px] lg:max-w-[1000px]`}
            >
              <div
                key={`accordion${key}`}
                className={`relative z-30 mb-4 flex w-full min-w-0 flex-col overflow-hidden break-words rounded-3xl bg-beigeBG`}
              >
                <AccordionItem
                  key={`accordionItem${key}`}
                  dogNames={item.dogNames}
                  dogs={item.dogs}
                  status={item.status}
                  packInfo={item.packInfo}
                  setOpen={() => {
                    callBackAction(key)
                  }}
                  isOpened={key === open}
                  showEditPlanModal={showEditPlanModal}
                  setShowEditPlanModal={setShowEditPlanModal}
                  showResumePlanModal={showResumePlanModal}
                  setShowResumePlanModal={setShowResumePlanModal}
                  showEditAddressModal={showEditAddressModal}
                  setShowEditAddressModal={setShowEditAddressModal}
                  showRescheduleModal={showRescheduleModal}
                  setShowRescheduleModal={setShowRescheduleModal}
                  showRedZoneModal={showRedZoneModal}
                  setShowRedZoneModal={setShowRedZoneModal}
                  redZoneConfirmButton={redZoneConfirmButton}
                  setRedZoneConfirmButton={setRedZoneConfirmButton}
                  subscriptionId={item.subscriptionId}
                  redZone={item.redZone}
                  isInTrial={item.isInTrial}
                  plan={item.plan}
                  nextPlan={item.nextPlan}
                  quantity={item.quantity}
                  isMultiplePlan={items?.length > 1}
                />
              </div>
            </div>
          ))}
      </div>
      {(showEditPlanModal || showResumePlanModal || showEditAddressModal) &&
        items.map((item, key) => {
          if (key === open) {
            return (
              <>
                {showEditPlanModal && (
                  <EditPlanModal
                    key={`editPlanModal${key}`}
                    show={showEditPlanModal}
                    setShow={setShowEditPlanModal}
                    showReschedule={showRescheduleModal}
                    setShowReschedule={setShowRescheduleModal}
                    subscriptionId={item.subscriptionId}
                    status={item.status}
                    nextDelivery={item.packInfo.nextDeliveryDate}
                    validDeliveryDate={item.validDeliveryDate}
                  />
                )}
                {showResumePlanModal && (
                  <ResumePlanModal
                    key={`resumePlanModal${key}`}
                    show={showResumePlanModal}
                    setShow={setShowResumePlanModal}
                    subscriptionId={item.subscriptionId}
                    status={item.status}
                    dogs={item.dogs}
                    setShowRedZoneModal={setShowRedZoneModal}
                    redZone={false}
                  />
                )}
                {showEditAddressModal && (
                  <EditAddressModal
                    key={`editAddressModal${key}`}
                    subscriptionId={item.subscriptionId}
                    show={showEditAddressModal}
                    setShow={setShowEditAddressModal}
                    deliveryAddress={item.packInfo.deliveryTo}
                  />
                )}
              </>
            )
          }
        })}
      {showRedZoneModal && (
        <EditFutureOrderModal
          show={showRedZoneModal}
          setShow={setShowRedZoneModal}
          confirmButton={redZoneConfirmButton}
        />
      )}
    </>
  )
}

export default Accordion
