import { faPlus, faWarning, faX } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { navigate } from '@reach/router'
import EditFutureOrderModal from 'components/ui/modal/EditFutureOrderModal'
import EditPlanModal from 'components/ui/modal/EditPlanModal'
import ResumePlanModal from 'components/ui/modal/ResumePlanModal'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updatePlanRadio } from 'state/actions/account/accountDogAction'
import { createDogsExistingPlan } from 'state/actions/account/flow/dogAction'
import { populateRecipeAndTreatData } from 'state/actions/account/flow/planAction'
import { RootState } from '../../../../state'
import { PlanType } from '../../../../state/types/accountSummaryType'

dayjs.extend(advancedFormat)

interface AccordionItemProps {
  planItem: PlanType
  orders: any
  isOpened: boolean
  setOpen: (val: any) => void
  isAccordion: boolean
  keys: number
  setShowRescheduleModal: (val: boolean) => void
  setShowEditPlanModal: (val: boolean) => void
  setShowRedZoneModal: (val: boolean) => void
  setRedZoneConfirmButton: (val: any) => void
  setShowResumePlanModal: (val: boolean) => void
}

const AccordionItem: React.FC<AccordionItemProps> = ({
  planItem,
  orders,
  isOpened,
  setOpen,
  isAccordion,
  keys,
  setShowRescheduleModal,
  setShowEditPlanModal,
  setShowRedZoneModal,
  setRedZoneConfirmButton,
  setShowResumePlanModal
}) => {
  const collapseRef = useRef<HTMLDivElement>(null)
  const { spoofMode, spoofedUserId } = useSelector((state: RootState) => state.ui)

  const getNextDeliveryDateForPlanItem = planItem => {
    if (!planItem.nextDelivery) return '-'
    return dayjs(planItem.nextDelivery).format('dddd Do MMMM YYYY')

    let dates: Array<String> = []
    const today = new Date()
    today.setHours(0, 0, 0, 0)

    dates.push(planItem.nextDelivery)

    orders.map(order => {
      if (planItem.subscriptionId === order.subscriptionId) {
        dates.push(dayjs(order.shipDate).add(1, 'day').format('YYYY-MM-DD'))
      }
    })

    dates.sort((a, b) => {
      return new Date(a) - new Date(b)
    })

    dates = dates.filter(i => i)
    dates = dates.filter((i: any) => new Date(i) >= today)

    if (dates.length > 0) {
      return dayjs(dates[0]).format('dddd Do MMMM YYYY')
    }

    return '-'
  }

  const getNextPaymentDateForPlanItem = planItem => {
    if (!planItem.nextPayment) return '-'
    return dayjs(planItem.nextPayment).format('dddd Do MMMM YYYY')

    let dates: Array<String> = []
    const today = new Date()
    today.setHours(0, 0, 0, 0)

    dates.push(planItem.nextDelivery)

    orders.map(order => {
      if (planItem.subscriptionId === order.subscriptionId) {
        dates.push(dayjs(order.shipDate).add(1, 'day').format('YYYY-MM-DD'))
      }
    })

    dates.sort((a, b) => {
      return new Date(a) - new Date(b)
    })

    dates = dates.filter(i => i)
    dates = dates.filter((i: any) => {
      let date = new Date(i)
      date.setDate(date.getDate() - 4)
      return date > today
    })

    if (dates.length > 0) {
      return dayjs(dates[0]).subtract(4, 'days').format('dddd Do MMMM YYYY')
      // let i = 0
      // if(dayjs(dates[0]).isSame(dayjs().add(4, 'day'), 'day')) i++
      // if(dates.length === 1 && i === 1) return '-'
      // return dayjs(dates[i]).subtract(4, 'day').format('dddd Do MMMM YYYY')
    }

    return '-'
  }

  return (
    <div>
      <div className="px-4 py-2 first:rounded-t">
        <a className="no-underline hover:no-underline focus:no-underline">
          <div className="flex justify-between transition-all duration-300 ease-in-out">
            <div className="flex items-center">
              <div data-testid="dogsName" className="h3 text-xl text-base sm:text-2xl md:ml-6">
                {planItem.dogNames ? `${planItem.dogNames}'s Plan` : 'Plan'}
              </div>
            </div>
          </div>
          <div className="mt-2 flex gap-2 md:ml-6">
            Status:{' '}
            <div className="flex items-center gap-1">
              <span
                className={`h-3 w-3 rounded-full ${
                  planItem.status === 'active' || planItem.status === 'future'
                    ? 'bg-activeStatus'
                    : 'bg-pauseCancelStatus'
                }`}
              ></span>
              <button
                className={`font-bold ${
                  planItem.status === 'active' || planItem.status === 'future'
                    ? 'text-activeStatus'
                    : 'text-pauseCancelStatus'
                } underline`}
                onClick={() => {
                  setOpen(keys)
                  if (planItem.status === 'active' || planItem.status === 'future') {
                    if (typeof window.gtag !== 'undefined') {
                      window.gtag('event', 'clicked_edit_plan', {
                        page: 'account_summary'
                      })
                    }
                    if (planItem.redZone) {
                      setRedZoneConfirmButton(
                        <button
                          data-testid="editFutureStatus"
                          type="button"
                          className="btn-cta inline-flex justify-center px-4 py-2 sm:col-start-1 sm:mt-0"
                          onClick={() => {
                            setOpen(keys)
                            setShowRedZoneModal(false)
                            setShowRescheduleModal(false)
                            setShowEditPlanModal(true)
                          }}
                        >
                          Edit status
                        </button>
                      )
                      setShowRedZoneModal(true)
                    } else {
                      setShowRescheduleModal(false)
                      setShowEditPlanModal(true)
                    }
                  } else if (planItem.status === 'paused' || planItem.status === 'cancelled') {
                    setOpen(keys)
                    setShowResumePlanModal(true)
                    if (typeof window.gtag !== 'undefined') {
                      window.gtag('event', 'selected_resume_plan', {
                        page: 'Account_summary'
                      })
                    }
                  } else {
                    if (typeof window.gtag !== 'undefined') {
                      window.gtag('event', 'selected_resume_plan', {
                        page: 'account_summary'
                      })
                    }
                  }
                }}
              >
                {planItem.status[0].toUpperCase() + planItem.status.substring(1)}
              </button>
            </div>
          </div>
        </a>
      </div>
      <div className={`block transition-all duration-300 ease-in-out`} ref={collapseRef}>
        <div className="m-2 grid grid-cols-1 gap-2 px-2 py-4 text-base leading-relaxed md:grid-cols-1 md:px-8">
          {!planItem.isInTrial && (
            <div>
              Hand-cooked food:{' '}
              <span className="font-bold">
                {planItem.quantity} x {planItem.plan}
              </span>{' '}
              <span>every {planItem.weeks} weeks</span>
            </div>
          )}
          {planItem.isInTrial && (
            <div className="mb-4">
              {planItem && planItem.quantity && planItem.plan && (
                <p className="mb-2">
                  Your first box is a starter box with{' '}
                  <span className="font-bold">
                    {planItem.quantity} x {planItem.plan}
                  </span>{' '}
                  packs to help you transition.
                </p>
              )}
              {planItem && planItem.nextPlan && planItem.nextPlan.quantity && planItem.nextPlan.plan && (
                <p>
                  Following this it will be{' '}
                  <span className="font-bold">
                    {planItem.nextPlan.quantity} x {planItem.nextPlan.plan}
                  </span>{' '}
                  packs every <span className="font-bold">{planItem.nextPlan.weeks}</span> weeks
                </p>
              )}
            </div>
          )}
          <div>
            <span>Next delivery:</span>{' '}
            <span data-testid="nextDeliveryDate" className="font-bold">
              {getNextDeliveryDateForPlanItem(planItem)}
            </span>
          </div>
          <div>
            <span>Address:</span>{' '}
            <span className="font-bold">{`${
              planItem.address.shipping.address1 && planItem.address.shipping.address1 + ', '
            } ${planItem.address.shipping.address2 && planItem.address.shipping.address2 + ', '}${
              planItem.address.shipping.city
            }`}</span>
          </div>
          <div>
            <span>Next payment:</span>{' '}
            <span data-testid="nextPaymentDate" className="font-bold">
              {getNextPaymentDateForPlanItem(planItem)}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

interface AccordionProps {
  plans: PlanType[] | undefined
  orders: any
  setNextPage: any
}

const Accordion: React.FC<AccordionProps> = ({ plans, orders, setNextPage }) => {
  const { dogInfo }: any = useSelector((state: RootState) => state.accountDogFlow)
  const dispatch = useDispatch()
  const [showRedZoneModal, setShowRedZoneModal] = useState(false)
  const [redZoneConfirmButton, setRedZoneConfirmButton] = useState(<></>)
  const [open, setOpen] = useState(0)
  const [showRescheduleModal, setShowRescheduleModal] = useState(false)
  const [showEditPlanModal, setShowEditPlanModal] = useState(false)
  const [showResumePlanModal, setShowResumePlanModal] = useState(false)
  const [subscriptionId, setSubscriptionId] = useState('')

  useEffect(() => {
    if (subscriptionId && dogInfo?.defaultPackSize?.default) {
      const planType = dogInfo.defaultPackSize.default.toLowerCase().replace('_t', 'T')
      dispatch(
        populateRecipeAndTreatData({
          plan: dogInfo.defaultPackSize[planType],
          noOfPacks: dogInfo.defaultPackSize[planType].numberOfPacks,
          packSize: dogInfo.defaultPackSize[planType].packSize,
          weeks: dogInfo.defaultPackSize[planType].weeks,
          isDefaultPlan: dogInfo.defaultPackSize.default !== 'CUSTOM',
          packType: dogInfo.defaultPackSize.default,
          fixedOrCustomPlan: dogInfo.defaultPackSize.default === 'CUSTOM' ? 'CUSTOM' : 'FIXED'
        })
      )
      setNextPage('treats')
      dispatch(updatePlanRadio(subscriptionId))
      dispatch(
        createDogsExistingPlan({
          subscriptionId: subscriptionId,
          dogs: []
        })
      )
    }
  }, [dogInfo])

  let callBackAction = (key: number) => {
    setOpen(key)
  }

  const handleRedZoneButton = (plan: any) => {
    setRedZoneConfirmButton(
      <button
        type="button"
        className="btn-cta inline-flex w-full justify-center px-4 py-2 sm:col-start-1 sm:mt-0"
        onClick={() => {
          setShowRedZoneModal(false)
          if (typeof window.gtag !== 'undefined') {
            window.gtag('event', 'edit_treats', {
              page: 'account_summary'
            })
          }
          setNextPage('treats')
          dispatch(updatePlanRadio(plan.subscriptionId))
          dispatch(
            createDogsExistingPlan({
              subscriptionId: plan.subscriptionId,
              dogs: []
            })
          )
        }}
      >
        Edit treats
      </button>
    )
    setShowRedZoneModal(true)
  }

  return (
    <>
      <div className="lg:justify-none -mr-4 flex flex-row flex-wrap md:justify-center">
        {plans &&
          plans.map((plan: PlanType, key: number) => (
            <div className={`flex w-full flex-col pr-4 ${plans.length > 1 && 'md:w-1/2'}`} key={`planeDetails${key}`}>
              {/* // ${(plan.status === 'active' || plan.status === 'future') && 'bg-ddGreen'
              // } ${(plan.status === 'paused' || plan.status === 'cancelled') && 'bg-ddYellow'} */}
              <div
                key={key}
                className={`relative mb-5 flex h-full w-full min-w-0 flex-col overflow-hidden break-words rounded-xl bg-ddGreen py-2`}
              >
                <AccordionItem
                  planItem={plan}
                  orders={orders}
                  setOpen={() => callBackAction(key)}
                  isOpened={key === open}
                  isAccordion={plans?.length > 1}
                  keys={key}
                  setShowRescheduleModal={setShowRescheduleModal}
                  setShowEditPlanModal={setShowEditPlanModal}
                  setShowRedZoneModal={setShowRedZoneModal}
                  setRedZoneConfirmButton={setRedZoneConfirmButton}
                  setShowResumePlanModal={setShowResumePlanModal}
                />

                <div className="mb-4 flex flex-col justify-center px-4 md:flex-row">
                  {(plan.status === 'future' || plan.status === 'active') && (
                    <button
                      data-testid="editTreats"
                      className="my-5 mr-4 w-full rounded-full border-2 border-primary py-2 px-4 hover:underline focus:underline md:my-0 md:w-auto"
                      onClick={() => {
                        if (plan.redZone) {
                          handleRedZoneButton(plan)
                        } else {
                          if (typeof window.gtag !== 'undefined') {
                            window.gtag('event', 'edit_treats', {
                              page: 'account_summary'
                            })
                          }
                          dispatch(
                            createDogsExistingPlan({
                              subscriptionId: plan.subscriptionId,
                              dogs: []
                            })
                          )
                          setSubscriptionId(plan.subscriptionId)
                        }
                      }}
                    >
                      Edit treats
                    </button>
                  )}

                  <button
                    data-testid="editPlan"
                    id="editPlan"
                    className="btn-primary w-full justify-center !border-[#2A6482] hover:!bg-[#2A6482] hover:!text-white focus:!bg-[#2A6482] focus:!text-white md:w-auto"
                    onClick={() => {
                      if (['cancelled', 'paused'].includes(plan.status)) {
                        setOpen(key)
                        setShowResumePlanModal(true)
                        if (typeof window.gtag !== 'undefined') {
                          window.gtag('event', 'selected_resume_plan', {
                            page: 'account_summary'
                          })
                        }
                      } else {
                        if (typeof window.gtag !== 'undefined') {
                          window.gtag('event', 'edit_plan', {
                            page: 'account_summary'
                          })
                        }
                        setNextPage(['active', 'future'].includes(plan?.status) ? 'plan' : 'plan-recipes')
                        dispatch(updatePlanRadio(plan.subscriptionId))
                        dispatch(
                          createDogsExistingPlan({
                            subscriptionId: plan.subscriptionId,
                            dogs: []
                          })
                        )
                      }
                    }}
                  >
                    {['paused', 'cancelled'].includes(plan?.status) ? 'Resume plan' : 'View plan'}
                  </button>
                </div>
              </div>
            </div>
          ))}
        {showRedZoneModal && (
          <EditFutureOrderModal
            show={showRedZoneModal}
            setShow={setShowRedZoneModal}
            confirmButton={redZoneConfirmButton}
          />
        )}
      </div>
      {(showEditPlanModal || showResumePlanModal) &&
        plans?.map((item, key) => {
          if (key === open) {
            return (
              <>
                {showEditPlanModal && (
                  <EditPlanModal
                    key={`editPlanModal${key}`}
                    show={showEditPlanModal}
                    setShow={setShowEditPlanModal}
                    showReschedule={showRescheduleModal}
                    setShowReschedule={setShowRescheduleModal}
                    subscriptionId={item?.subscriptionId}
                    status={item.status[0].toUpperCase() + item.status.substring(1)}
                    nextDelivery={item?.nextDelivery}
                    validDeliveryDate={item.validDeliveryDate}
                  />
                )}
                {showResumePlanModal && (
                  <ResumePlanModal
                    key={`resumePlanModal${key}`}
                    show={showResumePlanModal}
                    setShow={setShowResumePlanModal}
                    subscriptionId={item?.subscriptionId}
                    status={item?.status[0].toUpperCase() + item.status.substring(1)}
                    dogs={item?.dogs}
                    setShowRedZoneModal={setShowRedZoneModal}
                    redZone={item.redZone}
                  />
                )}
              </>
            )
          }
        })}
    </>
  )
}

export default Accordion
