// months for dog DOB
export const months = [
  {
    value: '01',
    month: 'January',
    days: 31
  },
  {
    value: '02',
    month: 'February',
    days: 29
  },
  {
    value: '03',
    month: 'March',
    days: 31
  },
  {
    value: '04',
    month: 'April',
    days: 30
  },
  {
    value: '05',
    month: 'May',
    days: 31
  },
  {
    value: '06',
    month: 'June',
    days: 30
  },
  {
    value: '07',
    month: 'July',
    days: 31
  },
  {
    value: '08',
    month: 'August',
    days: 31
  },
  {
    value: '09',
    month: 'September',
    days: 30
  },
  {
    value: '10',
    month: 'October',
    days: 31
  },
  {
    value: '11',
    month: 'November',
    days: 31
  },
  {
    value: '12',
    month: 'December',
    days: 31
  }
]
