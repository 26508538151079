import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useLocation } from '@reach/router'
import ErrorToast from 'components/ui/toast/ErrorToast'
import Toast from 'components/ui/toast/Toast'
import { getVisitorId } from 'components/util/UserId'
import { Field, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import Api from 'state/apis/api'

const FeedbackForm = ({ source }: any) => {
  const [show, setShow] = useState(false)
  const [isSubmitting, setSubmitting] = useState(false)
  const [mobileMessageRows, setMobileMessageRows] = useState(3)
  const [showToast, setShowToast] = useState(false)
  const [showError, setErrorToast] = useState(false)
  const [visitorId, setVisitorId] = useState('')
  const location = useLocation()

  useEffect(() => {
    let isMounted = true
    const getUserId = async () => {
      if (isMounted) {
        setVisitorId(await getVisitorId())
      }
    }
    getUserId()
    return () => {
      isMounted = false
    }
  }, [])

  useEffect(() => {
    const windowWidth = window.innerWidth
    if (windowWidth > 400) {
      setMobileMessageRows(5)
    }
  }, [])

  const handleSetShowToast = (show: boolean) => {
    setShowToast(show)
  }

  const handleSetErrorToast = (show: boolean) => {
    setErrorToast(show)
  }

  return (
    <>
      {!show && (
        <div className={`fixed ${source === 'signup' ? 'bottom-4 md:bottom-56' : ' bottom-16'} right-0 z-50 w-10`}>
          <div className="flex h-[7.5rem] rounded-l-2xl bg-white drop-shadow-lg">
            <button
              className="inline-block transform -rotate-90 absolute -right-6 bottom-12 text-base font-sans font-bold"
              type="button"
              onClick={() => setShow(true)}
            >
              Feedback
            </button>
          </div>
        </div>
      )}
      <div
        className={`fixed right-0 ${
          source === 'signup' ? 'bottom-4 md:bottom-56' : ' bottom-8'
        } z-50 w-full lg:w-1/2 transition-transform duration-300 ${
          show ? 'transform translate-x-0' : 'transform translate-x-full'
        }`}
      >
        <div className="flex flex-row w-full justify-center items-center">
          <div className={`flex flex-col ${show ? 'inline-block' : 'hidden'}`}>
            <button
              className="transform -rotate-90 h-12 -mr-10 mt-40 relative p-3 pl-8 rounded-t-2xl bg-primary drop-shadow-lg text-base font-sans font-bold text-white"
              type="button"
              onClick={() => setShow(false)}
            >
              Feedback
            </button>
            <FontAwesomeIcon
              className="relative ml-10 mt-3 text-white"
              onClick={() => {
                setShow(false)
              }}
              icon={faXmark}
              fontSize={20}
            />
          </div>
          <div className="flex flex-col w-full h-68 rounded-l-3xl bg-white drop-shadow-lg p-4 py-6">
            <div className="flex w-full justify-center items-center mx-auto mb-4">
              <p className="font-bold text-sans text-base">Send feedback</p>
            </div>
            <Formik
              initialValues={{
                name: '',
                email: '',
                message: ''
              }}
              onSubmit={(values, { resetForm }) => {
                setSubmitting(true)
                const url = source === 'signup' ? '/v1/public/feedback' : '/v1/private/account/feedback'
                const { name = '', email = '', message } = values
                Api.post('wwwREST', url, {
                  body: {
                    name: name,
                    email,
                    feedback: message,
                    visitorId: visitorId,
                    path: location?.pathname
                  }
                })
                  .then((_res: any) => {
                    setShowToast(true)
                    setSubmitting(false)
                    setShow(false)
                    resetForm()
                  })
                  .catch((err: any) => {
                    setErrorToast(true)
                    setSubmitting(false)
                    setErrorToast(false)
                    console.log('Error submitting feedback')
                    console.error(JSON.stringify(err, null, 2))
                  })
              }}
            >
              {props => (
                <Form className="">
                  <div className="mb-4 w-11/12 mx-auto">
                    {source === 'signup' && (
                      <div className="flex flex-col lg:flex-row mb-6">
                        <Field
                          type="text"
                          name="name"
                          id="name"
                          placeholder="Your name (optional)"
                          className={` peer block w-full self-center rounded-full border border-gray-300 bg-white py-3 text-center focus:border-highlight focus:ring-highlight mb-2 lg:mb-0 lg:mr-2`}
                          onBlur={(e: any) => {
                            props.setFieldValue('name', e.target.value)
                          }}
                          onChange={(e: any) => {
                            props.setFieldValue('name', e.target.value)
                          }}
                        />
                        <Field
                          type="email"
                          name="email"
                          id="email"
                          placeholder="Email address (optional)"
                          className={` peer block w-full self-center rounded-full border border-gray-300 bg-white py-3 text-center focus:border-highlight focus:ring-highlight mt-2 lg:mt-0 lg:ml-2`}
                          onBlur={(e: any) => {
                            props.setFieldValue('email', e.target.value)
                          }}
                          onChange={(e: any) => {
                            props.setFieldValue('email', e.target.value)
                          }}
                        />
                      </div>
                    )}
                    <Field
                      className="w-full rounded-xl border-[#B0CDD5] focus:border-[#B0CDD5] focus:outline-none focus:ring-[#B0CDD5]"
                      name="message"
                      as="textarea"
                      placeholder="Start typing here..."
                      rows={mobileMessageRows}
                    />
                  </div>
                  <div className="mx-auto flex w-max flex-col items-center justify-center">
                    <button
                      type="submit"
                      disabled={!props.values.message || isSubmitting}
                      className="btn-cta mb-4 w-full max-w-[385px] disabled:opacity-50"
                    >
                      Send
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
            <button className="text-base font-sans underline" type="button" onClick={() => setShow(false)}>
              Close
            </button>
          </div>
        </div>
      </div>
      <ErrorToast
        showToast={showError}
        msg={'There has been a problem submitting the form, please try again.'}
        handleSetShowToast={handleSetErrorToast}
        handleOnClick={() => {}}
      />
      <Toast
        showToast={showToast}
        msg={'Thank you for getting in touch! We appreciate your feedback'}
        handleSetShowToast={handleSetShowToast}
      />
    </>
  )
}

export default FeedbackForm
