import { faClose, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dialog, Transition } from '@headlessui/react'
import { Field, Form, Formik } from 'formik'
import React, { Fragment, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'state'
import Api from 'state/apis/api'
import * as yup from 'yup'
import { PassValid } from '../../page/account/sign-in'

export default function EditPasswordModal({
  show,
  setShow,
  handleSetShowToast,
  setToastMsg,
  passwordModalState,
  setPasswordUpdated
}) {
  const cancelButtonRef = useRef(null)

  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false)
  const [isFocused, setIsFocused] = useState(false)
  const { spoofMode, spoofedUserId } = useSelector((state: RootState) => state.ui)

  const validationSchemaObject =
    passwordModalState === 'Edit'
      ? yup.object({
          password: yup
            .string()
            .required('Password is required')
            .min(8, 'Password must be at least 8 characters')
            .max(99, 'Password must be at most 99 characters')
            .matches(/[a-z]+/, 'Must include at least one lowercase character')
            .matches(/[A-Z]+/, 'Must include at least one uppercase character')
            .matches(/\d+/, 'Must include at least one number'),
          confirmPassword: yup
            .string()
            .label('confirm password')
            .oneOf([yup.ref('password'), ''], 'Password must match')
            .required()
        })
      : yup.object({
          password: yup
            .string()
            .required('Password is required')
            .min(8, 'Password must be at least 8 characters')
            .max(99, 'Password must be at most 99 characters')
            .matches(/[a-z]+/, 'Must include at least one lowercase character')
            .matches(/[A-Z]+/, 'Must include at least one uppercase character')
            .matches(/\d+/, 'Must include at least one number')
        })

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={() => {
          const passwordClose = passwordModalState === 'Edit' ? false : true
          setShow(passwordClose)
        }}
      >
        <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block w-full max-w-xl transform rounded-3xl bg-white p-4 text-left align-middle shadow-xl transition-all sm:my-8 md:p-8">
              <div>
                {passwordModalState === 'Edit' && (
                  <div
                    className={`absolute top-0 right-0 flex h-12 w-12 cursor-pointer flex-col items-center justify-center p-2 md:top-4 md:right-4`}
                    onClick={() => setShow(false)}
                  >
                    <FontAwesomeIcon icon={faClose} className="text-primary" fontSize={24} />
                  </div>
                )}
                <div>
                  <div>
                    <h3 className="mb-4 text-center">{passwordModalState} your password</h3>
                    <Formik
                      initialValues={{ password: '', confirmPassword: '' }}
                      validationSchema={validationSchemaObject}
                      onSubmit={formikValues => {
                        const body = {
                          password: formikValues.password
                        }

                        const url = spoofMode
                          ? '/v1/private/admin/account/customer?userId=' + spoofedUserId
                          : '/v1/private/account/customer'

                        Api.put('wwwREST', url, { body })
                          .then(() => {
                            setShow(false)
                            setToastMsg('Password updated successfully')
                            handleSetShowToast(true)
                            setPasswordUpdated(true)
                          })
                          .catch(_err => {})
                      }}
                    >
                      {props => {
                        return (
                          <Form>
                            <div>
                              {passwordModalState === 'Create' && (
                                <div className="text-center text-sm text-base lg:text-lg font-sans pb-6">
                                  You'll need this to log in to your account next time
                                </div>
                              )}
                              <div className="relative flex w-full flex-shrink-0 flex-row items-center">
                                <Field
                                  data-testid="newPassword"
                                  type={isPasswordVisible ? 'text' : 'password'}
                                  name="password"
                                  className="peer block w-full rounded-md border border-gray-300 bg-white pt-6 text-left focus:border-[#B0CDD5] focus:ring-0 sm:mr-2"
                                  onFocus={() => setIsFocused(true)}
                                />
                                <FontAwesomeIcon
                                  icon={isPasswordVisible ? faEyeSlash : faEye}
                                  className="absolute right-4 top-1/2 -translate-y-1/2 cursor-pointer"
                                  onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                                />
                                <label
                                  className={`absolute transform cursor-text appearance-none duration-300 peer-focus:-ml-3 peer-focus:-translate-y-3 peer-focus:scale-75 ${
                                    props.values.password ? '-ml-3 -translate-y-3 scale-75' : 'ml-4'
                                  }`}
                                  htmlFor="password"
                                >
                                  Enter your new password
                                </label>
                              </div>
                              {!isFocused && (
                                <span className="my-1 block text-xs font-medium text-gray-400">
                                  Password must contain upper and lower case letters and numbers{' '}
                                </span>
                              )}
                              {props.touched.password && props.errors.password && (
                                <div className="text-xs text-red-500">{props.errors.password}</div>
                              )}
                              {isFocused && (
                                <div className="mb-4">
                                  <PassValid value={props.values.password} />
                                </div>
                              )}
                            </div>
                            {passwordModalState === 'Edit' && (
                              <div className="mb-8">
                                <div className="relative flex w-full flex-shrink-0 flex-row items-center">
                                  <Field
                                    data-testid="confirmNewPassword"
                                    type={isConfirmPasswordVisible ? 'text' : 'password'}
                                    name="confirmPassword"
                                    className="peer block w-full rounded-md border border-gray-300 bg-white pt-6 text-left focus:border-[#B0CDD5] focus:ring-0 sm:mr-2"
                                  />
                                  <FontAwesomeIcon
                                    icon={isConfirmPasswordVisible ? faEyeSlash : faEye}
                                    className="absolute right-4 top-1/2 -translate-y-1/2 cursor-pointer"
                                    onClick={() => setIsConfirmPasswordVisible(!isConfirmPasswordVisible)}
                                  />
                                  <label
                                    className={`absolute transform cursor-text appearance-none duration-300 peer-focus:-ml-4 peer-focus:-translate-y-3 peer-focus:scale-75 ${
                                      props.values.confirmPassword ? '-ml-4 -translate-y-3 scale-75' : 'ml-4'
                                    }`}
                                    htmlFor="confirmPassword"
                                  >
                                    Confirm your new password
                                  </label>
                                </div>
                                {props.errors.confirmPassword && props.touched.confirmPassword ? (
                                  <div className="text-xs text-red-500">{props.errors.confirmPassword}</div>
                                ) : null}
                              </div>
                            )}
                            {passwordModalState === 'Create' && (
                              <div className="w-1/3 mx-auto flex justify-center">
                                <button data-testid="editPasswordConfirm" type="submit" className="btn-cta !px-10">
                                  Save
                                </button>
                              </div>
                            )}
                            {passwordModalState === 'Edit' && (
                              <div className="mx-auto grid grid-cols-3">
                                <div className="col-start-2 inline-flex justify-center">
                                  <button
                                    type="button"
                                    className="btn-secondary"
                                    onClick={() => {
                                      setShow(false)
                                    }}
                                  >
                                    Cancel
                                  </button>
                                </div>
                                <div className="col-start-3 flex justify-end">
                                  <button data-testid="editPasswordConfirm" type="submit" className="btn-cta">
                                    Save
                                  </button>
                                </div>
                              </div>
                            )}
                          </Form>
                        )
                      }}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
