import { RewardCtx } from 'pages/account/[...]'
import React, { useContext, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { RootState } from 'state'
import { AccountSummaryType } from 'state/types/accountSummaryType'

const Rewards = () => {
  const {
    summary
  }: {
    summary: AccountSummaryType
  } = useSelector((state: RootState) => state.accountSummary)

  const RewardPageStore = useContext(RewardCtx)
  const { rewardFrame, setRewardFrame }: any = RewardPageStore

  let content = window.document.getElementById('swell-customer-identification')
  useEffect(() => {
    if (!rewardFrame) {
      try {
        content = window.document.getElementById('swell-customer-identification')
        setRewardFrame(content)
      } catch (e) {
        console.error(e, 'Rewards page error')
      }
    }
    if (typeof window?.yotpoWidgetsContainer?.initWidget !== 'undefined') {
      window.yotpoWidgetsContainer.initWidgets()
    }
    const sc: HTMLScriptElement | null = document.head.querySelector('script')
    sc && document.head.removeChild(sc)
  }, [])

  return (
    <div>
      <h2 className="mt-8 mb-4 text-center text-2xl sm:text-4xl">Rewards</h2>
      <div
        id="swell-customer-identification"
        data-authenticated="true"
        data-email={summary?.customer?.email}
        data-id={summary?.customer?.customerId}
        className="display:none;"
      ></div>
      {process.env.GATSBY_YOTPO_ACCOUNT_DASHBOARD && (
        <div
          className="yotpo-widget-instance"
          data-yotpo-instance-id={process.env.GATSBY_YOTPO_ACCOUNT_DASHBOARD}
          coupon-code-copied-message-title="Congrats!"
          coupon-code-copied-message-body="Your points have now been redeemed. The discount will be applied to your next order."
        ></div>
      )}
    </div>
  )
}

export default Rewards
