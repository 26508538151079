import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

// This file is pretty hacky but we need to avoid a Static query as we faced build issues
// Note that static image doesn't support a dynamic src name
// Have removed the alt text from recipes as it breaks the hover effect

const Image = props => {
  const { filename = '', alt = '', objectFit = 'fill', className = '' } = props
  if (!filename) return null

  switch (filename) {
    //   // Recipes
    //   case 'braised-beef-cooked.jpeg':
    //     return (
    //       <StaticImage
    //         className={className}
    //         objectFit={objectFit}
    //         src="../../../images/recipes/braised-beef-cooked.jpeg"
    //         placeholder="tracedSVG"
    //       />
    //     )
    //   case 'braised-beef-raw.jpeg':
    //     return (
    //       <StaticImage
    //         className={className}
    //         objectFit={objectFit}
    //         src="../../../images/recipes/braised-beef-raw.jpeg"
    //         placeholder="tracedSVG"
    //       />
    //     )
    //   case 'catch-of-the-day-cooked.jpeg':
    //     return (
    //       <StaticImage
    //         className={className}
    //         objectFit={objectFit}
    //         src="../../../images/recipes/catch-of-the-day-cooked.jpeg"
    //         placeholder="tracedSVG"
    //       />
    //     )
    //   case 'catch-of-the-day-raw.jpeg':
    //     return (
    //       <StaticImage
    //         className={className}
    //         objectFit={objectFit}
    //         src="../../../images/recipes/catch-of-the-day-raw.jpeg"
    //         placeholder="tracedSVG"
    //       />
    //     )
    //   case 'chicken-casserole-cooked.jpeg':
    //     return (
    //       <StaticImage
    //         className={className}
    //         objectFit={objectFit}
    //         src="../../../images/recipes/chicken-casserole-cooked.jpeg"
    //         placeholder="tracedSVG"
    //       />
    //     )
    //   case 'chicken-casserole-raw.jpeg':
    //     return (
    //       <StaticImage
    //         className={className}
    //         objectFit={objectFit}
    //         src="../../../images/recipes/chicken-casserole-raw.jpeg"
    //         placeholder="tracedSVG"
    //       />
    //     )
    //   case 'comforting-chicken-cooked.jpeg':
    //     return (
    //       <StaticImage
    //         className={className}
    //         objectFit={objectFit}
    //         src="../../../images/recipes/comforting-chicken-cooked.jpeg"
    //         placeholder="tracedSVG"
    //       />
    //     )
    //   case 'comforting-chicken-raw.jpeg':
    //     return (
    //       <StaticImage
    //         className={className}
    //         objectFit={objectFit}
    //         src="../../../images/recipes/comforting-chicken-raw.jpeg"
    //         placeholder="tracedSVG"
    //       />
    //     )
    //   case 'hearty-beef-and-berries-cooked.jpeg':
    //     return (
    //       <StaticImage
    //         className={className}
    //         objectFit={objectFit}
    //         src="../../../images/recipes/hearty-beef-and-berries-cooked.jpeg"
    //         placeholder="tracedSVG"
    //       />
    //     )
    //   case 'hearty-beef-and-berries-raw.jpeg':
    //     return (
    //       <StaticImage
    //         className={className}
    //         objectFit={objectFit}
    //         src="../../../images/recipes/hearty-beef-and-berries-raw.jpeg"
    //         placeholder="tracedSVG"
    //       />
    //     )
    //   case 'keep-me-trim-turkey-cooked.jpeg':
    //     return (
    //       <StaticImage
    //         className={className}
    //         objectFit={objectFit}
    //         src="../../../images/recipes/keep-me-trim-turkey-cooked.jpeg"
    //         placeholder="tracedSVG"
    //       />
    //     )
    //   case 'keep-me-trim-turkey-raw.jpeg':
    //     return (
    //       <StaticImage
    //         className={className}
    //         objectFit={objectFit}
    //         src="../../../images/recipes/keep-me-trim-turkey-raw.jpeg"
    //         placeholder="tracedSVG"
    //       />
    //     )
    //   case 'lamb-hotpot-cooked.jpeg':
    //     return (
    //       <StaticImage
    //         className={className}
    //         objectFit={objectFit}
    //         src="../../../images/recipes/lamb-hotpot-cooked.jpeg"
    //         placeholder="tracedSVG"
    //       />
    //     )
    //   case 'lamb-hotpot-raw.jpeg':
    //     return (
    //       <StaticImage
    //         className={className}
    //         objectFit={objectFit}
    //         src="../../../images/recipes/lamb-hotpot-raw.jpeg"
    //         placeholder="tracedSVG"
    //       />
    //     )
    //   case 'low-purine-and-phosphorous-cooked.jpeg':
    //     return (
    //       <StaticImage
    //         className={className}
    //         objectFit={objectFit}
    //         src="../../../images/recipes/low-purine-and-phosphorous-cooked.jpeg"
    //         placeholder="tracedSVG"
    //       />
    //     )
    //   case 'low-purine-and-phosphorous-raw.jpeg':
    //     return (
    //       <StaticImage
    //         className={className}
    //         objectFit={objectFit}
    //         src="../../../images/recipes/low-purine-and-phosphorous-raw.jpeg"
    //         placeholder="tracedSVG"
    //       />
    //     )
    //   case 'low-purine-and-phosphorus-cooked.jpeg':
    //     return (
    //       <StaticImage
    //         className={className}
    //         objectFit={objectFit}
    //         src="../../../images/recipes/low-purine-and-phosphorus-cooked.jpeg"
    //         placeholder="tracedSVG"
    //       />
    //     )
    //   case 'low-purine-and-phosphorus-raw.jpeg':
    //     return (
    //       <StaticImage
    //         className={className}
    //         objectFit={objectFit}
    //         src="../../../images/recipes/low-purine-and-phosphorus-raw.jpeg"
    //         placeholder="tracedSVG"
    //       />
    //     )
    //   case 'pawfect-picnic-cooked.jpeg':
    //     return (
    //       <StaticImage
    //         className={className}
    //         objectFit={objectFit}
    //         src="../../../images/recipes/pawfect-picnic-cooked.jpeg"
    //         placeholder="tracedSVG"
    //       />
    //     )
    //   case 'pawfect-picnic-raw.jpeg':
    //     return (
    //       <StaticImage
    //         className={className}
    //         objectFit={objectFit}
    //         src="../../../images/recipes/pawfect-picnic-raw.jpeg"
    //         placeholder="tracedSVG"
    //       />
    //     )
    //   case 'shepherds-pie-cooked.jpeg':
    //     return (
    //       <StaticImage
    //         className={className}
    //         objectFit={objectFit}
    //         src="../../../images/recipes/shepherds-pie-cooked.jpeg"
    //         placeholder="tracedSVG"
    //       />
    //     )
    //   case 'shepherds-pie-raw.jpeg':
    //     return (
    //       <StaticImage
    //         className={className}
    //         objectFit={objectFit}
    //         src="../../../images/recipes/shepherds-pie-raw.jpeg"
    //         placeholder="tracedSVG"
    //       />
    //     )
    //   case 'stay-supple-fish-supper-cooked.jpeg':
    //     return (
    //       <StaticImage
    //         className={className}
    //         objectFit={objectFit}
    //         src="../../../images/recipes/stay-supple-fish-supper-cooked.jpeg"
    //         placeholder="tracedSVG"
    //       />
    //     )
    //   case 'stay-supple-fish-supper-raw.jpeg':
    //     return (
    //       <StaticImage
    //         className={className}
    //         objectFit={objectFit}
    //         src="../../../images/recipes/stay-supple-fish-supper-raw.jpeg"
    //         placeholder="tracedSVG"
    //       />
    //     )
    //   case 'turkey-fricassee-cooked.jpeg':
    //     return (
    //       <StaticImage
    //         className={className}
    //         objectFit={objectFit}
    //         src="../../../images/recipes/turkey-fricassee-cooked.jpeg"
    //         placeholder="tracedSVG"
    //       />
    //     )
    //   case 'turkey-fricassee-raw.jpeg':
    //     return (
    //       <StaticImage
    //         className={className}
    //         objectFit={objectFit}
    //         src="../../../images/recipes/turkey-fricassee-raw.jpeg"
    //         placeholder="tracedSVG"
    //       />
    //     )
    //   case 'venison-and-thyme-cooked.jpeg':
    //     return (
    //       <StaticImage
    //         className={className}
    //         objectFit={objectFit}
    //         src="../../../images/recipes/venison-and-thyme-cooked.jpeg"
    //         placeholder="tracedSVG"
    //       />
    //     )
    //   case 'venison-and-thyme-raw.jpeg':
    //     return (
    //       <StaticImage
    //         className={className}
    //         objectFit={objectFit}
    //         src="../../../images/recipes/venison-and-thyme-raw.jpeg"
    //         placeholder="tracedSVG"
    //       />
    //     )

    //   // Treats
    //   case 'bone-broth.jpeg':
    //     return (
    //       <StaticImage
    //         className={className}
    //         objectFit={objectFit}
    //         src="../../../images/treats/bone-broth.jpeg"
    //         alt={alt}
    //         placeholder="tracedSVG"
    //       />
    //     )
    //   case 'venison-bone-broth.jpeg':
    //     return (
    //       <StaticImage
    //         className={className}
    //         objectFit={objectFit}
    //         src="../../../images/treats/venison-bone-broth.jpeg"
    //         alt={alt}
    //         placeholder="tracedSVG"
    //       />
    //     )
    //   case 'chicken-chews.jpeg':
    //     return (
    //       <StaticImage
    //         className={className}
    //         objectFit={objectFit}
    //         src="../../../images/treats/chicken-chews.jpeg"
    //         alt={alt}
    //         placeholder="tracedSVG"
    //       />
    //     )
    //   case 'compostable-dog-poo-bags.jpeg':
    //     return (
    //       <StaticImage
    //         className={className}
    //         objectFit={objectFit}
    //         src="../../../images/treats/compostable-dog-poo-bags.jpeg"
    //         alt={alt}
    //         placeholder="tracedSVG"
    //       />
    //     )
    //   case 'fishy-favourites.jpeg':
    //     return (
    //       <StaticImage
    //         className={className}
    //         objectFit={objectFit}
    //         src="../../../images/treats/fishy-favourites.jpeg"
    //         alt={alt}
    //         placeholder="tracedSVG"
    //       />
    //     )
    //   case 'game-on.jpeg':
    //     return (
    //       <StaticImage
    //         className={className}
    //         objectFit={objectFit}
    //         src="../../../images/treats/game-on.jpeg"
    //         alt={alt}
    //         placeholder="tracedSVG"
    //       />
    //     )
    //   case 'keep-calm.jpeg':
    //     return (
    //       <StaticImage
    //         className={className}
    //         objectFit={objectFit}
    //         src="../../../images/treats/keep-calm.jpeg"
    //         alt={alt}
    //         placeholder="tracedSVG"
    //       />
    //     )
    //   case 'free-animotics-probiotic-paste.jpeg':
    //     return (
    //       <StaticImage
    //         className={className}
    //         objectFit={objectFit}
    //         src="../../../images/treats/free-animotics-probiotic-paste.jpeg"
    //         alt={alt}
    //         placeholder="tracedSVG"
    //       />
    //     )
    //   case 'animotics-probiotic-paste.jpeg':
    //     return (
    //       <StaticImage
    //         className={className}
    //         objectFit={objectFit}
    //         src="../../../images/treats/free-animotics-probiotic-paste.jpeg"
    //         alt={alt}
    //         placeholder="tracedSVG"
    //       />
    //     )
    //   case 'animotics-probiotic-spray.jpeg':
    //     return (
    //       <StaticImage
    //         className={className}
    //         objectFit={objectFit}
    //         src="../../../images/treats/animotics-probiotic-spray.jpeg"
    //         alt={alt}
    //         placeholder="tracedSVG"
    //       />
    //     )
    //   case 'liver-with-love.jpeg':
    //     return (
    //       <StaticImage
    //         className={className}
    //         objectFit={objectFit}
    //         src="../../../images/treats/liver-with-love.jpeg"
    //         alt={alt}
    //         placeholder="tracedSVG"
    //       />
    //     )
    //   case 'turkey-trimmings.jpeg':
    //     return (
    //       <StaticImage
    //         className={className}
    //         objectFit={objectFit}
    //         src="../../../images/treats/turkey-trimmings.jpeg"
    //         alt={alt}
    //         placeholder="tracedSVG"
    //       />
    //     )

    // Pause / Cancel plan page
    case 'sad.png':
      return (
        <StaticImage
          className={className}
          objectFit={objectFit}
          src="../../../images/reason/sad.png"
          alt={alt}
          placeholder="tracedSVG"
        />
      )
    case 'money.png':
      return (
        <StaticImage
          className={className}
          objectFit={objectFit}
          src="../../../images/reason/money.png"
          alt={alt}
          placeholder="tracedSVG"
        />
      )
    case 'dog-treat.png':
      return (
        <StaticImage
          className={className}
          objectFit={objectFit}
          src="../../../images/reason/dog-treat.png"
          alt={alt}
          placeholder="tracedSVG"
        />
      )
    case 'refrigerator.png':
      return (
        <StaticImage
          className={className}
          objectFit={objectFit}
          src="../../../images/reason/refrigerator.png"
          alt={alt}
          placeholder="tracedSVG"
        />
      )
    case 'away.png':
      return (
        <StaticImage
          className={className}
          objectFit={objectFit}
          src="../../../images/reason/away.png"
          alt={alt}
          placeholder="tracedSVG"
        />
      )
    case 'dog-bowl.png':
      return (
        <StaticImage
          className={className}
          objectFit={objectFit}
          src="../../../images/reason/dog-bowl.png"
          alt={alt}
          placeholder="tracedSVG"
        />
      )
    case 'more.png':
      return (
        <StaticImage
          className={className}
          objectFit={objectFit}
          src="../../../images/reason/more.png"
          alt={alt}
          placeholder="tracedSVG"
        />
      )

    case 'moving-truck.png':
      return (
        <StaticImage
          className={className}
          objectFit={objectFit}
          src="../../../images/reason/moving-truck.png"
          alt={alt}
          placeholder="tracedSVG"
        />
      )
    case 'broken-heart.png':
      return (
        <StaticImage
          className={className}
          objectFit={objectFit}
          src="../../../images/reason/broken-heart.png"
          alt={alt}
          placeholder="tracedSVG"
        />
      )
    case 'health.png':
      return (
        <StaticImage
          className={className}
          objectFit={objectFit}
          src="../../../images/reason/health.png"
          alt={alt}
          placeholder="tracedSVG"
        />
      )
    case 'pet-bowl.png':
      return (
        <StaticImage
          className={className}
          objectFit={objectFit}
          src="../../../images/reason/pet-bowl.png"
          alt={alt}
          placeholder="tracedSVG"
        />
      )
    case 'calendar.png':
      return (
        <StaticImage
          className={className}
          objectFit={objectFit}
          src="../../../images/reason/calendar.png"
          alt={alt}
          placeholder="tracedSVG"
        />
      )
    case 'package.png':
      return (
        <StaticImage
          className={className}
          objectFit={objectFit}
          src="../../../images/reason/package.png"
          alt={alt}
          placeholder="tracedSVG"
        />
      )
    case 'web-design.png':
      return (
        <StaticImage
          className={className}
          objectFit={objectFit}
          src="../../../images/reason/web-design.png"
          alt={alt}
          placeholder="tracedSVG"
        />
      )
    case 'pet-food.png':
      return (
        <StaticImage
          className={className}
          objectFit={objectFit}
          src="../../../images/reason/pet-food.png"
          alt={alt}
          placeholder="tracedSVG"
        />
      )

    // Cancellation/pause
    case 'dog-and-owner.jpg':
      return (
        <StaticImage
          className={className}
          objectFit={objectFit}
          src="../../../images/reason/dog-and-owner.jpg"
          alt={alt}
          placeholder="tracedSVG"
        />
      )
    case 'microwave-horizontal.jpg':
      return (
        <StaticImage
          className={className}
          objectFit={objectFit}
          src="../../../images/reason/microwave-horizontal.jpg"
          alt={alt}
          placeholder="tracedSVG"
        />
      )
    case 'refer-friend.jpg':
      return (
        <StaticImage
          className={className}
          objectFit={objectFit}
          src="../../../images/reason/refer-friend.jpg"
          alt={alt}
          placeholder="tracedSVG"
        />
      )

    // plan page recipe
    case 'plan-recipe.png':
      return (
        <StaticImage
          className={className}
          objectFit={objectFit}
          src="../../../images/plan-recipe.png"
          alt={alt}
          placeholder="tracedSVG"
        />
      )
    case 'BulletPoint.svg':
      return (
        <StaticImage
          className={className}
          objectFit={objectFit}
          src="../../../images/BulletPoint.svg"
          alt={alt}
          placeholder="tracedSVG"
        />
      )
    default:
      return null
  }
}

export default Image
