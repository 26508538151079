import ErrorModal from 'components/ui/modal/ErrorModal'
import ZendeskTicketModal from 'components/ui/modal/ZendeskTicketModal'
import { Form, Formik } from 'formik'
import { Link, navigate } from 'gatsby'
import CDImg from 'images/cancel-pause/CD.svg'
import DeliveryDateImg from 'images/cancel-pause/Date.svg'
import ChangeDeliveryFrequencyImg from 'images/cancel-pause/Frequency.svg'
import ChangeRecipesImg from 'images/cancel-pause/Recipes.svg'
import SpecialistImg from 'images/cancel-pause/Specialist.svg'
import Img1 from 'images/cancel-pause/svg-image-1.svg'
import Img2 from 'images/cancel-pause/svg-image-2.svg'
import Img3 from 'images/cancel-pause/svg-image-3.svg'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'state'
import { updateDogsToDelete, updatePlanRadio } from 'state/actions/account/accountDogAction'
import { createDogsExistingPlan, updateChangedStatus } from 'state/actions/account/flow/dogAction'
import { postPlanIdForCustom, prefillRecipes } from 'state/actions/account/flow/planAction'
import Api from 'state/apis/api'
import loadingGIF from '../../../../../images/loadingspinner.gif'

const Eating: React.FC = ({ subscriptionId, actionType }) => {
  const dispatch = useDispatch()
  const { dogsToDelete } = useSelector((state: RootState) => state.accountDog)
  const { plans } = useSelector((state: RootState) => state.accountPlan)
  const { customer } = useSelector((state: RootState) => state.accountSummary)
  const { spoofMode, spoofedUserId } = useSelector((state: RootState) => state.ui)
  const { changed } = useSelector((state: RootState) => state.accountDogFlow)
  const planStatus = useSelector((state: RootState) => state.accountPlan.planStatus)
  const [loading, _setLoading] = useState(false)
  const [showZendeskTicketModal, setShowZendeskTicketModal] = useState(false)
  const [nextPage, setNextPage] = useState<string | undefined>(undefined)
  const [showPopup, setShowPopup] = useState(false)
  const [errorMessage, setErrorMessage] = useState({})
  const subscription = plans?.find(o => o.subscriptionId === subscriptionId)
  const [isSubmitting, setSubmitting] = useState(false)

  useEffect(() => {
    if (changed && nextPage !== 'recipes') {
      const url = spoofMode ? '/account/plan-recipes/?userId=' + spoofedUserId : '/account/plan-recipes/'
      navigate(url)
    } else if (changed && nextPage === 'recipes') {
      let planId = `${subscription?.plan}-${subscription?.weeks}w`
      let packSize = Number(subscription?.plan.replace('g', ''))
      dispatch(
        postPlanIdForCustom({
          subscriptionId: subscriptionId,
          planId,
          noOfPacks: subscription?.quantity,
          packSize: packSize,
          weeks: subscription?.weeks,
          isDefaultPlan: false,
          packType: 'CUSTOM',
          fixedOrCustomPlan: 'CUSTOM'
        })
      )
      const url = spoofMode ? '/account/recipes/?userId=' + spoofedUserId : '/account/recipes/'
      navigate(url)
      dispatch(prefillRecipes(subscription.recipes))
    }
    return () => {
      dispatch(updateChangedStatus(false))
    }
  }, [changed])

  useEffect(() => {
    const redirectUrl = spoofMode ? `/account/plan/?userId=` + spoofedUserId : `/account/plan/`
    if (Object.keys(planStatus).length === 0) {
      navigate(redirectUrl)
    }
  }, [planStatus])

  if (loading) {
    return (
      <div className="m-auto w-full text-center" style={{ marginTop: '25vh' }}>
        <img className="m-auto" width="200px" src={loadingGIF} alt="Loading, please wait..." />
      </div>
    )
  }

  const handleSubmit = async () => {
    setSubmitting(true)
    const redirectUrl = spoofMode ? `/account/plan/?userId=` + spoofedUserId : `/account/plan/`
    const pauseCancelUrl = spoofMode
      ? `/v1/private/admin/account/plan/${actionType}?userId=` + spoofedUserId
      : `/v1/private/account/plan/${actionType}`
    const subscriptionObject = {
      subscriptionId: subscriptionId,
      text: planStatus.other || '',
      reason: planStatus.reason,
      id: planStatus.id
    }
    let event = 'paused_plan'
    if (actionType === 'cancel') {
      if (dogsToDelete?.length === 1) {
        await dispatch(updateDogsToDelete([dogsToDelete[0].id]))
      }
      subscriptionObject.deleteDogs = dogsToDelete
      event = 'cancelled_plan'
    }

    if (typeof window.gtag !== 'undefined') {
      window.gtag('event', event, {
        reason: 'Eating',
        subscription_id: subscriptionId
      })
    }

    Api.post('wwwREST', pauseCancelUrl, {
      body: subscriptionObject
    })
      .then(() => {
        navigate(redirectUrl)
        setSubmitting(false)
      })
      .catch((err: any) => {
        if (err.response.data) {
          setErrorMessage(err.response.data)
        }
        setShowPopup(true)
        setSubmitting(false)
      })
  }

  return (
    <div className="mt-8">
      <div className="mb-2">
        <Link to="/account/plan/">&larr; Go back to plan</Link>
      </div>
      <Formik
        initialValues={{}}
        onSubmit={async () => {
          await handleSubmit()
        }}
      >
        {_props => (
          <Form className="mx-auto w-full text-center md:max-w-[80%]">
            <h1 className="mb-8">Before you go...</h1>
            <h3 className="mb-8">These three tips from our canine nutritionist:</h3>

            <div className="mb-8">
              <ul className="mx-auto flex w-full flex-row flex-wrap items-start justify-center">
                <li className="flex w-[175px] flex-col items-center p-2 md:w-1/3">
                  <div className="flex flex-col items-center justify-center">
                    <img
                      className="mb-2 max-w-[75px]"
                      src={Img1}
                      alt="Gently warm the food in the microwave for 30 seconds to help bring out the aroma."
                    />
                    <p className="max-w-[175px]">
                      Gently warm the food in the microwave for 30 seconds to help bring out the aroma.
                    </p>
                  </div>
                </li>
                <li className="flex w-[175px] flex-col items-center p-2 md:w-1/3">
                  <div className="flex flex-col items-center justify-center">
                    <img
                      className="mb-2 max-w-[75px]"
                      src={Img2}
                      alt="Try adding our Bone Broth on top for extra flavour and moisture."
                    />
                    <p className="max-w-[175px]">Try adding our Bone Broth on top for extra flavour and moisture.</p>
                  </div>
                </li>
                <li className="flex w-[175px] flex-col items-center p-2 md:w-1/3">
                  <div className="flex flex-col items-center justify-center">
                    <img
                      className="mb-2 max-w-[75px]"
                      src={Img3}
                      alt="Sprinkle their favourite treats on top of their food."
                    />
                    <p className="max-w-[175px]">Sprinkle their favourite treats on top of their food.</p>
                  </div>
                </li>
              </ul>
            </div>

            <h3 className="mb-8">Here are some options for you</h3>

            <div className="mb-8">
              <div className="flex flex-row flex-wrap justify-center">
                <div className="flex flex-col items-center justify-center my-1">
                  <div className="flex flex-col items-center justify-center rounded-3xl hover:bg-[#B0CDD5] focus:bg-[#B0CDD5] border-2 border-[#006685] min-h-[180px] min-w-[290px] mx-1">
                    <button
                      type="button"
                      onClick={() => {
                        setShowZendeskTicketModal(true)
                      }}
                      className="flex flex-col items-center justify-center p-4"
                    >
                      <img className="mb-2" src={SpecialistImg} alt="Speak with one of our specialists" />
                      <p className="max-w-[80%]">Speak with one of our specialists</p>
                    </button>
                  </div>
                </div>
                <div className="flex flex-col items-center justify-center my-1">
                  <div className="flex flex-col items-center justify-center rounded-3xl hover:bg-[#B0CDD5] focus:bg-[#B0CDD5] border-2 border-[#006685] min-h-[180px] min-w-[290px] mx-1">
                    <button
                      type="button"
                      onClick={() => {
                        if (typeof window.zE !== 'undefined') {
                          window.zE('webWidget', 'show')
                          window.zE('webWidget', 'open')
                        }
                      }}
                      className="flex flex-col items-center justify-center p-4"
                    >
                      <img className="mb-2" src={CDImg} alt="Contact customer delight" />
                      <p className="max-w-[80%]">Contact Customer Delight</p>
                    </button>
                  </div>
                </div>
                <div className="flex flex-col items-center justify-center my-1">
                  <div className="flex flex-col items-center justify-center rounded-3xl hover:bg-[#B0CDD5] focus:bg-[#B0CDD5] border-2 border-[#006685] min-h-[180px] min-w-[290px] mx-1">
                    <button
                      type="button"
                      onClick={() => {
                        if (subscriptionId) {
                          setNextPage('recipes')
                          dispatch(updatePlanRadio(subscriptionId))
                          dispatch(
                            createDogsExistingPlan({
                              subscriptionId: subscriptionId,
                              dogs: []
                            })
                          )
                        }
                      }}
                      className="flex flex-col items-center justify-center p-4"
                    >
                      <img className="mb-2" src={ChangeRecipesImg} alt="Change recipes" />
                      <p className="max-w-[80%]">Change recipes</p>
                    </button>
                  </div>
                </div>
                <div className="flex flex-col items-center justify-center my-1">
                  <div className="flex flex-col items-center justify-center rounded-3xl hover:bg-[#B0CDD5] focus:bg-[#B0CDD5] border-2 border-[#006685] min-h-[180px] min-w-[290px] mx-1">
                    <button
                      type="button"
                      onClick={() => {
                        if (subscriptionId) {
                          dispatch(updatePlanRadio(subscriptionId))
                          dispatch(
                            createDogsExistingPlan({
                              subscriptionId: subscriptionId,
                              dogs: []
                            })
                          )
                        }
                      }}
                      className="flex flex-col items-center justify-center p-4"
                    >
                      <img className="mb-2" src={ChangeDeliveryFrequencyImg} alt="Change delivery frequency" />
                      <p className="max-w-[80%]">Change delivery frequency</p>
                    </button>
                  </div>
                </div>
                <div className="flex flex-col items-center justify-center my-1">
                  <div className="flex flex-col items-center justify-center rounded-3xl hover:bg-[#B0CDD5] focus:bg-[#B0CDD5] border-2 border-[#006685] min-h-[180px] min-w-[290px] mx-1">
                    <button
                      type="button"
                      onClick={() => {
                        const url = spoofMode
                          ? '/account/plan/edit-delivery/?userId=' + spoofedUserId + '&subscriptionId=' + subscriptionId
                          : '/account/plan/edit-delivery/?subscriptionId=' + subscriptionId
                        navigate(url)
                      }}
                      className="flex flex-col items-center justify-center p-4"
                    >
                      <img className="mb-2" src={DeliveryDateImg} alt="Change delivery date" />
                      <p className="max-w-[80%]">Change delivery date</p>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="mx-auto mb-8 flex w-max flex-col">
              <button type="submit" className="btn-cta mb-4 disabled:opacity-50" disabled={isSubmitting}>
                {actionType === 'cancel' ? 'Cancel' : 'Pause'} plan
              </button>
              <button
                type="button"
                onClick={() => {
                  navigate(spoofMode ? `/account/plan/?userId=` + spoofedUserId : `/account/plan/`)
                }}
                className="btn-primary"
              >
                I don't want to {actionType === 'cancel' ? 'cancel' : 'pause'} anymore
              </button>
            </div>
          </Form>
        )}
      </Formik>
      <div className="mx-auto w-full text-center">
        <Link className="btn-secondary !no-underline" to={`/account/plan/${subscriptionId}/status/${actionType}`}>
          Back
        </Link>
      </div>
      {customer && showZendeskTicketModal && (
        <ZendeskTicketModal
          show={showZendeskTicketModal}
          setShow={setShowZendeskTicketModal}
          customer={customer}
          actionType={actionType}
        />
      )}
      {errorMessage && (
        <ErrorModal
          show={showPopup}
          setShow={setShowPopup}
          errorMessage={errorMessage?.popup?.message ?? errorMessage?.message}
          url={errorMessage?.popup?.url ?? errorMessage?.url}
          action={errorMessage?.popup?.action ?? errorMessage?.action}
          actionText={errorMessage?.popup?.actionText ?? errorMessage?.actionText}
        />
      )}
    </div>
  )
}

export default Eating
