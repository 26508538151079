import { AddressFinder } from '@ideal-postcodes/address-finder'
import { navigate } from '@reach/router'
import { Auth } from 'aws-amplify'
import FaqAccordion from 'components/ui/faq-accordion'
import ErrorModal from 'components/ui/modal/ErrorModal'
import StickyFooter from 'components/ui/sticky-footer/StickyFooter'
import dayjs from 'dayjs'
import { Field, Formik } from 'formik'
import React, { useState, useEffect, useRef } from 'react'
import { CalendarContainer } from 'react-datepicker'
import { DatePicker } from 'react-formik-ui'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'state'
import { resetFlowDogs } from 'state/actions/account/flow/dogAction'
import { resetFlowPlan } from 'state/actions/account/flow/planAction'
import {
  createShippingConfirmDogs,
  resetFlowShipping,
  saveShippingConfirmForm
} from 'state/actions/account/flow/shippingAction'
import { fetchPlanSchedule } from 'state/apis/account/planApi'
import Api from 'state/apis/api'
import { PlanType } from 'state/types/planType'
import { AccountShippingSchema } from 'utils/yup-validation-schema'
import loadingGIF from '../../../../images/loadingspinner.gif'

const ShippingConfirm = () => {
  return <ShippingConfirmAccount />
}

const ShippingConfirmAccount = () => {
  const [isBillingAddressSame, _setIsBillingAddressSame] = useState(true)
  const {
    recipesSelected,
    addonsSelected,
    plan,
    noOfPacks,
    weeks,
    packSize,
    fixedOrCustomPlan,
    packType
  }: PlanType | any = useSelector((state: RootState) => state.accountDogPlanFlow)
  const { dogInfo, defaultPack, pack, shippingDates } = useSelector((state: RootState) => state.accountDogFlow)
  const { shipping, errorMessage, changed, loading, orderSummary } = useSelector(
    (state: RootState) => state.accountDogShippingFlow
  )
  const { lastValidatedCoupon }: any = useSelector((state: RootState) => state.dog)
  const { key } = useSelector((state: RootState) => state.key)
  const { spoofMode, spoofedUserId } = useSelector((state: RootState) => state.ui)

  const dispatch = useDispatch()
  const [_localErrorMessage, _setErrorMessage] = useState<string | undefined>()
  const [_couponCode, _setCouponCode] = useState<string>('')
  const [openAddressInputs, setOpenAddressInputs] = useState(false)
  const [showAddressFinder, setShowAddressFinder] = useState(true)
  const [_showBillingAddressFinder, _setShowBillingAddressFinder] = useState(true)
  const [showPopup, setShowPopup] = useState(false)
  const [totalAddonPrice, setTotalAddonPrice] = useState(0)
  const [_subTotal, setSubTotal] = useState(0)
  const [finalTotal, setFinalTotal] = useState(0)
  const [_consoleError, _setConsoleError] = useState(false)

  const [currentPrices, setCurrentPrices] = useState(null)
  const [pricesError, setPricesError] = useState(false)

  const [pricesLoading, setPricesLoading] = useState(false)

  const [dateChanged, setDateChanged] = useState(dogInfo.nextDelivery || null)
  const [activeDeliveryPrice, setActiveDeliveryPrice] = useState(399)
  const [priceDateChange, setPriceDateChange] = useState('')

  var formikRef = useRef<any>()

  useEffect(() => {
    const weekdays = shippingDates.filter(date => date.price != '499')
    if (weekdays.find(day => day.price === 399)) {
      const priceChangeDate = weekdays.find(day => day.price === 399)
      if (priceChangeDate && weekdays.find(day => day.price === 0)) {
        const orderDate = weekdays.findIndex(day => day.price === 399)
        const shippingDate = weekdays[orderDate + 4]
        setPriceDateChange(dayjs(shippingDate.date).format('Do MMMM'))
      }
    }
  }, [shippingDates, priceDateChange])

  useEffect(() => {
    let totalAddonPrice = 0
    addonsSelected.map((addon: any) => {
      totalAddonPrice += addon.price * addon.quantity
    })
    setTotalAddonPrice(totalAddonPrice)
    setSubTotal(totalAddonPrice + plan.totalValue)
    setFinalTotal(plan.totalValue + totalAddonPrice)
  }, [])

  useEffect(() => {
    if (formikRef?.current?.values?.deliveryDate) {
      const selectedDate: any[] = shippingDates?.filter(
        (dateFromShippingDates: any) =>
          dayjs(dateFromShippingDates.date).format('DD/MM/YYYY') ===
          dayjs(formikRef?.current?.values?.deliveryDate).format('DD/MM/YYYY')
      )

      if (selectedDate?.length === 1) {
        setActiveDeliveryPrice(selectedDate[0].price)
      }
    }
  }, [formikRef?.current?.values?.deliveryDate])

  useEffect(() => {
    if (errorMessage && errorMessage.popup) {
      setShowPopup(true)
    }
  }, [errorMessage])

  useEffect(() => {
    const removeStorageAndRedirect = async () => {
      const url = spoofMode ? '/account/plan/?userId=' + spoofedUserId : '/account/plan/'
      navigate(url)
    }

    if (changed) {
      if (orderSummary && orderSummary.subscriptionId) {
        if (typeof window.gtag !== 'undefined') {
          window.gtag('event', 'clicked_confirm_order', {
            page: 'account_flow_checkout',
            subscription_id: orderSummary.subscriptionId,
            value: parseFloat((finalTotal / 100)?.toFixed(2))
          })
          window.gtag('event', 'purchase', {
            transaction_id: orderSummary?.subscriptionId,
            value: parseFloat((finalTotal / 100)?.toFixed(2)),
            planLevel: pack?.planLevel,
            shipping: currentPrices?.delivery,
            currency: 'GBP',
            coupon: lastValidatedCoupon?.code,
            items: recipesSelected.concat(addonsSelected)
          })
          if (process.env.GATSBY_USER_BRANCH === 'production')
            window.gtag('event', 'conversion', {
              send_to: 'AW-627026958/3d7WCPrb_K4YEI7Y_qoC',
              value: parseFloat((finalTotal / 100)?.toFixed(2)),
              currency: 'GBP',
              transaction_id: orderSummary?.subscriptionId
            })
        }
      }

      removeStorageAndRedirect()
    }
  }, [changed])

  useEffect(() => {
    if (typeof AddressFinder.setup === 'undefined') {
      setOpenAddressInputs(true)
    }

    AddressFinder.setup({
      inputField: document.getElementById('shippingAddressSearch'),
      apiKey: process.env.GATSBY_IDEAL_POSTCODES,
      injectStyle: true,
      onAddressRetrieved: ({ line_1, line_2, line_3, post_town, county, postcode }) => {
        formikRef.current.setFieldValue([`shippingAddress1`], line_1)
        formikRef.current.setFieldValue([`shippingAddress2`], line_2)
        formikRef.current.setFieldValue([`shippingCity`], post_town)
        formikRef.current.setFieldValue([`shippingPostcode`], postcode)
        formikRef.current.setFieldValue([`shippingCounty`], county)
        setShowAddressFinder(false)
      },
      onFailedCheck: () => {
        setOpenAddressInputs(true)
      },
      onSearchError: () => {
        setOpenAddressInputs(true)
      }
    })
  }, [])

  useEffect(() => {
    const planObj = {
      id: packSize + 'g-' + weeks + 'w',
      type: fixedOrCustomPlan,
      grams: packSize
    }

    if (packType !== 'CUSTOM') {
      planObj.level = pack.planLevel

      if (packType !== 'CUSTOM') {
        planObj.size = packType
      }
    }

    if (recipesSelected && addonsSelected) {
      const url = spoofMode
        ? '/v1/private/admin/account/plan/estimate?userId=' + spoofedUserId
        : '/v1/private/account/plan/estimate'

      setPricesLoading(true)

      Api.post('wwwREST', url, {
        body: {
          recipes: recipesSelected,
          addons: addonsSelected,
          plan: planObj,
          deliveryDate: formikRef?.current?.values?.deliveryDate
        }
      })
        .then(({ current }: any) => {
          setCurrentPrices(current)
          setPricesError(false)
          setPricesLoading(false)
        })
        .catch((err: any) => {
          console.error(JSON.stringify(err, null, 2))
          setPricesError(true)
          setPricesLoading(false)
        })
    }
  }, [dateChanged])

  const recipesDescription = plan.recipes.map((r: any) => {
    const matched = recipesSelected.map((rs: any) => {
      if (rs.id === r.id) {
        return <p>{rs.quantity + ' x ' + r.name}</p>
      }
    })
    return matched
  })

  const addonsDescription = plan.addons.map((a: any) => {
    const matched = addonsSelected.map((as: any) => {
      if (as.id === a.id) {
        return <p>{as.quantity + ' x ' + a.name}</p>
      }
    })
    return matched
  })

  const backClick = async () => {
    if (formikRef.current) {
      dispatch(saveShippingConfirmForm(formikRef.current.values, fixedOrCustomPlan))
    }
    const url = spoofMode ? '/account/treats/?userId=' + spoofedUserId : '/account/treats/'
    navigate(url)
  }

  const nextClick = (call: any) => {
    call()
  }

  const _redZoneDays = 4

  const getDiscountedPrice = (price: number) => {
    let total = price

    if (price > 0 && dogInfo.defaultCoupon?.code) {
      if (dogInfo.defaultCoupon.type === 'percentage') {
        total = price - (dogInfo.defaultCoupon.amount / 100) * price
      } else if (dogInfo.defaultCoupon.type === 'amount') {
        total = price - dogInfo.defaultCoupon.amount / 100
      }
    }
    return total
  }

  return (
    <>
      <div>
        <div>
          <Formik
            innerRef={formikRef}
            initialValues={{
              deliveryDate: shipping.deliveryDate,
              deliveryInstructions: shipping.notes,
              shippingAddressSearch: '',
              shippingAddress1: shipping.address.shipping.address1,
              shippingAddress2: shipping.address.shipping.address2,
              shippingCity: shipping.address.shipping.city,
              shippingCounty: shipping.address.shipping.county,
              shippingPostcode: shipping.address.shipping.postcode
            }}
            validationSchema={AccountShippingSchema}
            onSubmit={async (values: any, { setSubmitting }) => {
              const deliveryDate = dayjs(values.deliveryDate).format('YYYY-MM-DD')
              const planObj = {
                id: packSize + 'g-' + weeks + 'w',
                type: fixedOrCustomPlan,
                grams: packSize
              }

              if (fixedOrCustomPlan !== 'CUSTOM') {
                planObj.level = pack.planLevel
                planObj.size = packType
              }

              const dataToForward: any = {
                address: {
                  shipping: {
                    address1: values.shippingAddress1,
                    address2: values.shippingAddress2,
                    city: values.shippingCity,
                    county: values.shippingCounty,
                    postcode: values.shippingPostcode
                  },
                  billing: {
                    address1: isBillingAddressSame ? values.shippingAddress1 : values.billingDeliveryAddress1,
                    address2: isBillingAddressSame ? values.shippingAddress2 : values.billingDeliveryAddress2,
                    city: isBillingAddressSame ? values.shippingCity : values.billingCity,
                    county: isBillingAddressSame ? values.shippingCounty : values.billingCounty,
                    postcode: isBillingAddressSame ? values.shippingPostcode : values.billingPostcode
                  }
                },
                recipes: recipesSelected,
                addons: addonsSelected,
                deliveryDate,
                plan: planObj,
                dogs: dogInfo.dogs
              }
              dispatch(createShippingConfirmDogs(dataToForward))
              setSubmitting(false)
            }}
            validateOnMount
          >
            {props => {
              return (
                <div>
                  <div className="grid grid-cols-1 items-start gap-y-6 p-4 sm:gap-x-8">
                    <div className="grid grid-cols-1 gap-y-3 rounded-xl border border-third bg-thankyouBG p-4 text-center">
                      <h1 data-testid="reviewYourPlan" className="p-4 text-center text-3xl tracking-tight sm:text-5xl">
                        Review Your Plan
                      </h1>

                      <div className="m-auto flex w-full flex-col text-left md:w-2/3">
                        <div className="flex w-full flex-wrap items-start border-b border-[#C4C4C4] py-8">
                          <h2 className="mb-4 w-full text-lg md:mb-0 md:w-1/2">Delivery Date</h2>
                          <label className="w-full md:w-1/2">
                            {/* @ts-ignore This library has some type conflicts with one of its dependency */}
                            <DatePicker
                              data-testid="deliveryDate"
                              name="deliveryDate"
                              disabled={shippingDates?.length === 0}
                              placeholder="Select delivery date"
                              calendarStartDay={1}
                              dateFormat="dd/MM/yyyy"
                              includeDates={shippingDates?.map(date => new Date(date.date))}
                              className="flex w-full rounded-md border border-gray-300 bg-white bg-white p-2 px-4 py-3 px-4 text-center focus:border-secondary focus:ring-secondary"
                              inputmode="none"
                              onFocus={(e: any) => e.target.blur()}
                              popperPlacement="bottom"
                              onSelect={(date: Date) => {
                                props.setFieldValue('deliveryDate', date)
                                setDateChanged(date)
                              }}
                              dayClassName={(date: any) => {
                                const dateObj = new Date(date)
                                const validDate = shippingDates?.filter(
                                  dateFromShippingDates =>
                                    dayjs(dateFromShippingDates.date).format('DD/MM/YYYY') ===
                                    dayjs(date).format('DD/MM/YYYY')
                                )

                                if (validDate?.length === 1 && dateObj.getDay() === 6) {
                                  return '!text-red-500'
                                } else if (validDate?.length === 0 && dateObj.getDay() === 6) {
                                  return '!text-red-300'
                                } else {
                                  return undefined
                                }
                              }}
                              calendarContainer={({ className, children }) => (
                                <div>
                                  <CalendarContainer className={className}>
                                    <div style={{ position: 'relative' }}>{children}</div>
                                    <div className="w-[240px] p-2 pt-0">
                                      <span className="w-auto font-bold">
                                        {activeDeliveryPrice === 0 &&
                                          !priceDateChange &&
                                          currentPrices?.delivery === 0 &&
                                          'Free weekday delivery on first order'}
                                        {activeDeliveryPrice === 0 &&
                                          !priceDateChange &&
                                          currentPrices?.delivery === 399 &&
                                          'Weekday delivery'}
                                        {(activeDeliveryPrice === 0 || activeDeliveryPrice === 399) &&
                                          priceDateChange &&
                                          'Free weekday delivery before ' + priceDateChange + ', £3.99 after this date'}
                                        {activeDeliveryPrice === 399 && !priceDateChange && '£3.99 Weekday delivery'}
                                      </span>
                                      <span className="flex w-full font-bold text-red-400">
                                        £4.99 - Saturday delivery
                                      </span>
                                    </div>
                                  </CalendarContainer>
                                </div>
                              )}
                            />
                            {props.errors.deliveryDate && props.touched.deliveryDate ? (
                              <div className="text-xs text-red-500">{props.errors.deliveryDate}</div>
                            ) : null}
                          </label>
                        </div>

                        <div className="flex w-full flex-wrap items-start border-b border-[#C4C4C4] py-8">
                          <h2 className="mb-4 w-full text-lg md:mb-0 md:w-1/2">Delivery Address</h2>

                          {!openAddressInputs && showAddressFinder && (
                            <div className="w-full md:w-1/2">
                              <label>
                                <Field
                                  type="text"
                                  id="shippingAddressSearch"
                                  name="shippingAddressSearch"
                                  placeholder="Search for address"
                                  label="Search for address"
                                  className="block w-full rounded-md border border-gray-300 bg-white p-3 px-4 focus:border-secondary focus:ring-secondary"
                                />
                                {props.errors.shippingAddressSearch && props.touched.shippingAddressSearch ? (
                                  <div className="text-xs text-red-500">{props.errors.shippingAddressSearch}</div>
                                ) : null}
                              </label>
                              <button
                                data-testid="addressNotShown"
                                className="w-full text-right text-sm hover:underline focus:underline"
                                onClick={() => setOpenAddressInputs(true)}
                              >
                                My address isn't shown
                              </button>
                            </div>
                          )}

                          <div
                            className={`${
                              formikRef.current?.values['shippingAddress1'] || openAddressInputs
                                ? 'flex w-full flex-col'
                                : 'hidden'
                            }`}
                          >
                            <div>
                              <label>
                                Address Line 1
                                <Field
                                  data-testid="shippingAddress1"
                                  type="text"
                                  id="shippingAddress1"
                                  name="shippingAddress1"
                                  placeholder="Address"
                                  label="Address"
                                  className="block w-full rounded-md border border-gray-300 bg-white p-3 px-4 focus:border-secondary focus:ring-secondary"
                                />
                                {props.errors.shippingAddress1 && props.touched.shippingAddress1 ? (
                                  <div className="text-xs text-red-500">{props.errors.shippingAddress1}</div>
                                ) : null}
                              </label>
                            </div>

                            <div>
                              <label>
                                Address Line 2
                                <Field
                                  data-testid="shippingAddress2"
                                  type="text"
                                  name="shippingAddress2"
                                  placeholder="Address"
                                  label="Address"
                                  className="block w-full rounded-md border border-gray-300 bg-white p-3 px-4 focus:border-secondary focus:ring-secondary"
                                />
                                {props.errors.shippingAddress2 && props.touched.shippingAddress2 ? (
                                  <div className="text-xs text-red-500">{props.errors.shippingAddress2}</div>
                                ) : null}
                              </label>
                            </div>

                            <div>
                              <label>
                                City
                                <Field
                                  data-testid="city"
                                  type="text"
                                  name="shippingCity"
                                  placeholder="City"
                                  label="City"
                                  className="block w-full rounded-md border border-gray-300 bg-white p-3 px-4 focus:border-secondary focus:ring-secondary"
                                />
                                {props.errors.shippingCity && props.touched.shippingCity ? (
                                  <div className="text-xs text-red-500">{props.errors.shippingCity}</div>
                                ) : null}
                              </label>
                            </div>

                            <div>
                              <label>
                                County
                                <Field
                                  data-testid="county"
                                  type="text"
                                  name="shippingCounty"
                                  placeholder="County"
                                  label="County"
                                  className="block w-full rounded-md border border-gray-300 bg-white p-3 px-4 focus:border-secondary focus:ring-secondary"
                                />
                                {props.errors.shippingCounty && props.touched.shippingCounty ? (
                                  <div className="text-xs text-red-500">{props.errors.shippingCounty}</div>
                                ) : null}
                              </label>
                            </div>

                            <div>
                              <label>
                                Postcode
                                <Field
                                  data-testid="postcode"
                                  type="text"
                                  name="shippingPostcode"
                                  placeholder="Postcode"
                                  label="Postcode"
                                  className="block w-full rounded-md border border-gray-300 bg-white p-3 px-4 focus:border-secondary focus:ring-secondary"
                                />
                                {props.errors.shippingPostcode && props.touched.shippingPostcode ? (
                                  <div className="text-xs text-red-500">{props.errors.shippingPostcode}</div>
                                ) : null}
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="flex w-full flex-wrap items-start border-b border-[#C4C4C4] py-8">
                          <h2 className="mb-4 w-full text-lg md:mb-0 md:w-1/2">Recipe Summary ({plan.packSize}g)</h2>
                          <div className="w-full md:w-1/2">{recipesDescription}</div>
                        </div>

                        {totalAddonPrice > 0 && (
                          <div className="flex w-full flex-wrap items-start border-b border-[#C4C4C4] py-8">
                            <h2 className="mb-4 w-full text-lg md:mb-0 md:w-1/2">Treats and Extras</h2>
                            <div className="w-full md:w-1/2">{addonsDescription}</div>
                          </div>
                        )}

                        <div className="flex w-full flex-wrap items-start py-8">
                          <h2 className="mb-4 w-full text-lg md:w-1/2">Subscription Summary</h2>
                          <div className="w-full">
                            {((!currentPrices && !pricesError) || pricesLoading) && (
                              <div>
                                <div className="m-auto w-full text-center" style={{ marginTop: '5vh' }}>
                                  <img
                                    className="m-auto"
                                    width="200px"
                                    src={loadingGIF}
                                    alt="Loading, please wait..."
                                  />
                                </div>
                              </div>
                            )}
                            {pricesError && (
                              <div>
                                <p>There has been an error retrieving the plan price totals.</p>
                              </div>
                            )}
                            {currentPrices && !pricesError && !pricesLoading && (
                              <div className="border border-secondary p-4 text-sm text-black">
                                <div className="border-b-2 pb-2">
                                  <div className="flex flex-row justify-between">
                                    <span>{plan.weeks} weeks hand-cooked food</span>
                                    <span className="flex-shrink-0">
                                      <span className="mr-2">
                                        {currentPrices?.discount > 0 && (
                                          <span className="strikethrough">
                                            £{((currentPrices?.plan + currentPrices?.recipes) / 100).toFixed(2)}
                                          </span>
                                        )}
                                      </span>
                                      <span className="font-bold">
                                        £
                                        {currentPrices?.discount > 0
                                          ? getDiscountedPrice(
                                              currentPrices?.plan + currentPrices?.recipes / 100
                                            ).toFixed(2)
                                          : ((currentPrices?.plan + currentPrices?.recipes) / 100).toFixed(2)}
                                      </span>
                                    </span>
                                  </div>

                                  {totalAddonPrice > 0 && (
                                    <div className="flex flex-row justify-between">
                                      <span>Treats &amp; Extras</span>
                                      <span className="flex-shrink-0">
                                        <span className="mr-2">
                                          {currentPrices?.discount > 0 && dogInfo.defaultCoupon.type !== 'amount' && (
                                            <span className="strikethrough">
                                              £{(currentPrices?.addons / 100).toFixed(2)}
                                            </span>
                                          )}
                                        </span>
                                        <span className="font-bold">
                                          £
                                          {currentPrices?.discount > 0 && dogInfo.defaultCoupon.type !== 'amount'
                                            ? getDiscountedPrice(currentPrices?.addons / 100).toFixed(2)
                                            : (currentPrices?.addons / 100).toFixed(2)}
                                        </span>
                                      </span>
                                    </div>
                                  )}

                                  <div className="flex flex-row justify-between">
                                    <span>Delivery</span>
                                    <div>
                                      {currentPrices?.delivery > 0 && (
                                        <span className="font-bold">£{(currentPrices?.delivery / 100).toFixed(2)}</span>
                                      )}
                                      {currentPrices?.delivery === 0 && (
                                        <div>
                                          <span className="strikethrough">
                                            {dayjs(formikRef.current?.values['deliveryDate'])?.day() === 6
                                              ? '£4.99'
                                              : '£3.99'}
                                          </span>
                                          <span
                                            data-testid="deliveryChargeSummary"
                                            className="pl-1 font-bold uppercase"
                                          >
                                            {dayjs(formikRef.current?.values['deliveryDate'])?.day() === 6
                                              ? '£4.99'
                                              : 'FREE'}
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="flex flex-row justify-between py-2">
                                  <span className="font-bold">Total for {plan.weeks} weeks hand-cooked food</span>
                                  {currentPrices?.delivery > 0 && (
                                    <span className="font-bold">
                                      £{((currentPrices?.total + currentPrices?.delivery) / 100).toFixed(2)}
                                    </span>
                                  )}
                                  {currentPrices?.delivery === 0 && (
                                    <span className="font-bold">£{(currentPrices?.total / 100).toFixed(2)}</span>
                                  )}
                                </div>

                                {currentPrices?.discount > 0 && dogInfo.defaultCoupon.amount && (
                                  <div className="mt-4 w-full text-center">
                                    {dogInfo.defaultCoupon.type === 'percentage'
                                      ? 'With ' + dogInfo.defaultCoupon.amount + '% off '
                                      : 'With £' + dogInfo.defaultCoupon.amount / 100 + ' off '}
                                    you're saving £
                                    {Math.abs(
                                      currentPrices?.discount / 100 +
                                        (currentPrices?.delivery === 0 ? 3.99 : 0) +
                                        (dayjs(formikRef.current?.values['deliveryDate'])?.day() === 6 ? 1 : 0)
                                    ).toFixed(2)}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="flex flex-row justify-between py-8">
                          <button
                            data-testid="reviewPlanBack"
                            onClick={backClick}
                            type="button"
                            className="btn-secondary"
                          >
                            Back
                          </button>

                          <button
                            data-testid="confirmOrder"
                            onClick={() => nextClick(props.handleSubmit)}
                            disabled={!props.isValid || loading || props.isSubmitting}
                            type="submit"
                            className={`${!props.isValid || loading || props.isSubmitting ? 'opacity-50' : ''} btn-cta`}
                          >
                            {loading ? (
                              <div className="align-center flex justify-center">
                                <div className="border-white-900 mr-2 h-5 w-5 animate-spin rounded-full border-b-2"></div>
                                <div>Please wait</div>
                              </div>
                            ) : (
                              'Confirm'
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-12 w-full text-center font-serif">
                    <h3 className="mb-4">Have you got any questions?</h3>
                    <p>
                      Call us on{' '}
                      <a className="font-bold" href="tel:+441743384562">
                        01743 384 562
                      </a>
                    </p>
                    <p>
                      or email{' '}
                      <a className="font-bold" href="mailto:feedme@differentdog.com">
                        feedme@differentdog.com
                      </a>{' '}
                    </p>
                  </div>

                  <FaqAccordion
                    faqs={[
                      {
                        question: `When will I be billed?`,
                        answer: `You will be billed 4 days before your delivery arrives. For example, if you have selected a Wednesday delivery, we will attempt payment on the Saturday before. You can change your order until the day of billing.`
                      },
                      {
                        question: `Can I set a safe place for my delivery?`,
                        answer: `When your order is processed, you will receive an email or text message from DPD to advise you of your consignment number. To update your delivery instructions or to select a safe place for DPD to leave your delivery if you are not in, please either click on "Show my options" in the email you receive from DPD.`
                      }
                    ]}
                    backgroundColor={'#A6C7D1'}
                    headingText={'FAQs'}
                    headingTextColor={'primary'}
                    faqQuestionColor={'primary'}
                    faqAnswerColor={'primary'}
                    iconClass={'text-white'}
                  />
                </div>
              )
            }}
          </Formik>
        </div>
      </div>
      {errorMessage?.popup && (
        <ErrorModal
          show={showPopup}
          setShow={setShowPopup}
          errorMessage={errorMessage?.popup.message}
          url={errorMessage?.popup.url}
          action={errorMessage?.popup.action}
          actionText={errorMessage?.popup.actionText}
        />
      )}
    </>
  )
}

export default ShippingConfirm
