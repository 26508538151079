import { Amplify, Auth, Cache } from 'aws-amplify'
import { navigate } from 'gatsby'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { resetErrorMessageForPlans, resetPlansAndOrders } from 'state/actions/account/accountPlanAction'
import { resetFlowDogs } from 'state/actions/account/flow/dogAction'
import { resetFlowPlan } from 'state/actions/account/flow/planAction'
import { resetErrorMessageForFlowShipping, resetFlowShipping } from 'state/actions/account/flow/shippingAction'
import { resetAccountSummary } from 'state/actions/accountSummary'
import { updateSpoofMode } from 'state/actions/uiAction'
import loadingGIF from '../../images/loadingspinner.gif'

//@ts-ignore
import awsconfig from '../../aws-exports'
Amplify.configure(awsconfig)

const PrivateRoute = ({ component: Component, location, ...rest }: any) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const isAccountMaintenance = process.env.GATSBY_ENABLE_MAINTENANCE_MODE_ACCOUNT === 'true' ? true : false

  useEffect(() => {
    if (isAccountMaintenance) {
      Cache.setItem('lastAccountRoute', location?.pathname)
      navigate('/maintenance')
      return
    }
    return () => {
      Cache.setItem('lastAccountRoute', location?.pathname)
    }
  }, [location?.pathname])

  useEffect(() => {
    dispatch(resetErrorMessageForPlans())
    dispatch(resetErrorMessageForFlowShipping())
  }, [])

  useEffect(() => {
    setLoading(true)
    const authenticateUser = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser()
        const userGroup = user.signInUserSession.accessToken.payload['cognito:groups'][0]

        if (location.pathname.includes('/admin') && !['admin', 'staff'].includes(userGroup)) {
          navigate('/account')
          return
        }

        if (location.pathname.includes('/account') && (userGroup === 'admin' || userGroup === 'staff')) {
          const queryStr = queryString.parse(location.search)

          if (queryStr && Object.keys(queryStr).length === 0) {
            navigate('/admin')
            return null
          }

          let userId = queryStr.userId as string
          const lastChar = userId.slice(-1)

          if (lastChar === '/') userId = userId.slice(0, -1)
          dispatch(updateSpoofMode(true, userId as string, ''))
        }
        if (user) {
          if (location.pathname === '/admin' && ['admin', 'staff'].includes(userGroup)) setIsLoggedIn(true)
          else if (location.pathname !== '/admin') setIsLoggedIn(true)
          else navigate('/account/')
        }
      } catch (e: any) {
        console.error(JSON.stringify(e, null, 2))
        if (e === 'The user is not authenticated') {
          navigate(`/account/signin?r=${location?.pathname}`)
        } else {
          navigate('/error')
        }
      }
      setLoading(false)
    }
    authenticateUser()
  }, [])

  if (loading) {
    return (
      <div className="m-auto w-full text-center" style={{ marginTop: '25vh' }}>
        <img className="m-auto" width="200px" src={loadingGIF} alt="Loading, please wait..." />
      </div>
    )
  } else if (isLoggedIn) return <Component {...rest} />
  return null
}

export default PrivateRoute
